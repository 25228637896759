import calculationForOutstandingDurationYearTarget from './handleOutstandingDurationYearTarget';
import { calculateCarAssetLoanTotalMonthlyRepayment, calculateCarAssetLoanTotalAmount } from './assetCalculator';
import { formatCurrency, formatMoneyToNumber, formatCurrencyWithoutSign } from '@/layouts/application/utils/formats';
import eventHandlerForIsInputMonthlyRepayment from './shared/eventHandlerForIsInputMonthlyRepayment';

export default class AssetCarForm {
  constructor() {
    this.outstandingDurationInputSelector = '#outstanding-duration';
    this.monthlyOutstandingDurationInputSelector = '#monthly-outstanding-duration';
    this.outstandingAmountInputSelector = '#outstanding-amount';
    this.outstandingRateInputSelector = '#outstanding-rate';
    this.outstandingMonthlyRepaymentInputSelector = '#monthly-repayment-input';
    this.monthlyRepaymentAmountSelector = '#monthly-repayment-amount';
    this.outstandingAmountSelector = '#out-standing-amount-with-input-monthly-repayment';
    this.targetYearvalueSelector = '#target-year-value';
    this.formSelector = '#asset-car-form';
    this.inputMonthlyRepaymentSelector = '#is-input-monthly-repayment';
    this.currencySelect = '#currency-select';
  }

  setup() {
    calculationForOutstandingDurationYearTarget(
      this.formSelector,
      this.outstandingDurationInputSelector,
      this.monthlyOutstandingDurationInputSelector,
      this.targetYearvalueSelector,
    );
    this.syncDataForLoanMonthlyRepayment();
    this.syncDataForOutstadingAmount();
    this.calculateForMonthlyRepayment();
    eventHandlerForIsInputMonthlyRepayment();
    this.handleCheckIsInputMonthlyRepayment();
  }

  syncDataForLoanMonthlyRepayment() {
    $(this.formSelector).on(
      'input',
      `${this.baseValueSelector}, ${this.outstandingDurationInputSelector}, ${this.outstandingAmountInputSelector}, ${this.outstandingRateInputSelector}, ${this.monthlyOutstandingDurationInputSelector}`,
      () => {
        this.calculateForMonthlyRepayment();
      },
    );
  }

  calculateForMonthlyRepayment() {
    const loanOutstandingAmount = this.getLoanAmount();
    const loanRate = this.getLoanRate();
    const loanDuration = this.getLoanDuration();
    const monthlyLoanDuration = this.getMonthlyLoanDuration();

    const monthlyRepaymentAmount =
      loanDuration !== 0 || monthlyLoanDuration !== 0
        ? calculateCarAssetLoanTotalMonthlyRepayment(loanOutstandingAmount, loanRate, loanDuration, monthlyLoanDuration)
        : 0.0;
    $(this.monthlyRepaymentAmountSelector).html(formatCurrency(monthlyRepaymentAmount, $(this.currencySelect).val()));
  }

  syncDataForOutstadingAmount() {
    $(this.formSelector).on(
      'input',
      `${this.outstandingDurationInputSelector}, ${this.outstandingRateInputSelector}, ${this.outstandingMonthlyRepaymentInputSelector}, ${this.monthlyOutstandingDurationInputSelector}`,
      () => {
        const loanMonthlyRepaymentAmount = this.getLoanMonthlyRepaymentAmount();
        const loanRate = this.getLoanRate();
        const loanDuration = this.getLoanDuration();
        const monthlyLoanDuration = this.getMonthlyLoanDuration();

        const outstandingAmount =
          loanDuration !== 0 || monthlyLoanDuration !== 0
            ? calculateCarAssetLoanTotalAmount(loanMonthlyRepaymentAmount, loanRate, loanDuration, monthlyLoanDuration)
            : 0.0;
        $(this.outstandingAmountSelector).html(formatCurrency(outstandingAmount));
        if ($(this.inputMonthlyRepaymentSelector).is(':checked')) {
          $(this.outstandingAmountInputSelector).val(formatCurrencyWithoutSign(outstandingAmount));
        }
      },
    );
  }

  handleCheckIsInputMonthlyRepayment() {
    $(this.inputMonthlyRepaymentSelector).click(() => {
      let currencySign = $(this.currencySelect).val() === 'SGD' ? '$' : '';
      $(this.outstandingAmountSelector).html(currencySign + $(this.outstandingAmountInputSelector).val());
      $('.with-input-monthly-repayment input').val(
        $('.with-not-input-monthly-repayment #monthly-repayment-amount').text().replace('$', ''),
      );
    });
  }

  getLoanRate() {
    return (parseFloat($(this.outstandingRateInputSelector).val()) || 0) / 100;
  }

  getLoanDuration() {
    return parseInt($(this.outstandingDurationInputSelector).val()) || 0;
  }

  getMonthlyLoanDuration() {
    return parseInt($(this.monthlyOutstandingDurationInputSelector).val()) || 0;
  }

  getLoanAmount() {
    return parseFloat(formatMoneyToNumber($(this.outstandingAmountInputSelector).val())) || 0;
  }

  getLoanMonthlyRepaymentAmount() {
    return parseFloat(formatMoneyToNumber($(this.outstandingMonthlyRepaymentInputSelector).val())) || 0;
  }
}
