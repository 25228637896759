import { formatCurrency, formatMoneyToNumber } from '@/layouts/application/utils/formats';
import ToggleSharedDreamEvent from './shared/toggleSharedDreamEvent';
import updateAgeByInput from './shared/updateAgeByInput';
import checkValidDreamStartDate from './shared/checkInvalidStartDate';
import { DEFAULT_CURRENCY } from '@/constants';

export default class DreamGiftingForm {
  constructor() {
    this.baseValueSelector = 'input[name="dream[gifting_cost]"]';
    this.totalCostInputSelector = '#dream_gifting_cost';
    this.totalCostInputGiftingClientSelector = '#gifting_calculator_client_gifting_cost';
    this.cashPaymentByClientSelector = '.cash-payment-amount';
    this.purchaseStartDateSelector = '#date-expected-input';
    this.ageExpectedShowSelector = '#age-happen-value';
    this.clientBaseCurrency = $('body').data('client-base-currency') || DEFAULT_CURRENCY;
  }

  setup() {
    new ToggleSharedDreamEvent().setup();

    this.setOnchangeEventForTotalCost();

    this.syncCashPaymentAmounts();

    updateAgeByInput(this.purchaseStartDateSelector, this.ageExpectedShowSelector);

    checkValidDreamStartDate(this.purchaseStartDateSelector);

    $(this.purchaseStartDateSelector).on('input', () => {
      checkValidDreamStartDate(this.purchaseStartDateSelector);
    });
  }

  setOnchangeEventForTotalCost() {
    $(`${this.totalCostInputSelector}, ${this.totalCostInputGiftingClientSelector}`).on('input', ({ target }) => {
      const value = formatMoneyToNumber(target.value);

      $(this.cashPaymentByClientSelector).html(formatCurrency(value, this.clientBaseCurrency));
    });
  }

  syncCashPaymentAmounts() {
    const paymentAmount = this.getBaseValueAmount();
    $(this.cashPaymentByClientSelector).text(formatCurrency(paymentAmount, this.clientBaseCurrency));
  }

  getBaseValueAmount() {
    return parseFloat(formatMoneyToNumber($(this.baseValueSelector).val())) || 0;
  }
}
