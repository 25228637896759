import { formatCurrency, formatMoneyToNumber } from '@/layouts/application/utils/formats';
import { DEFAULT_CURRENCY } from '@/constants';

export default class ToggleSharedDreamEvent {
  constructor() {
    this.shareDreamCheckboxSelector = '#share-dream-checkbox';
    this.cashPaymentSectionSelector = '.cash-payment-section';
    this.sharedDreamSectionSelector = '.with-shared-dream';
    this.sharedMemberRadioInputSelector = '.shared-member-input';
    this.memberContributionAmountInputSelector = '#member-contribution-amount-input';
    this.clientContributionAmountInputSelector = '#client-contribution-amount-input';
    this.sharedCashRepaymentMemberNameSelector = '#shared-cash-repayment-member-name';
    this.memberProgressAmountInputSelector = '#member-progress-amount-input';
    this.clientProgressAmountInputSelector = '#client-progress-amount-input';
    this.paymentRequiredMemberNameSelector = '#payment-required-member-name';
    this.downpaymentRequiredMemberNameSelector = '#downpayment-required-member-name';
    this.totalContributionMemberNameSelector = '.total-contribution-member-name';
    this.totalContributionTextSelector = '.total-contribution-value';
    this.totalDownpaymentTextSelector = '#total-downpayment-value';
    this.totalPaymentTextSelector = '#total-payment-value';
    this.dreamStartDateInputSelector = '#date-expected-input';
    this.clientBaseCurrency = $('body').data('client-base-currency') || DEFAULT_CURRENCY;
  }

  setup() {
    this.setupExistingData();
    this.addEventForChangingSharedMember();
    this.setOnChangeForTotalContribution();
  }

  setupExistingData() {
    this.calculateTotalContribution();
    this.setupDefaultMemberName();
  }

  setupDefaultMemberName() {
    const $sharedMember = $(`${this.sharedMemberRadioInputSelector}:input:checked`);

    if ($sharedMember.length > 0) {
      const memberName = $sharedMember.parent().find('label').html();
      this.changeMemberNameInLabels(memberName);
    }
  }

  addEventForChangingSharedMember() {
    $(this.sharedMemberRadioInputSelector).on('click', ({ target }) => {
      const memberName = $(target).parent().find('label').html();
      this.changeMemberNameInLabels(memberName);
    });
  }

  changeMemberNameInLabels(memberName) {
    $(this.memberContributionAmountInputSelector)
      .parent()
      .find('label')
      .contents()
      .get(0).data = `${memberName}'s contribution (monthly)`;
    $(this.memberProgressAmountInputSelector)
      .parent()
      .find('label')
      .contents()
      .get(0).data = `${memberName}'s Progress`;
    $(this.sharedCashRepaymentMemberNameSelector).html(` ${memberName}`);
    $(this.downpaymentRequiredMemberNameSelector).html(` ${memberName}`);
    $(this.paymentRequiredMemberNameSelector).html(` ${memberName}`);
    $(this.totalContributionMemberNameSelector).html(` ${memberName}`);
  }

  setOnChangeForTotalContribution() {
    $('body').on(
      'change',
      `${this.dreamStartDateInputSelector},
    ${this.memberContributionAmountInputSelector},
    ${this.memberProgressAmountInputSelector},
    ${this.clientContributionAmountInputSelector},
    ${this.clientProgressAmountInputSelector}
    `,
      () => {
        this.calculateTotalContribution();
      },
    );
  }

  calculateTotalContribution() {
    const gapMonths = this.gapMonthsFromNowToDreamStartDate();
    if (gapMonths < 0) {
      $(this.totalContributionTextSelector).text(formatCurrency(0, this.clientBaseCurrency));
      return false;
    }
    const clientProgress = parseFloat(formatMoneyToNumber($(this.clientProgressAmountInputSelector).val()) || 0);
    const memberProgress = parseFloat(formatMoneyToNumber($(this.memberProgressAmountInputSelector).val()) || 0);
    const clientContribution =
      parseFloat(formatMoneyToNumber($(this.clientContributionAmountInputSelector).val()) || 0) * gapMonths;
    const memberContribution =
      parseFloat(formatMoneyToNumber($(this.memberContributionAmountInputSelector).val()) || 0) * gapMonths;

    const totalContribution = clientProgress + memberProgress + clientContribution + memberContribution;

    $(this.totalContributionTextSelector).text(formatCurrency(totalContribution || 0, this.clientBaseCurrency));
  }

  gapMonthsFromNowToDreamStartDate() {
    const currentDate = new Date();
    const dreamStartDate = new Date($(this.dreamStartDateInputSelector).val());

    if (dreamStartDate <= currentDate) {
      return -1;
    }
    const currentMonth = currentDate.getMonth() + 1;
    const dreamStartDateMonth = dreamStartDate.getMonth() + 1;
    const monthsFromNowToYearEnd = 12 - currentMonth;
    const gapYearBetweenNowToDreamHappen = dreamStartDate.getFullYear() - currentDate.getFullYear();

    let gapMonth = 0;

    if (gapYearBetweenNowToDreamHappen == 0) {
      gapMonth = dreamStartDateMonth - currentMonth;
    } else {
      const gapFullYearBetweenNowToDreamHappen =
        gapYearBetweenNowToDreamHappen <= 1 ? 0 : gapYearBetweenNowToDreamHappen - 1;
      gapMonth = monthsFromNowToYearEnd + (dreamStartDateMonth - 1) + gapFullYearBetweenNowToDreamHappen * 12;
    }

    if (gapMonth <= 0) {
      gapMonth = 1;
    }

    return gapMonth;
  }
}
