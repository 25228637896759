export function toggleTdTable() {
  const showSelected = $('.selected-show');
  const hiddenSelected = $('.selected-hidden');
  const groupBtn = $('#group-btn');

  $("input.select-box[type='checkbox']").change(function () {
    const selectedBoxCount = $('.select-box:checked').length;
    const $this = $(this);

    if ($this.is(':checked')) {
      showSelected.removeClass('d-none');
      //groupBtn.addClass('d-flex')
      hiddenSelected.addClass('d-none');
    } else if (!$this.is(':checked') && selectedBoxCount == 0) {
      showSelected.addClass('d-none');
      //groupBtn.removeClass('d-flex');
      hiddenSelected.removeClass('d-none');
    }
  });
}

export function toggleTdTableForRecruit() {
  const showSelectedContacts = $('.selected-show-contact');
  const hiddenSelectedContacts = $('.selected-hidden-contact');

  $("input.select-box-contact[type='checkbox']").change(function () {
    const selectedBoxContactsCount = $('.select-box-contact:checked').length;

    const $this = $(this);

    if ($this.is(':checked')) {
      showSelectedContacts.removeClass('d-none');
      hiddenSelectedContacts.addClass('d-none');
    } else if (!$this.is(':checked') && selectedBoxContactsCount == 0) {
      showSelectedContacts.addClass('d-none');
      hiddenSelectedContacts.removeClass('d-none');
    }
  });
}

export function checkboxSelected() {
  const selectBox = $('.select-box');
  const maxSelectBox = selectBox.length;

  const btnDestroy = $('#btn-destroy-clients');
  const baseUrl = btnDestroy.attr('href');
  let ids = [];
  let countChecked = 0;

  $('#main-select-box').change(function () {
    countChecked = 0;
    const $this = $(this);
    selectBox.prop('checked', $this.is(':checked')).change();
  });

  $('body').on('change', '.select-box', function () {
    const $this = $(this);
    const value = $this.data('value');

    if ($this.is(':checked')) {
      $this.parents('tr').addClass('bg-light-blue');
      ids.push(value);
      countChecked++;

      if (countChecked == maxSelectBox) {
        $('#main-select-box').prop('checked', true);
      }
    } else {
      $this.parents('tr').removeClass('bg-light-blue');
      ids = ids.filter((i) => i !== value);

      if (countChecked > 0) {
        countChecked--;
      }

      $('#main-select-box').prop('checked', false);
    }

    btnDestroy.attr('href', `${baseUrl}${ids}`);
    let text = ' contact selected';

    if (countChecked > 1) {
      text = ' contacts seleted';
    }

    $('#nums-checked').text(countChecked + text);
  });
}

export function checkboxRecruitSelected() {
  const selectContactsBox = $('.select-box-contact');
  const maxSelectContactsBox = selectContactsBox.length;
  const btnDestroy = $('#btn-destroy-clients');
  const baseUrl = btnDestroy.attr('href');
  let ids = [];
  let countChecked = 0;

  $('#main-select-box-all-contacts').change(function () {
    countChecked = 0;
    const $this = $(this);
    selectContactsBox.prop('checked', $this.is(':checked')).change();
  });

  $('body').on('change', '.select-box-contact', function () {
    const $this = $(this);
    const value = $this.data('value');

    if ($this.is(':checked')) {
      $this.parents('tr').addClass('bg-light-blue');
      ids.push(value);
      countChecked++;

      if (countChecked == maxSelectContactsBox) {
        $('#main-select-box-contact').prop('checked', true);
      }
    } else {
      $this.parents('tr').removeClass('bg-light-blue');
      ids = ids.filter((i) => i !== value);

      if (countChecked > 0) {
        countChecked--;
      }

      $('#main-select-box-contact').prop('checked', false);
    }

    btnDestroy.attr('href', `${baseUrl}${ids}`);
    let text = ' contact selected';

    if (countChecked > 1) {
      text = ' contacts seleted';
    }

    $('#nums-checked-contact').text(countChecked + text);
  });
}
