import Raicon from 'raicon';
import AppointmentForm from './appointmentForm';
import CalendarDisplay from './calendarDisplay';
import InsurancePolicyForm from '../insurances/insurancePolicyForm';
import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
import { handleOverlayGuides } from '../overlayGuides';
import { handleTrackClickBanner } from '../shared/handleTrackClickBanner';

import '../tasks/taskPage';
class ConsultantPlannersController {
  index() {
    this.setupAppointmentForm();
    this.setupInsurancePolicyForm();
    this.setupCalendar();
    this.setupCalculateAgeByBirthday();
    this.setupOpenEventModal();
    handleOverlayGuides();
    handleTrackClickBanner();
  }

  setupCalendar() {
    if ($('.consultant-calendar-page').length > 0) {
      new CalendarDisplay().setup();
    }
  }

  setupAppointmentForm() {
    $('body').on('modal:afterSetContent', '#new-appointment-button, #appointment-form, #send-confirmation-email-form, #send-cancellation-email-form', () => {
      new AppointmentForm().setup();
    });
  }

  setupInsurancePolicyForm() {
    new InsurancePolicyForm().setup();
  }

  setupCalculateAgeByBirthday() {
    $('body').on('modal:afterSetContent', '#edit-referee-form', () => {
      calculateAgeByInput('#dob', '.input-age');
    });
  }

  setupOpenEventModal() {
    $(document).ready(function() {
      let urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('event_id') && urlParams.has('appointment_name') && urlParams.has('start_time')) {
        let eventId = urlParams.get('event_id');
        let appointmentName = urlParams.get('appointment_name');
        let startTime = urlParams.get('start_time');
        let endTime = urlParams.get('end_time');
        let location = urlParams.get('location');

        let ajaxUrl = Routes.company_event_modal_consultant_planners_path({
          event_id: eventId,
          appointment_name: appointmentName,
          start_time: startTime,
          end_time: endTime,
          location: location
        });

        $.ajax({
          url: ajaxUrl,
          method: 'GET',
          success: function(response) {
            let newModal = response;
            $('body #main-modal').append(newModal);
            $('#main-modal').modal('show');
          }
        });
      }
    });
  }
}

Raicon.register('consultant/planners', ConsultantPlannersController);
