import Raicon from 'raicon';

class AdminTiersController {
  constructor() {
    this.applyConsultantsButton = '#apply-consultants-button';
    this.tierConsultantTable = '#tier-consultant-table';
    this.consultantCheckBox = '.applied-consultant-checkbox';
    this.appliedConsultantCheckBox = '.applied-consultant-checkbox:checked';
    this.consultantIDsInput = '#consultant-ids-input';

    this.dreamLimitationInput = '#dreams-limitation.is-invalid';
    this.simulatedInvestmentLimitationInput = '#si-limitation.is-invalid';
    this.simulatedEventLimitationInput = '#se-limitation.is-invalid';
    this.fhrLimitationInput = '#fhr-limitation.is-invalid';
    this.taskCheckBox = '#task-checkbox';
    this.plannerCheckBox = '#planner-checkbox';
    this.emailCheckBox = '#email-checkbox';
    this.serviceMatrixCheckBox = '#service-matrix-checkbox';
    this.automationCheckBox = '#automation-checkbox';
  }

  index() {}

  new() {
    this.addErrorMessage();
    this.handleAssignModuleCheckBoxes();
  }

  create() {
    this.addErrorMessage();
    this.handleAssignModuleCheckBoxes();
  }

  edit() {
    this.addErrorMessage();
    this.handleAssignModuleCheckBoxes();
  }

  update() {
    this.addErrorMessage();
    this.handleAssignModuleCheckBoxes();
  }

  addErrorMessage() {
    if ($(this.dreamLimitationInput).length > 0) {
      $(this.dreamLimitationInput).after(`<div class='invalid-feedback'>Please enter a numeric value</div>`);
    }
    if ($(this.simulatedInvestmentLimitationInput).length > 0) {
      $(this.simulatedInvestmentLimitationInput).after(
        `<div class='invalid-feedback'>Simulated Investment can only be 0,1,2</div>`,
      );
    }
    if ($(this.simulatedEventLimitationInput).length > 0) {
      $(this.simulatedEventLimitationInput).after(`<div class='invalid-feedback'>Please enter a numeric value</div>`);
    }
    if ($(this.fhrLimitationInput).length > 0) {
      $(this.fhrLimitationInput).after(`<div class='invalid-feedback'>Please enter a numeric value</div>`);
    }
  }

  handleAssignModuleCheckBoxes() {
    this.enableDisableAutomationAndServiceMatrix();
    $(`${this.taskCheckBox}, ${this.plannerCheckBox}`).on('change', () => {
      this.enableDisableAutomationAndServiceMatrix();
    });
  }

  enableDisableAutomationAndServiceMatrix() {
    if (this.beAbleToCheckAutomationAndServiceMatrix()) {
      $(this.serviceMatrixCheckBox).removeAttr('disabled');
      $(this.automationCheckBox).removeAttr('disabled');
    } else {
      $(this.serviceMatrixCheckBox).attr('disabled', true);
      $(this.automationCheckBox).attr('disabled', true);
      $(this.serviceMatrixCheckBox).prop('checked', false);
      $(this.automationCheckBox).prop('checked', false);
    }
  }

  beAbleToCheckAutomationAndServiceMatrix() {
    return $(this.taskCheckBox).is(':checked') && $(this.plannerCheckBox).is(':checked');
  }
}

Raicon.register('admin/tiers', AdminTiersController);
