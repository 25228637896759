import Raicon from 'raicon';

class ConsultantEmailController {
  index() {
    this.showActiveTab();
    this.showActiveTabPane();
    this.fadeOutAlertWarning();
    this.handleOpenTemplatePreview();
    this.handleClostTemplatePreview();
  }

  new() {
    this.handleOnChangeScheduleType();
    this.hideDateTimeInputIfScheduleNow();
    this.handleChangeEmailTemplateInput();
    this.removeClientIdOrLabelInput();
  }

  edit() {
    this.handleOnChangeScheduleType();
    this.hideDateTimeInputIfScheduleNow();
    this.handleChangeEmailTemplateInput();
    this.removeClientIdOrLabelInput();
  }

  create() {
    this.hideDateTimeInputIfScheduleNow();
    this.handleOnChangeScheduleType();
    this.handleChangeEmailTemplateInput();
    this.removeClientIdOrLabelInput();
    this.handleCategoryIdsError();
  }

  update() {
    this.hideDateTimeInputIfScheduleNow();
    this.handleOnChangeScheduleType();
    this.handleChangeEmailTemplateInput();
    this.removeClientIdOrLabelInput();
    this.handleCategoryIdsError();
  }

  showActiveTab() {
    const activeTabData = $('#email-tab').data('active-tab');
    const activeTab = $('#email-tab').find('.active')

    if (activeTabData) {
      $(`#email-${activeTabData}-tab`).addClass('active');
    }
    else if (activeTab.length > 0) {
      return true
    }
    else {
      $('#email-scheduled-tab').addClass('active');
    }
  }

  showActiveTabPane() {
    const activeTabId = $('#email-tab').find('.active')[0].id
    const activeTabName = activeTabId.slice(0, -4);

    $(`#${activeTabName}`).addClass('active show')
  }

  handleOnChangeScheduleType() {
    const $emailScheduleTypeInput = $('#email-schedule-type-input');
    const $emailDateTimeInput = $('#email-date-time-input-wrapper');

    $emailScheduleTypeInput.on('change', () => {
      this.hideDateTimeInputIfScheduleNow();
    });
  }

  hideDateTimeInputIfScheduleNow() {
    const $emailScheduleTypeInput = $('#email-schedule-type-input');
    const $emailDateTimeInput = $('#email-date-time-input-wrapper');

    if ($emailScheduleTypeInput.val() == 'now') {
      $emailDateTimeInput.hide();
    } else {
      $emailDateTimeInput.show();
    }
  }

  fadeOutAlertWarning() {
    const $alertWarning = $('.alert-warning');

    $alertWarning.fadeOut(3000);
  }

  handleChangeEmailTemplateInput() {
    const $emailTemplateInput = $('#email-template-input');
    const emailTemplates = $('.email-template-input').data('email-templates');
    const $emailSubjectInput = $('#email-subject-input');

    $emailTemplateInput.on('change', () => {
      const chosenTemplate = emailTemplates.filter((template) => template['id'] == $emailTemplateInput.val() )[0];

      const emailSubject = chosenTemplate['versions'].filter((version) => version['active'] == 1)[0]['subject'];

      $emailSubjectInput.val(emailSubject);
    });
  }

  handleOpenTemplatePreview() {
    const $emailTemplateCard = $('.container-template');

    $emailTemplateCard.on('click', function() {
      $(`#${this.id}-preview`).css('display', 'block');
    });
  }

  handleClostTemplatePreview() {
    const $closeTemplateBtn = $('.modal-close-btn');

    $closeTemplateBtn.on('click', function() {
      $('.preview-template-modal').css('display', 'none');
    })
  }

  removeClientIdOrLabelInput() {
    $('#email-submit-btn').on('click', function() {
      const inactiveTabContent =  $('#email-audience-tab-content').children('li').not('.active')

      for (var i = 0; i <= inactiveTabContent.length; i++) {
        var inactiveTabContentId = inactiveTabContent[i].id
        $(`#${inactiveTabContentId}-input`).val([]);
      }
    });
  }

  handleCategoryIdsError() {
    const $categoryErrorField = $('.category-targets .invalid-feedback');

    if ($('.category-targets .invalid-feedback').length > 0) {
      $(`<div class='invalid-feedback d-block'>${$categoryErrorField.text()}</div>`).appendTo('#email-category');
      $categoryErrorField.remove();
    }
  }
}

Raicon.register('consultant/emails', ConsultantEmailController);
