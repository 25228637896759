import { formatDate } from '@/layouts/application/utils/formats';

export function fillDataForAddContact() {
  $('body').on(
    'modal:afterSetContent',
    '#new-referee-to-contact-button, #new-contact-form, #new-ipreciate-to-contact-button',
    (e) => {
      const $referee_row = $(e.target).closest('tr');
      const first_name = $referee_row.find('.name').text();
      const email = $referee_row.find('.email').text();
      const country_code = $referee_row.find('.country-code').text().trim();
      const mobile_number = $referee_row.find('.mobile-number').text().trim();
      const contact_type = $referee_row.find('.contact_type').text();
      const pre_fact_find = $referee_row.find('.info-pre-fact-find').data('info');
      const dob = $referee_row.find('.dob').text();

      if (first_name != '') {
        $('#first-name-input').val(first_name);
      }
      if (mobile_number != '') {
        $('#mobile-number-input').val(mobile_number);
      }
      if (email != '') {
        $('#email-input').val(email);
      }
      if (country_code != '') {
        $('#country-code-input').val(country_code).change();
      }

      if (contact_type != '') {
        $('#contact-type-input').val(contact_type);
      }

      if (typeof pre_fact_find !== 'undefined') {
        const pre_fact_find_dob = pre_fact_find['dob'];
        const employment_status = pre_fact_find['employment_status'];
        const marital_status = pre_fact_find['marital_status'];

        if (pre_fact_find_dob != '') {
          setDob(pre_fact_find_dob);
        }

        if (employment_status != '') {
          $('#employment-status-input').val(employment_status);
        }

        if (marital_status != '') {
          $('#marial-status-input').val(marital_status);
        }
      }

      if (dob != '') {
        setDob(dob);
      }
    },
  );
}

export function fillDataForDataContactWithSecurity() {
  $('body').on('modal:afterSetContent', '#new-referee-to-contact-button, #new-contact-form', (e) => {
    const $referee_row = $(e.target).closest('tr');
    const first_name = $referee_row.find('.name').text();
    const email = $referee_row.find('.email').data('value');
    const country_code = $referee_row.find('.country-code').data('value');
    const mobile_number = $referee_row.find('.mobile-number').data('value').toString().trim();
    const contact_type = $referee_row.find('.contact_type').text();
    const pre_fact_find = $referee_row.find('.info-pre-fact-find').data('info');
    const dob = $referee_row.find('.dob').text();

    if (first_name != '') {
      $('#first-name-input').val(first_name);
    }
    if (mobile_number != '') {
      $('#mobile-number-input').val(mobile_number);
    }
    if (email != '') {
      $('#email-input').val(email);
    }
    if (country_code != '') {
      $('#country-code-input').val(country_code).change();
    }
    if (contact_type != '') {
      $('#contact-type-input').val(contact_type);
    }

    if (typeof pre_fact_find !== 'undefined') {
      const pre_fact_find_dob = pre_fact_find['dob'];
      const employment_status = pre_fact_find['employment_status'];
      const marital_status = pre_fact_find['marital_status'];

      if (pre_fact_find_dob != '') {
        setDob(pre_fact_find_dob);
      }

      if (employment_status != '') {
        $('#employment-status-input').val(employment_status);
      }

      if (marital_status != '') {
        $('#marial-status-input').val(marital_status);
      }
    }

    if (dob != '') {
      setDob(dob);
    }
  });
}

export function fillDataForAddContactOfInsight() {
  $('body').on('modal:afterSetContent', '#new-referee-to-contact-button', (e) => {
    const $referee_row = $(e.target).closest('tr');
    const first_name = $referee_row.find('.first-name').text();
    const email = $referee_row.find('.email').text();
    const country_code = $referee_row.find('.country-code').text().trim();
    const mobile_number = $referee_row.find('.mobile-number').text().trim();
    const dob = $referee_row.find('.dob').text();

    if (first_name != '') {
      $('#first-name-input').val(first_name);
    }
    if (mobile_number != '') {
      $('#mobile-number-input').val(mobile_number);
    }
    if (email != '') {
      $('#email-input').val(email);
    }
    if (country_code != '') {
      $('#country-code-input').val(country_code).change();
    }
    if (dob != '') {
      setDob(dob);
    }
  });
}

const setDob = (dob) => {
  const formatDob = formatDate(new Date(dob));
  const standardDob = formatDob.split('/').reverse().join('-');
  $('#dob').val(standardDob).trigger('change');
  $('.datepicker[placeholder="Date of Birth"][type="text"]').val(formatDob).trigger('change');
};
