import Raicon from 'raicon';
import DreamBusinessForm from '../../consultant/dreams/dreamBusinessForm';
import DreamCarForm from '../../consultant/dreams/dreamCarForm';
import DreamCustomForm from '../../consultant/dreams/dreamCustomForm';
import DreamEducationForm from '../../consultant/dreams/dreamEducationForm';
import DreamHavingChildForm from '../../consultant/dreams/dreamHavingChildForm';
import DreamHouseForm from '../../consultant/dreams/dreamHouseForm';
import DreamTravelForm from '../../consultant/dreams/dreamTravelForm';
import DreamWeddingForm from '../../consultant/dreams/dreamWeddingForm';
import DreamGiftingForm from '../../consultant/dreams/dreamGiftingForm';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import { formatCurrency } from '@/layouts/application/utils/formats';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import handleLockForm from '../shared/handleLockForm';

const images = require.context('@/images', true);
const imagePath = (name) => images(name, true);
class DreamsController {
  constructor() {
    this.dreamListSelector = '.dreams-wrapper .dream-card-wrapper';
    this.dreamCardIconSelector = '.card-icon';
    this.dreamFeasibilityStatus = '.feasibility-status';
    this.retirementTargetImgSelector = '#retirement-target-img';
    this.totalRetirementAmountSelector = '.total-retirement-amount';
    this.retirementAmountInfoSelector = '.retirement-amount-info';
    this.isLifeTimeView = false;
    this.retirementDreamChart = undefined;
  }

  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  index() {
    this.updateIconBackgroundAndImage();
    this.updateRetirementTargetInfoForIndividualAccounts();
  }

  new() {
    this.setup();
  }

  edit() {
    this.setup();
    handleLockForm(['#edit_dream']);
  }

  create() {
    this.setup();
  }

  setup() {
    new DreamHouseForm().setup();
    new DreamCarForm().setup();
    new DreamTravelForm().setup();
    new DreamWeddingForm().setup();
    new DreamEducationForm().setup();
    new DreamBusinessForm().setup();
    new DreamCustomForm().setup();
    new DreamCustomForm().setup();
    new DreamHavingChildForm().setup();
    new DreamGiftingForm().setup();
    handleMoneyField();
  }

  show() {}

  newDreamSelection() {}

  updateIconBackgroundAndImage() {
    $.post(
      Routes.present_dreams_to_retirement_chart_clients_dreams_path({
        type: 'retirement',
      }),
      {
        data: {
          is_lifetime_view: this.isLifeTimeView,
          range_start_age: this.currentRangeStartAge,
        },
      },
      (data) => {
        this.totalRetirementAmount = data.chart_data.total_retirement_amount;
        this.retirementAmountInfo = data.retirement_info;
        this.dreamFeasibilitiesData = data.chart_data.dream_feasibilities;
        this.retirementEnoughNotice = data.chart_data.retirement_info.retirement_enough_notice;
        this.updateRetirementInfo();
        this.updateDeamFeasibilities();
        this.updateRetirementTargetImage();
      },
    );
  }

  updateRetirementTargetImage() {
    const image = this.retirementEnoughNotice
      ? imagePath('./icons/dream/retirement-target-green.svg')
      : imagePath('./icons/dream/retirement-target.svg');

    $(this.retirementTargetImgSelector).attr('src', image);
  }

  updateRetirementInfo() {
    $(this.totalRetirementAmountSelector).html(formatCurrency(this.totalRetirementAmount));
    $(this.retirementAmountInfoSelector).html(this.retirementAmountInfo);
  }

  updateDeamFeasibilities() {
    Array.from(document.querySelectorAll(this.dreamListSelector)).forEach((element) => {
      const dreamId = element.dataset.dreamId;
      const feasibily = this.dreamFeasibilitiesData[dreamId];
      if (feasibily) {
        const cardIconSelector = $(element.querySelector(this.dreamCardIconSelector));
        const feasibilityStatus = $(element.querySelector(this.dreamFeasibilityStatus));
        const feasibilityWithSaving = feasibily['feasibility_with_saving'];
        const feasibilityWithFirstSi = feasibily['feasibility_with_first_si'];
        const feasibilityWithSecondSi = feasibily['feasibility_with_second_si'];

        if (feasibilityWithSaving) {
          cardIconSelector.removeClass('red');
          cardIconSelector.addClass('green');
          feasibilityStatus.html('');
        } else {
          cardIconSelector.removeClass('green');
          cardIconSelector.addClass('red');
          feasibilityStatus.html('');
        }

        if (!feasibilityWithSaving) {
          if (feasibilityWithFirstSi && feasibilityWithSecondSi) {
            feasibilityStatus.html('Achievable with Simulated Investment 1 or 2');
          } else if (feasibilityWithFirstSi) {
            feasibilityStatus.html('Achievable with Simulated Investment 1');
          } else if (feasibilityWithSecondSi) {
            feasibilityStatus.html('Achievable with Simulated Investment 2');
          }
        }
      }
    });
  }

  updateRetirementTargetInfoForIndividualAccounts() {
    const mergedContactIds = $('#individual-contacts').data('merged-contact-ids');
    if (mergedContactIds.length == 0) return;

    mergedContactIds.forEach((clientId, index) => {
      $.post(
        Routes.present_dreams_to_retirement_chart_clients_dreams_path({
          client_id: clientId,
          type: 'retirement',
        }),
        {
          data: {
            is_lifetime_view: this.isLifeTimeView,
            range_start_age: this.currentRangeStartAge,
          },
        },
        (data) => {
          const totalRetirementAmount = data.chart_data.total_retirement_amount;
          const retirementEnoughNotice = data.chart_data.retirement_info.retirement_enough_notice;
          const retirementAmountInfo = data.retirement_info;
          const retirementNumber = index + 1;

          $(`.total-retirement-amount-${retirementNumber}`).html(formatCurrency(totalRetirementAmount));

          const image = retirementEnoughNotice
            ? imagePath('./icons/dream/retirement-target-green.svg')
            : imagePath('./icons/dream/retirement-target.svg');
          $(`#retirement-target-img-${retirementNumber}`).attr('src', image);

          $(`.retirement-amount-info-${retirementNumber}`).html(retirementAmountInfo);
        },
      );
    });
  }
}

Raicon.register('clients/dreams', DreamsController);
