import { copyExecuted } from '@/layouts/application/utils/copyExecuted';
import { activeByCheckBox } from '@/layouts/application/utils/activeButtonWithCondition'
import Raicon from 'raicon';

class FindingMerlinController {
  constructor() {
    this.slider = '#personal-financial-planning-rate';
    this.sliderLabels = '.slider-labels .label';
    this.dreamGoalGroup = '.dream-goal-group';
    this.dreamGoalValues = '#dream-goal-values';
  }

  show() {
    
  }

  start() {
    
  }

  result() {
    activeByCheckBox('#finding-merlin-submit-btn');
    copyExecuted('#copy-finding-merlin-link', '#finding-merlin-link');
  }

  submitResultAndGiveaway() {
    activeByCheckBox('#finding-merlin-submit-btn');
    copyExecuted('#copy-finding-merlin-link', '#finding-merlin-link');
  }

  bonusChance() {
    activeByCheckBox('#finding-merlin-submit-btn');
    copyExecuted('#copy-finding-merlin-link', '#finding-merlin-link');
    this.setupSlider();
    this.handleSelectDreamGoal();
    this.displayCollectionCheckBoxesFinancialGoal();
  }

  submitBonusChance() {
    activeByCheckBox('#finding-merlin-submit-btn');
    copyExecuted('#copy-finding-merlin-link', '#finding-merlin-link');
    this.setupSlider();
    this.handleSelectDreamGoal();
    this.displayCollectionCheckBoxesFinancialGoal();
  }

  thankYou() {
    copyExecuted('#copy-finding-merlin-link', '#finding-merlin-link');
  }

  answers() {
    
  }

  handleSelectDreamGoal() {
    $(this.dreamGoalGroup).on('click', (e) => {
      const dreamName = $(e.target).attr('id');
      let dreamGoalValues = $(this.dreamGoalValues).val().split(',');
      let imgSelector = $(e.target);
      if (!$(imgSelector).is('img')) {
        imgSelector = $(imgSelector).find('img');
      }

      if (dreamGoalValues.includes(dreamName)) {
        dreamGoalValues = this.removeElementFromArray(dreamGoalValues, dreamName);
        $(imgSelector).removeClass('selected');
      } else {
        dreamGoalValues = this.addElementAndRemoveEmpty(dreamGoalValues, dreamName);
        $(imgSelector).addClass('selected');
      }

      $(this.dreamGoalValues).val(dreamGoalValues.join(','));
    });
  }

  removeElementFromArray(arr, ele) {
    let filteredArray = arr.filter(item => item !== ele);
    filteredArray = filteredArray.filter(item => item !== "" && item !== undefined);

    return filteredArray;
  }

  addElementAndRemoveEmpty(arr, ele) {
    arr.push(ele);

    return arr.filter(item => item !== "" && item !== undefined);
}

  displayCollectionCheckBoxesFinancialGoal() {
    let dreamGoalValue = $('#dream-goal-values').val().split(' ');
    $('#dream-goal-values').val(dreamGoalValue.join(','));

    if (dreamGoalValue[0] != '') {
      dreamGoalValue.forEach(function (img_id, index) {
        $('.dream-goal-group').find(`#${img_id}`).addClass('selected');
      });
    }
  }

  setupSlider() {
    let slider = $(this.slider);
    let labels = $(this.sliderLabels);

    // Set initial value for the slider percentage
    let initialPercentage = (slider.val() - slider.attr('min')) / (slider.attr('max') - slider.attr('min')) * 100;
    slider.css('--slider-percentage', initialPercentage + '%');

    function updateSlider() {
      let value = slider.val();
      labels.each(function(index) {
        if (index < value) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      });
    }

    // Initial update
    updateSlider();

    slider.on('input', function() {
      // Update the letiable for the gradient
      let percentage = ($(this).val() - $(this).attr('min')) / ($(this).attr('max') - $(this).attr('min')) * 100;
      $(this).css('--slider-percentage', percentage + '%');

      updateSlider();
    });
  }
}

Raicon.register('findingMerlin', FindingMerlinController);
