import Raicon from 'raicon';

import { toggleTdTableForRecruit, checkboxRecruitSelected } from '@/layouts/application/utils/multipleSelectTable';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';

class AdminRecruitManagementsController {
  index() {
    checkboxRecruitSelected();
    toggleTdTableForRecruit();
    changeParamenterByTab('user_managements', 'active_tab');

    this.addTriggerOpenModalSendMailRecruits();
  }

  addTriggerOpenModalSendMailRecruits() {
    $('body').on('click', '#send-career-quiz-to-contacts-button', () => {
      this.addIdsToSendMailRecruits();
    });
  }

  addIdsToSendMailRecruits() {
    const selectBox = $('.select-box-contact:checked');
    const recruitIds = $('#send-mail-to-selected-contacts-ids');
    let ids = [];

    selectBox.each(function () {
      const value = $(this).data('value');
      ids.push(value);
    });

    recruitIds.val(ids);
  }
}

Raicon.register('admin/recruitManagements', AdminRecruitManagementsController);
