import calculateDepositAmountByClient from './shared/calculateDepositAmountByClient';
import eventForRecurringDepositType from './shared/eventForRecurringDepositType';
import AssetEvents from './assetEvents';
import AddFamilyMemberAction from '../dreams/addFamilyMemberAction';
import dynamicIncludedMonth from '../dynamicIncludedMonth';
import { handleInsurerOther } from '../shared/handleInsurerOther';
import { handlePolicyBenefit } from '../shared/handlePolicyBenefit';
import { handleTotalPaidOnChange } from './shared/calculateTotalPaidAmount';
export default class AssetEndowmentForm extends AssetEvents {
  constructor() {
    super();
    this.formSelector = '#asset-endowment-form';
    this.recurringInputSelector = '#recurring-deposit-input';
    this.recurringIntervalInputSelector = '#recurring-interval-type';
    this.recurringDepositByClientSelector = '#recurring-deposit-by-client';
    this.remaindingPaymentTypeSelector = '#remaining-payment-type';
    this.payoutOptionSelector = '#payout-option-selector';
    this.lumpSumPayoutOptionSelector = '#lump-sum-payout-option';
    this.recurringPayoutOption = '#recurring-payout-option';
  }

  setup() {
    this.setExistingValue();
    this.addOnchangeRecurringAmount();
    this.eventForRecurringDepositType();
    this.eventForPayoutOption();
    this.setAssetEvents();
    handleInsurerOther();
    handleTotalPaidOnChange();
    handlePolicyBenefit();
    new AddFamilyMemberAction().setup('.networth-modal');
    new dynamicIncludedMonth().setup('#premium-interval-type');
  }

  setExistingValue() {
    this.calculateDepositAmountByClient();
    this.togglePayoutOption($(this.payoutOptionSelector).val());
  }

  eventForPayoutOption() {
    $(this.payoutOptionSelector).on('change', ({ target }) => {
      const payoutOptionValue = target.value;

      this.togglePayoutOption(payoutOptionValue);
    });
  }

  togglePayoutOption(payoutOption) {
    if (payoutOption == 'lump_sum_payout') {
      $(this.lumpSumPayoutOptionSelector).show();
      $(this.recurringPayoutOption).hide();
    } else if (payoutOption == 'recurring_payout') {
      $(this.lumpSumPayoutOptionSelector).hide();
      $(this.recurringPayoutOption).show();
    } else {
      $(this.lumpSumPayoutOptionSelector).hide();
      $(this.recurringPayoutOption).hide();
    }
  }

  addOnchangeRecurringAmount() {
    $(this.formSelector).on('input', `${this.recurringInputSelector}, ${this.recurringIntervalInputSelector}`, () => {
      this.calculateDepositAmountByClient();
    });
  }

  calculateDepositAmountByClient() {
    calculateDepositAmountByClient(
      this.recurringInputSelector,
      this.recurringIntervalInputSelector,
      this.recurringDepositByClientSelector,
    );
  }

  eventForRecurringDepositType() {
    eventForRecurringDepositType(this.recurringIntervalInputSelector, this.remaindingPaymentTypeSelector);
  }
}
