import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';
import handleLockForm from '../shared/handleLockForm';

class RetirementSummariesController {
  editRetirementTarget() {
    handleSideMenu();
    handleUploadAvatar();
    handleLockForm(['#client-retirement-target-form']);
  }
}

Raicon.register('clients/retirementSummaries', RetirementSummariesController);
