import Raicon from 'raicon';
import LoanForm from '../../consultant/netwealths/loanForm';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';
import handleLockForm  from '../shared/handleLockForm';

class ClientsLiabilitiesController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  new() {
    handleMoneyField();
    new LoanForm().setup();
  }

  edit() {
    handleMoneyField();
    new LoanForm().setup();
    handleLockForm(['#loan-liability-form']);
  }

  create() {
    handleMoneyField();
    new LoanForm().setup();
  }

  newLiabilitySelection() {}
}

Raicon.register('clients/liabilities', ClientsLiabilitiesController);
