import { copyExecuted } from '@/layouts/application/utils/copyExecuted';
import { activeByCheckBox } from '@/layouts/application/utils/activeButtonWithCondition'
import Raicon from 'raicon';
import Swiper from 'swiper/swiper-bundle.min';

class IpreciatesController {
  constructor() {
    this.carouselSwiper = '.carousel-swiper';
    this.swiperInstance = null;
  }

  index() {
    this.handleTabSwitch();
    this.initializeSwiper();
    this.checkMobileWidthAndAdjustText();
  }

  new() {
    activeByCheckBox('#ipreciate-submit-btn');
  }

  create() {
    activeByCheckBox('#ipreciate-submit-btn');
  }

  thankYou() {
    copyExecuted('#copy-ipreciate-link', '#ipreciate-link');
  }

  handleTabSwitch() {
    $('.text-options[data-toggle="tab"]').on('shown.bs.tab', (e) => {
      var targetTabId = $(e.target).attr('href').substring(1);
      if (targetTabId === 'our_team') {
        if (this.swiperInstance) {
          this.swiperInstance.destroy(true, true);
        }
        this.initializeSwiper();
      }
    });
  }

  initializeSwiper() {
    this.swiperInstance = new Swiper(this.carouselSwiper, {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 3,
      loop: true,
      loopedSlides: 2,
      loopAdditionalSlides: 2,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 200,
        modifier: 2,
        slideShadows: false,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

  checkMobileWidthAndAdjustText() {
    const adjustText = () => {
      if ($(window).width() <= 450) {
        $('#voices_of_clients_tab .text-options').html('Voices <br/>Of Clients');
        $('#resources_tab .text-options').html('Our<br/>Commitment');
        $('#our_team_tab .text-options').html('Our<br/>Team');
      } else {
        $('#voices_of_clients_tab .text-options').html('Voices Of Clients');
        $('#resources_tab .text-options').html('Our Commitment');
        $('#our_team_tab .text-options').html('Our Team');
      }
    };

    adjustText();

    $(window).on('resize', adjustText);
  }
}

Raicon.register('ipreciates', IpreciatesController);
