import initDoughnutChart from '@/layouts/application/utils/charts/initDoughnutChart';

import {
  PORTFOLIO_HEALTH_COLOR,
  PORTFOLIO_INVESTMENT_COLOR,
  PORTFOLIO_ENDOWMENT_COLOR,
  PORTFOLIO_WHOLE_LIFE_COLOR,
  PORTFOLIO_PERSONAL_ACCIDENTS_COLOR,
  PORTFOLIO_TERM_LIFE_COLOR,
  PORTFOLIO_OTHERS_COLOR,
  PORTFOLIO_DISABILITY_COLOR,
  NET_WEALTH,
  LIABILITY,
  EMPTY
} from '@/layouts/application/utils/charts/chartColors';

export default class InsurancePolicyChart {
  setup(){
    this.drawPortfolioSummaryChart();
  }

  drawPortfolioSummaryChart(){
    const $chartElement = $('#portfolio-chart');
    const chartData = $chartElement.parent().data('calculation');
    const empty_chart_data = [0, 0, 0, 0, 0, 0, 0];
    const is_empty_chart = this.compareArr(chartData, empty_chart_data);

    if (!is_empty_chart) {
      initDoughnutChart($chartElement, chartData, [PORTFOLIO_INVESTMENT_COLOR, PORTFOLIO_ENDOWMENT_COLOR, PORTFOLIO_WHOLE_LIFE_COLOR, PORTFOLIO_PERSONAL_ACCIDENTS_COLOR, PORTFOLIO_TERM_LIFE_COLOR, PORTFOLIO_HEALTH_COLOR, PORTFOLIO_OTHERS_COLOR, PORTFOLIO_DISABILITY_COLOR]);
    } else {
      initDoughnutChart($chartElement, [100], [EMPTY]);
    }
  }

  compareArr(arr1, arr2) {
    return arr1.every((v, i) => v === arr2[i]);
  }
}
