import { formatCurrency, formatCurrencyToNumber } from '@/layouts/application/utils/formats';

export default class DreamUpdateProgress {
  constructor() {
    this.savingProgressInputSelector = '.saving-progress-input';
    this.submitButtonSelector = '.save-saving-progress-btn';
    this.closeModalButtonSelector = '.modal-close-btn';
    this.totalSaveAmountValueSelecor = '#total-saved-amount-value';
  }

  setup() {
    this.setOnsubmitEvent();
    this.addOnchangeEventForSavingInput();
    this.calculateTotalSavedAmount();
  }

  addOnchangeEventForSavingInput() {
    $(this.savingProgressInputSelector).on('input', ({ target }) => {
      const dreamId = $(target).data('dream-id');
      const value = parseFloat(target.value) || 0;

      if (dreamId) {
        this.adjustProgressBar(dreamId, value);
        this.calculateTotalSavedAmount();
      }
    });
  }

  setOnsubmitEvent() {
    $(this.submitButtonSelector).on('click', () => {
      const data = this.buildDataForUpdateProgressRequest();
      const clientId = $('body').data('client-id');

      $.post(Routes.bulk_update_saving_progress_consultant_client_dreams_path({ client_id: clientId }), {
        data: data,
      }).done(() => {
        $(this.closeModalButtonSelector)[0].click();
      });
    });
  }

  adjustProgressBar(dreamId, value) {
    value = value ? value : 0;

    const $progressBarByDreamId = $(`#progress-bar-dream-${dreamId}`);

    const $targetValue = $(`#target-value-${dreamId}`);
    const tartetValue = formatCurrencyToNumber($targetValue.html());

    const $savedValue = $(`#amount-saved-value-${dreamId}`);

    let progressInPercent = (value / tartetValue) * 100;

    if (progressInPercent > 100) {
      progressInPercent = 100;
    }

    $savedValue.html(formatCurrency(value));
    $progressBarByDreamId.css('width', `${progressInPercent}%`);
  }

  calculateTotalSavedAmount() {
    let totalValue = 0;
    $(this.savingProgressInputSelector).each((index, element) => {
      const inputValue = parseFloat($(element).val());
      
      if (inputValue) {
        totalValue += inputValue;
      }
    });

    $(this.totalSaveAmountValueSelecor).html(formatCurrency(totalValue));
  }

  buildDataForUpdateProgressRequest() {
    let data = [];

    $(this.savingProgressInputSelector).each((index, element) => {
      data.push({
        dream_id: $(element).data('dream-id'),
        progress_value: $(element).val(),
      });
    });

    return data;
  }
}
