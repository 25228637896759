export function activeByCheckBox(btn, checkbox = '.check-privacy-box') {
  handleToggle(btn, checkbox);
  $(checkbox).on('click', () => {
    handleToggle(btn, checkbox);
  })
}

const handleToggle = (btn, checkbox) => {
  if ($(checkbox).is(':checked')) {
    $(btn).prop('disabled', false);
  } else {
    $(btn).prop('disabled', true);
  }
}