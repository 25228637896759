import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

class AdminLeaderboardsController {
  index() {
    this.initDropZone();
  }

  initDropZone() {
    new DropZoneForm({
      isLoading: true,
      isFailedRecordsLink: true,
      overrideInput: '.override-duplication',
      formSelector: '#import-leaderboard-form',
      submitBtnSelector: '#submit-import-leaderboard',
      fileParamName: 'leaderboard[leaderboard_file]',
      importResultFormSelector: '#import-leaderboard-result-form',
      importBtnSelector: [
        '#import-leaderboard-form',
        '#import-leaderboard-button'
      ],
    }).setup();
  }
}

Raicon.register('admin/leaderboards', AdminLeaderboardsController);
