import Raicon from 'raicon';
import { getParamFromUrl } from '@/layouts/application/utils/checkParamsExist';
class TutorialManagersController {
  index() {
    this.openDirectPage();
    this.formatYoutubeContent();
    this.trackingUser();
  }

  trackingUser(){
    $(document).on('click', '.collapse-btn', (e) => {
      const postId = $(e.target).data('page-id');
      $.post(Routes.tracking_consultant_tutorial_page_path(postId))
    })
  }

  formatYoutubeContent(){
    $('.category-item').on('click', (element) => {
      let clickElement = $(element.target).next().prevObject[0].dataset.target;
      $(clickElement).find('a').toArray().forEach(e => {
        const href = e.href
        if (href.includes('youtube.com')) {
          $(e).replaceWith(`<iframe width="500" height="400" src="${href}" allowfullscreen> </iframe>`)
        }
      });
    });
  }

  openDirectPage(){
    const slug = getParamFromUrl('page');
    if(slug == null) return;
    const pageButton = $(`button[data-direct-page="${slug}"]`)
    const categoryButton = $(`button[data-target="${pageButton.data('category-page')}"]`)
    if(slug == undefined || slug == null || pageButton.length === 0) return;

    setTimeout(() => {
      $('html, body').animate({
        scrollTop: $(`#${slug}`).offset().top
      }, 1000);
    }, 100)

    const postId = pageButton.data('page-id');
    $.post(Routes.tracking_consultant_tutorial_page_path(postId))

    categoryButton.trigger('click');
    pageButton.trigger('click');
  }
}

Raicon.register('consultant/tutorialPages', TutorialManagersController);
