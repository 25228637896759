import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';

class AdminCompanyEventsController {
  index() {
    changeParamenterByTab('company_events', 'current_status')
    this.handleToggleSelectedFcList();
    this.initDropZone();
  }

  handleToggleSelectedFcList() {
    $('body').on('modal:afterSetContent', '#open-new-company-events, #edit-company-event-link, #company-event-form', () => {
      const currentOption = $('.fc-access-option:checked').val();
      this.toggleSelectedFcList(currentOption);
      $('.fc-access-option').on('change', (e) => {
        this.toggleSelectedFcList(e.target.value);
      });
    });
  }

  toggleSelectedFcList(option) {
    if (option == 'all') {
      $('#selected-fc-list').hide()
    } else {
      $('#selected-fc-list').show()
    }
  }

  initDropZone() {
    new DropZoneForm({
      isFailedRecordsLink: true,
      formSelector: '#import-event-attendance',
      submitBtnSelector: '#submit-import-event-attendance',
      fileParamName: 'company_event[event_attendance_file]',
      importResultFormSelector: '#update-attendance-result-form',
      importBtnSelector: [
        '#import-rsvps-button',
        '#import-event-attendance',
        '#import-event-attendance-button',
      ]
    }).setup();
  }
}

Raicon.register('admin/companyEvents', AdminCompanyEventsController);
