import { formatCurrency, formatMoneyToNumber } from '@/layouts/application/utils/formats';
import ToggleSharedDreamEvent from './shared/toggleSharedDreamEvent';
import updateAgeByInput from './shared/updateAgeByInput';
import checkValidDreamStartDate from './shared/checkInvalidStartDate';
import { DEFAULT_CURRENCY } from '@/constants';

export default class DreamTravelForm {
  constructor() {
    this.containerSelector = '.dream-travel-form';
    this.ballparkExpenseTotalCostSelector = '.with-ballpark-expense input[name="dream[travel_cost]"]';
    this.detailExpenseTotalCostSelector = '.detail-expense-total-cost-amount';
    this.ballparkExpensePaymentAmountSelector = '.ballpark-expense-payment-amount';
    this.detailExpensePaymentAmountSelector = '.detail-expense-payment-amount';
    this.detailExpenseAirFareSelector = 'input[name="dream[travel_expense_detail_air_fare]"]';
    this.detailExpenseAccomodationSelector = 'input[name="dream[travel_expense_detail_accomodation]"]';
    this.detailExpenseTransportationSelector = 'input[name="dream[travel_expense_detail_transportation]"]';
    this.detailExpenseFoodSelector = 'input[name="dream[travel_expense_detail_food]"]';
    this.detailExpenseAttractionSelector = 'input[name="dream[travel_expense_detail_attraction]"]';
    this.detailExpenseOthersSelector = 'input[name="dream[travel_expense_detail_others]"]';
    this.expenseTypeInputSelector = 'input[name="dream[travel_expense_type]"]';
    this.purchaseStartDateSelector = '#date-expected-input';
    this.ageExpectedShowSelector = '#age-happen-value';
    this.clientBaseCurrency = $('body').data('client-base-currency') || DEFAULT_CURRENCY;
  }

  setup() {
    new ToggleSharedDreamEvent().setup();

    updateAgeByInput(this.purchaseStartDateSelector, this.ageExpectedShowSelector);
    checkValidDreamStartDate(this.purchaseStartDateSelector);

    $(this.containerSelector).on('change', this.expenseTypeInputSelector, () => {
      this.syncExpenseTypeMethod();
    });

    $(this.containerSelector).on('input', this.ballparkExpenseTotalCostSelector, () => {
      if (this.getExpenseType() === 'ballpark') {
        this.syncBallParkExpenseTypeAmounts();
      }
    });

    $(this.containerSelector).on(
      'input',
      [
        this.detailExpenseAirFareSelector,
        this.detailExpenseAccomodationSelector,
        this.detailExpenseTransportationSelector,
        this.detailExpenseFoodSelector,
        this.detailExpenseAttractionSelector,
        this.detailExpenseOthersSelector,
      ].join(', '),
      () => {
        if (this.getExpenseType() === 'detail') {
          this.syncDetailExpenseTypeAmounts();
        }
      },
    );

    // trigger for initial state
    if (!this.getExpenseType()) {
      $(`${this.expenseTypeInputSelector}[value="ballpark"]`).attr('checked', 'checked');
    }

    $(this.purchaseStartDateSelector).on('input', () => {
      checkValidDreamStartDate(this.purchaseStartDateSelector);
    });

    this.syncExpenseTypeMethod();
  }

  syncExpenseTypeMethod() {
    const expenseType = this.getExpenseType();
    const $withBallParkExpenseElements = $(`${this.containerSelector} .with-ballpark-expense`);
    const $withDetailExpenseElements = $(`${this.containerSelector} .with-detail-expense`);

    if (expenseType === 'ballpark') {
      $withDetailExpenseElements.addClass('d-none');
      $withBallParkExpenseElements.removeClass('d-none');
    }

    if (expenseType === 'detail') {
      $withDetailExpenseElements.removeClass('d-none');
      $withBallParkExpenseElements.addClass('d-none');
    }
  }

  syncBallParkExpenseTypeAmounts() {
    const totalCost = this.getBallparkExpenseTotalCostAmount();
    $(this.ballparkExpensePaymentAmountSelector).text(formatCurrency(totalCost, this.clientBaseCurrency));
  }

  syncDetailExpenseTypeAmounts() {
    const totalCost = [
      this.getDetailExpenseAirFareAmount(),
      this.getDetailExpenseAccomodationAmount(),
      this.getDetailExpenseTransportationAmount(),
      this.getDetailExpenseFoodAmount(),
      this.getDetailExpenseAttractionAmount(),
      this.getDetailExpenseOthersAmount(),
    ].reduce((total, value) => total + value);

    $(this.detailExpenseTotalCostSelector).text(formatCurrency(totalCost, this.clientBaseCurrency));
    $(this.detailExpensePaymentAmountSelector).text(formatCurrency(totalCost, this.clientBaseCurrency));
  }

  getExpenseType() {
    return $(`${this.expenseTypeInputSelector}:checked`).val();
  }

  getBallparkExpenseTotalCostAmount() {
    return parseFloat(formatMoneyToNumber($(this.ballparkExpenseTotalCostSelector).val())) || 0;
  }

  getBallparkExpenseTotalCostAmount() {
    return parseFloat(formatMoneyToNumber($(this.ballparkExpenseTotalCostSelector).val())) || 0;
  }

  getDetailExpenseAirFareAmount() {
    return parseFloat(formatMoneyToNumber($(this.detailExpenseAirFareSelector).val())) || 0;
  }

  getDetailExpenseAccomodationAmount() {
    return parseFloat(formatMoneyToNumber($(this.detailExpenseAccomodationSelector).val())) || 0;
  }

  getDetailExpenseTransportationAmount() {
    return parseFloat(formatMoneyToNumber($(this.detailExpenseTransportationSelector).val())) || 0;
  }

  getDetailExpenseFoodAmount() {
    return parseFloat(formatMoneyToNumber($(this.detailExpenseFoodSelector).val())) || 0;
  }

  getDetailExpenseAttractionAmount() {
    return parseFloat(formatMoneyToNumber($(this.detailExpenseAttractionSelector).val())) || 0;
  }

  getDetailExpenseOthersAmount() {
    return parseFloat(formatMoneyToNumber($(this.detailExpenseOthersSelector).val())) || 0;
  }
}
