import Raicon from 'raicon';

class ClientsPagesController {
  welcome(){
    // this.setupCarousel();
    // this.setupWelcomeClientPortal();
  }

  setupCarousel(){
    const carouselLength = $('.carousel-item').length - 1;

    // If there is more than one item
    if (carouselLength) {
        $('.carousel-control-next').removeClass('d-none');
    }

    $('.carousel').carousel({
        interval: false,
        wrap: false
    }).on('slide.bs.carousel', function (e) {
        // First one
        if (e.to == 0) {
            $('.carousel-control-prev').addClass('d-none');
            $('.carousel-control-next').removeClass('d-none');
        } // Last one
        else if (e.to == carouselLength) {
            $('.carousel-control-prev').removeClass('d-none');
            $('.carousel-control-next').addClass('d-none');
        } // The rest
        else {
            $('.carousel-control-prev').removeClass('d-none');
            $('.carousel-control-next').removeClass('d-none');
        }
    });
  }

  setupWelcomeClientPortal(){
    if(localStorage.getItem('firstOpenedApp') === null){
      localStorage.setItem('firstOpenedApp', true)
    } else {
      $('#carsousel-welcome-wrapper').addClass('d-none');
      $('.welcome-wrapper').css('height', '100vh');
      $('.welcome-wrapper').css('visibility', 'unset');
      $('.footer').removeClass('d-none');
    }
  }
}

Raicon.register('clients/pages', ClientsPagesController);
