import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

export default class ImportInsurancePoliciesForm extends DropZoneForm {
  constructor() {
    super({
      isLoading: true,
      isFailedRecordsLink: true,
      overrideInput: '.override-duplication',
      formSelector: '#import-insurance-policies-form',
      submitBtnSelector: '#submit-import-insurance-policy',
      fileParamName: 'insurance_policy[import_policy_file]',
      importResultFormSelector: '#import-policies-result-form',
      gotItBtnSelector: '#got-it-button-import-people',
      importBtnSelector: [
        '#import-insurance-policies-from',
        '#import-insurance-policies-button',
      ]
    })
  }
}
