import Raicon from 'raicon';
import InsurancePolicyForm from './insurancePolicyForm';
import showAttentionNeededForEvents from '../shared/showEventProgressByPercentage';
import InsurancePolicyChart from './insurancePolicyChart';
import { queryParamExistUrl } from '@/layouts/application/utils/checkParamsExist';
import { add10Referees } from '../add10Referees';
import GiftingCalculatorClientForm from '../clients/giftingCalculatorForm';
import AddFamilyMemberAction from '../dreams/addFamilyMemberAction';
import dynamicIncludedMonth from '../dynamicIncludedMonth';
import Tablesort from 'tablesort';
import { bulkDeleteItem } from '../shared/bulkDeleteItem';
import { handleHideSignSgd } from '../shared/handleHideSignSgd';
import OverlayCanvas from '../../shared/overlayCanvas';

class ConsultantInsurancesController {
  index() {
    this.setupInsurances();
    this.setupEmergencyNetworks();
    showAttentionNeededForEvents();
    this.triggerPopupPolicyWakeup();
    this.getCurrentTotalLiabilities();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    this.handleToggleDreamPlanning();
    this.setupGiftingCalculatorForm();
    $('.bulk-delete-btn').each(function () {
      let id = $(this).attr('id');
      let indexDeleteButton = id.split('bulk-delete-insurance-')[1];
      bulkDeleteItem(`#bulk-delete-insurance-${indexDeleteButton}`, `.insurance-checkbox-${indexDeleteButton}`);
    });
    //include portfolio summary chart to index
    new InsurancePolicyChart().setup();
    showAttentionNeededForEvents();
    this.getCurrentTotalLiabilities();
    this.setupTableSort();
    this.setupOverlayCanvas();
    handleHideSignSgd('#currency-select', '.property-currency');
  }

  setupGiftingCalculatorForm() {
    new GiftingCalculatorClientForm().setup();
  }

  setupOverlayCanvas() {
    new OverlayCanvas().setup();
  }

  triggerPopupPolicyWakeup() {
    if (queryParamExistUrl('policy_type')) {
      $('#policy-button-init')[0].click();
    }
  }

  setupTableSort() {
    let search = '_table';
    Object.keys(localStorage)
      .filter((key) => key.endsWith(search))
      .map((key) => {
        return localStorage.removeItem(key);
      });

    $('.tablesort').each((_, table) => {
      new Tablesort(table);

      table.addEventListener('afterSort', ({ target }) => {
        const tableSelector = $(target);
        const tableName = tableSelector.attr('id');
        const insuranceIds = [];

        localStorage.removeItem(tableName);
        tableSelector.find('tr').each((_, data) => {
          const $data = $(data);
          if ($data.data('insurance-id')) {
            insuranceIds.push($data.data('insurance-id'));
          }
        });

        localStorage.setItem(tableName, insuranceIds);
      });
    });
  }

  setupInsurances() {
    new InsurancePolicyForm().setup();
  }

  setupEmergencyNetworks() {
    $('body').on(
      'modal:afterSetContent',
      '#edit-emergency-network-btn, #new-emergency-network-btn, #emergency-network-form, #edit-emergency-network-form, #back-link',
      () => {
        new AddFamilyMemberAction().setup('.emergency-network-modal');
        this.handleSelectAllBtn();
        this.handleDeselectAllBtn();
      },
    );
  }

  getCurrentTotalLiabilities() {
    const getCurrent = '#get-current-liabilities';
    const totalLiabilities = '#total-liabilities';

    $('body').on('modal:afterSetContent', '#death-event, #edit-event-event_death-form', () => {
      $(getCurrent).on('click', () => {
        const liabilities = $(getCurrent).attr('data-current-liabilities');
        $(totalLiabilities).val(liabilities);
      });
    });
  }

  handleSelectAllBtn() {
    $('#select-all-btn').on('click', () => {
      $('.insurance-list input').prop('checked', true);
    });
  }

  handleDeselectAllBtn() {
    $('#deselect-all-btn').on('click', () => {
      $('.insurance-list input').prop('checked', false);
    });
  }

  handleToggleDreamPlanning() {
    $('body').on('modal:afterSetContent', '#add-policy-button, #edit-policy-button, #insurance-policy-form', () => {
      this.toggleDreamPlanningEvent();
      this.eventForPayoutOption();
      new dynamicIncludedMonth().setup('#premium-interval-type');
      $('#currency-select').trigger('change');
    });


    $('body').on('modal:afterSetContent', '#back-link, #insurance-policy-form', () => {
      this.toggleDreamPlanningEvent();
    })
  }

  toggleDreamPlanningEvent() {
    const $checkbox = $('#dream-planing-checkbox');
    const $recurringForm = $('.payout-option-form');

    this.toggleElementByCheckboxEvent($checkbox, $recurringForm);
  }

  toggleElementByCheckboxEvent($checkbox, $form) {
    if ($checkbox.is(':checked')) {
      $form.show();
    } else {
      $form.hide();
    }

    $checkbox.on('change', function () {
      if (this.checked) {
        $form.show();
      } else {
        $form.hide();
      }
    });
  }

  eventForPayoutOption() {
    let payoutOptionValue = $('#payout-option-selector').val();
    this.togglePayoutOption(payoutOptionValue);

    $('#payout-option-selector').on('change', ({ target }) => {
      payoutOptionValue = target.value;

      this.togglePayoutOption(payoutOptionValue);
    });
  }

  togglePayoutOption(payoutOption) {
    const $lumpSumPayoutOptionSelector = $('#lump-sum-payout-option');
    const $recurringPayoutOption = $('#recurring-payout-option');

    if (payoutOption == 'lump_sum_payout') {
      $lumpSumPayoutOptionSelector.show();
      $recurringPayoutOption.hide();
    } else if (payoutOption == 'recurring_payout') {
      $lumpSumPayoutOptionSelector.hide();
      $recurringPayoutOption.show();
    } else {
      $lumpSumPayoutOptionSelector.hide();
      $recurringPayoutOption.hide();
    }
  }
}

Raicon.register('consultant/insurances', ConsultantInsurancesController);
