import Raicon from 'raicon';

import { toggleTdTable, checkboxSelected } from '@/layouts/application/utils/multipleSelectTable';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';

class AdminUserManagementsController {
  index() {
    checkboxSelected();
    toggleTdTable();
    changeParamenterByTab('user_managements', 'active_tab');

    this.addTriggerOpenModalEvents();
  }

  addTriggerOpenModalEvents() {
    $('body').on('click', '#bulk-update-clients-consultant', () => {
      this.addIdsToAddLabelsAndUpdateRating();
    });
  }

  addIdsToAddLabelsAndUpdateRating() {
    const selectBox = $('.select-box:checked');
    const clientIds = $('#bulk-update-clients-consultant-client-ids');
    let ids = [];

    selectBox.each(function () {
      const value = $(this).data('value');
      ids.push(value);
    });

    clientIds.val(ids);
  }
}

Raicon.register('admin/userManagements', AdminUserManagementsController);
