export default class ConsultantActionFriendshipCompatibilityForm {
  constructor() {
    this.formSelector = '#quiz-information-form';
    this.stepQueues = [];
    this.currentStep = 0;
    this.stepQuizFriendship = [
      '#information-step-1',
      '#information-step-2',
      '#information-step-3',
      '#information-step-4',
      '#information-step-5',
      '#information-step-6',
      '#information-step-7',
    ];
    this.navigationSection = '#navigation-section';
    this.nextButton = '#next-button';
    this.backButton = '#back-button';
    this.submitStep = '#submit-step';
    this.submitButton = '#submit-button input';
  }

  setup() {
    this.stepQueues = this.stepQuizFriendship;
    this.handleNextButton('create');
    this.initialAction()
  }

  initialAction() {
    if (this.currentStep == 0) {
      $(this.stepQueues[this.currentStep]).removeClass('d-none')
    }

    this.handleChoseAnswerToNext();
    this.hanldeBackButton()
  }


  handleChoseAnswerToNext() {
    this.stepQueues.forEach((step, index) => {
      $(step).find('.collection_radio_buttons').on('click', () => {
        setTimeout(() => {
          $(this.nextButton).trigger('click');
        }, 50);
      })
    });
  }

  handleNextButton(type){
    $(this.nextButton).on('click', () => {
      if(type == 'create'){
        this.nextStep();
      } else {
        this.nextStepForShow();
      }
    })
  }

  nextStep(){
    let validCheckedInput = $(this.stepQueues[this.currentStep]).find('input:radio, input:checkbox').is(":checked")

    if (validCheckedInput) {
      $(this.stepQueues[this.currentStep]).addClass('d-none');
      $(this.navigationSection).removeClass('d-none');
      this.setStep(this.currentStep + 1);
      $(this.stepQueues[this.currentStep]).removeClass('d-none');
    } else {
      alert('Please choose an answer!');
    }
  }

  setStep(nextStep) {
    this.currentStep = nextStep;
    this.navigateToNextStep();
    this.updateNavigationSectionDisplay();
    this.updateAnswer();
  }

  navigateToNextStep() {
    this.stepQueues.forEach((step, index) => {
      const $indexStepElement = $(step);
      if (index == this.currentStep) {
        $indexStepElement.removeClass('d-none');
      } else {
        $indexStepElement.addClass('d-none');
      }
    })
  }

  updateNavigationSectionDisplay() {
    if (this.currentStep >= this.stepQueues.length) {
      $(this.navigationSection).addClass('d-none');
    } else {
      $(this.navigationSection).removeClass('d-none');
    }
  }

  updateAnswer() {
    if (this.currentStep == this.stepQueues.length) {
      $(this.submitButton).trigger('click')
    } else {
      $(this.submitStep).addClass('d-none');
    }
  }

  hanldeBackButton(){
    $(this.backButton).on('click', () => {
      if(this.currentStep === 1) {
        $('.disclaimer').removeClass('d-none');
      } else {
        $('.disclaimer').addClass('d-none');
      }
      this.backStep();
    })
  }

  backStep() {
    const MIN_STEP = 0

    $(this.stepQueues[this.currentStep]).addClass('d-none');
    this.currentStep -= 1;
    if(this.currentStep === MIN_STEP){
      $( this.navigationSection).addClass('d-none')
    }
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
  }
}
