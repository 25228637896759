import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';
import {
  emailFormatValidator,
  presentValidator,
  dobInThePastValidator,
  setupValidatorBaseOnCountryCode,
} from '@/layouts/application/utils/validators';
import { activeByCheckBox } from '@/layouts/application/utils/activeButtonWithCondition';

class PreFactFindController {
  constructor() {
    this.formSelector = '#preff-information-form';
    this.calendlyContianerSelector = '#calendly-container';
    this.backButtonSelector = '#back-button';
    this.nextButtonSelector = '#next-button';
    this.nextButtonBtnMain = `${this.nextButtonSelector} .btn-main`;
    this.getStartedButtonSelector = '#get-started-button';
    this.navigationSectionSelector = '#navigation-section';
    this.submitButtonSelector = '#submit-button';
    this.currentStep = 0;
    this.formInformationStepsSelector = '#form-information-steps';
    this.dreamGoalGroup = '.dream-goal-group';
    this.dreamGoalValues = '#dream-goal-values';
  }

  stepOrder = ['#welcome-step', '#information-step', '#financial-information-step', '#financial-goal-step'];
  financialGoalCheck = [
    '#car',
    '#house',
    '#travel',
    '#education',
    '#start-a-business',
    '#having-a-child',
    '#wedding',
    '#retirement',
  ];

  firstPageRequiredFields = [
    {
      selector: '.pre_fact_find_personality_wonder',
      validators: [presentValidator],
    },
    {
      selector: '.pre_fact_find_money_provider_you_with',
      validators: [presentValidator],
    },
  ];

  secondPageRequiredFields = [
    {
      selector: '#first-name-input',
      validators: [presentValidator],
    },
    {
      selector: '#dob-input',
      validators: [presentValidator, dobInThePastValidator],
    },
    {
      selector: '#email-input',
      validators: [presentValidator, emailFormatValidator],
    },
    {
      selector: '#mobile-number-input',
      validators: [presentValidator],
    },
    {
      selector: '#find-out-reason-input',
      validators: [presentValidator],
    },
    {
      selector: '#employment-status-input',
      validators: [presentValidator],
    },
    {
      selector: '#monthly-income-range-input',
      validators: [],
    },
    {
      selector: '#occupation-input',
      validators: [],
    },
    {
      selector: '#other-occupation-input',
      validators: [],
    },
    {
      selector: '.financial-advisor-experience-input',
      validators: [presentValidator],
    },
  ];

  index() {
    this.setup();
    activeByCheckBox('#pre-appointment-submit-btn');
  }

  createPreFactFind() {
    this.setup();
    this.displayFirstPageError();
    activeByCheckBox('#pre-appointment-submit-btn');
  }

  show() {
    this.currentStep = 1;
    this.setupNavigationActions();
    this.displayCollectionCheckBoxeFinacialGoal();
  }

  setup() {
    this.setupCalendly();
    this.handleEmploymentStatus();
    this.setupNavigationActions();
    this.initDropZone();
    this.handleSelectDreamGoal();
    this.displayCollectionCheckBoxeFinacialGoal();
  }

  handleSelectDreamGoal() {
    $(this.dreamGoalGroup).on('click', (e) => {
      const dreamName = $(e.target).attr('id');
      let dreamGoalValues = $(this.dreamGoalValues).val().split(',');
      let imgSelector = $(e.target);
      if (!$(imgSelector).is('img')) {
        imgSelector = $(imgSelector).find('img');
      }

      if (dreamGoalValues.includes(dreamName)) {
        dreamGoalValues = this.removeElementFromArray(dreamGoalValues, dreamName);
        $(imgSelector).removeClass('selected');
      } else {
        dreamGoalValues.push(dreamName);
        $(imgSelector).addClass('selected');
      }

      $(this.dreamGoalValues).val(dreamGoalValues.join(','));
    });
  }

  removeElementFromArray(arr, ele) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] == ele) {
        arr.splice(i, 1);
        i--;
      }
    }

    return arr;
  }

  displayFirstPageError() {
    const $formInformationSteps = $(this.formInformationStepsSelector);

    if ($formInformationSteps.length > 0) {
      const firstPageError = parseInt($formInformationSteps.data('first-page-error'));
      this.setStep(firstPageError || 0);
    }
  }

  displayCollectionCheckBoxeFinacialGoal() {
    let dreamGoalValue = $('#dream-goal-values').val().split(' ');

    if (dreamGoalValue[0] != '') {
      dreamGoalValue.forEach(function (img_id, index) {
        $('.dream-goal-group').find(`#${img_id}`).css('opacity', '1');
      });
    }
  }

  setUpValueClickMoneyProviderPersonalityWonder(currentStep) {
    let personalityWonderChecker = false;
    let moneyProviderChecker = false;

    if (currentStep == 2) {
      $(this.nextButtonBtnMain).addClass('disabled');
      $(this.nextButtonSelector).attr('disabled', true);

      $(document).on('click', () => {
        $('.pre_fact_find_personality_wonder')
          .find('input[type="radio"]')
          .each(function (index, inputValue) {
            if (inputValue.checked) {
              $('.pre_fact_find_personality_wonder').val(inputValue.value);
              personalityWonderChecker = true;
            }
          });

        $('.pre_fact_find_money_provider_you_with')
          .find('input[type="radio"]')
          .each(function (index, inputValue) {
            if (inputValue.checked) {
              $('.pre_fact_find_money_provider_you_with').val(inputValue.value);
              moneyProviderChecker = true;
            }
          });

        if (personalityWonderChecker && moneyProviderChecker) {
          $(this.nextButtonBtnMain).removeClass('disabled');
          $(this.nextButtonSelector).attr('disabled', false);
        }
      });
    }
  }

  setupCalendly() {
    const $calendlyContianer = $(this.calendlyContianerSelector);

    if ($calendlyContianer.length > 0) {
      const calendlyUserName = $calendlyContianer.data('calendly-username');

      $(this.calendlyContianerSelector).html(`
        <!-- Calendly inline widget begin -->
        <div class="calendly-inline-widget" data-url="https://calendly.com/${calendlyUserName}?hide_landing_page_details=1&hide_gdpr_banner=1" style="min-width:320px;height:630px;"></div>
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
        <!-- Calendly inline widget end -->
      `);
    }
  }

  handleEmploymentStatus() {
    $('#employment-status-input').on('change', ({ target }) => {
      const value = target.value;
      const leadRequiredFields = [
        '#first-name-input',
        '#dob-input',
        '#email-input',
        '#mobile-number-input',
        '#find-out-reason-input',
      ];
      const hasSalaryEmployeeStatus = ['full_time', 'part_time', 'self_employed'];
      const optionSalaryEmployeeStatus = ['househusband', 'housewife', 'retired', 'student', 'unemployed'];
      let financeRequiredFields = [];

      if (hasSalaryEmployeeStatus.includes(value)) {
        $('#occupation-input').val('').trigger('change');
        financeRequiredFields = [
          '#monthly-income-range-input',
          '#occupation-input',
          '#employment-status-input',
          '.financial-advisor-experience-input',
        ];
      } else if (optionSalaryEmployeeStatus.includes(value)) {
        financeRequiredFields = [
          '#occupation-input',
          '#employment-status-input',
          '.financial-advisor-experience-input',
        ];
        $('#occupation-input')
          .val(`${value[0].toUpperCase()}${value.slice(1)}`)
          .trigger('change');
      } else {
        $('#occupation-input').val('').trigger('change');
        financeRequiredFields = [
          '#monthly-income-range-input',
          '#other-occupation-input',
          '#employment-status-input',
          '.financial-advisor-experience-input',
        ];
      }
      const requiredFields = [...financeRequiredFields, ...leadRequiredFields];
      this.secondPageRequiredFields = this.secondPageRequiredFields.map((input) => {
        if (requiredFields.includes(input.selector)) {
          if (
            !$(`label[for=${input.selector.slice(1)}]`)
              .text()
              .includes('*')
          ) {
            $(`label[for=${input.selector.slice(1)}]`).append(`<abbr title="required">*</abbr>`);
          }

          return {
            selector: input.selector,
            validators: [presentValidator],
          };
        } else {
          $(`label[for=${input.selector.slice(1)}]`)
            .find('abbr')
            .remove();
          return {
            selector: input.selector,
            validators: [],
          };
        }
      });
    });
    $('#employment-status-input').trigger('change');
  }

  initDropZone() {
    const currentStepValidationPassed = this.validateCurrentStep();
    if (!currentStepValidationPassed) e.preventDefault();

    const dropZone = new DropZoneForm({
      uploadMultiple: true,
      isValidateUpload: false,
      formSelector: this.formSelector,
      fileParamName: 'pre_fact_find[policy_files]',
      submitBtnSelector: this.submitButtonSelector,
      acceptedFiles: ['image/png', '.jpg', 'application/pdf', '.doc', '.docx'],
    });

    dropZone.setupDropZone();
    dropZone.handleSubmit();
  }

  setupNavigationActions() {
    this.setStep(this.currentStep);

    $(this.stepOrder[this.currentStep]).removeClass('d-none');

    $(this.getStartedButtonSelector).on('click', () => {
      this.setStep(1);
    });

    $(this.nextButtonSelector).on('click', () => {
      const currentStepValidationPassed = this.validateCurrentStep();

      if (currentStepValidationPassed) {
        this.setStep(this.currentStep + 1);
      }
    });

    $(this.backButtonSelector).on('click', () => {
      this.setStep(this.currentStep - 1);
    });
  }

  validateCurrentStep() {
    let currentStepValidationPassed = true;
    let currentPageSteps = [];
    let phoneNumberValidate = this.secondPageRequiredFields.find(
      (requiredField) => requiredField.selector === '#mobile-number-input',
    );
    setupValidatorBaseOnCountryCode(phoneNumberValidate, '#pre_fact_find_country_code');
    const isDisabled = $(this.formSelector).data('is-disabled');

    if (this.currentStep == 2 && isDisabled === false) {
      currentPageSteps = this.firstPageRequiredFields;
    } else if (this.currentStep == 3 && isDisabled === false) {
      currentPageSteps = this.secondPageRequiredFields;
    }

    currentPageSteps.forEach((fieldInfor) => {
      const fieldInput = $(fieldInfor.selector);
      let isValidField = true;

      fieldInfor.validators.forEach((validatorMethod) => {
        isValidField = validatorMethod.bind(this)(fieldInput.val());
        if (!isValidField) {
          currentStepValidationPassed = false;
          fieldInput.addClass('is-invalid');
          fieldInput.removeClass('is-valid');
          fieldInput.parent().parent().find('.invalid-feedback').show();
        } else {
          fieldInput.addClass('is-valid');
          fieldInput.removeClass('is-invalid');
          fieldInput.parent().parent().find('.invalid-feedback').hide();
        }
      });
    });

    return currentStepValidationPassed;
  }

  setStep(nextStep) {
    this.currentStep = nextStep;
    this.navigateToNextStep();
    this.updateNavigationSectionDisplay();
    this.setUpValueClickMoneyProviderPersonalityWonder(this.currentStep);
  }

  navigateToNextStep() {
    this.stepOrder.forEach((step, index) => {
      const $indexStepElement = $(step);

      if (index == this.currentStep) {
        $indexStepElement.removeClass('d-none');
      } else {
        $indexStepElement.addClass('d-none');
      }
    });
  }

  updateNavigationSectionDisplay() {
    if (this.currentStep == 0 || this.currentStep > 3) {
      $(this.navigationSectionSelector).addClass('d-none');
    } else {
      $(this.navigationSectionSelector).removeClass('d-none');
      if (this.currentStep == 3) {
        $(this.nextButtonSelector).addClass('d-none');
        $(this.submitButtonSelector).removeClass('d-none');
      } else {
        $(this.nextButtonSelector).removeClass('d-none');
        $(this.submitButtonSelector).addClass('d-none');
      }
    }
  }
}

Raicon.register('preFactFind', PreFactFindController);
