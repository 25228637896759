import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';

class ClientsNotificationsController {
  index() {
    handleSideMenu();
    handleUploadAvatar();
    $.ajax({
      url: Routes.mark_read_client_portal_notifications_clients_notifications_path(),
      type: 'PUT',
      dataType: 'json',
      success: function() {
        if($('#list-client-portal-notifications').length > 0) {
          $('#list-client-portal-notifications').find('.circle-red').remove();
        }
      }
    })
  }
}

Raicon.register('clients/notifications', ClientsNotificationsController);
