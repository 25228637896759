import Raicon from 'raicon';
import { copyExecutedButton } from '@/layouts/application/utils/copyExecuted';
import { initFlatPickr } from '@/layouts/application/packages/flatpickr';

class AdminTutorialManagersController {
  constructor() {
    this.selectConsultantIdsInput = '#select2-client-ids';
  }

  index() {}

  tutorialPagesIndex() {
    $(this.selectConsultantIdsInput).select2({
      dropdownParent: $('#select-fc-modal'),
    });

    copyExecutedButton('.btn-copy-excuted');
    this.onChangeSelectConsultantIds();
    this.toggleBulkChangeCategory();
    this.eventBulkChangeCategory();
  }

  toggleBulkChangeCategory() {
    $('.select-box').on('change', (e) => {
      const _this = $(e.target);
      if (_this.is(':checked')) {
        $('#option-otp').removeClass('d-none');
      } else {
        if ($('.select-box:checked').length == 0) {
          $('#option-otp').addClass('d-none');
        }
      }
    });
  }

  eventBulkChangeCategory() {
    $('.change-category-link').on('click', (e) => {
      const _this = $(e.target);
      const oldUrl = _this.attr('href');

      let ids = $.map($('.select-box:checked'), (item) => {
        return $(item).data('value');
      });

      let newUrl = `${oldUrl}&ids=${ids.join(', ')}`;
      if (e.target.id == 'reset-link') {
        newUrl = `${oldUrl}?ids=${ids.join(', ')}`;
      }
      _this.attr('href', newUrl);
    });
  }

  onChangeSelectConsultantIds() {
    $('input[name="tutorial_manager_category[target]"]').on('change', ({ target }) => {
      let value = target.value;

      if (value === 'all_fc') {
        $(this.selectConsultantIdsInput).attr('disabled', 'disabled');
      } else {
        $(this.selectConsultantIdsInput).removeAttr('disabled');
      }
    });
  }

  newTutorialPage() {
    initFlatPickr();
    this.togglePostForm();
  }

  createTutorialPage() {
    initFlatPickr();
    this.togglePostForm();
  }

  editTutorialPage() {
    initFlatPickr();
  }

  togglePostForm() {
    if ($('#is_show_form').is(':checked')) {
      $('#post-form').removeClass('d-none');
    } else {
      $('#post-form').addClass('d-none');
    }

    $('#is_show_post').on('change', (e) => {
      if ($(e.target).is(':checked')) {
        $('#post-form').removeClass('d-none');
      } else {
        $('#post-form').addClass('d-none');
      }
    });
  }
}

Raicon.register('admin/tutorialManagers', AdminTutorialManagersController);
