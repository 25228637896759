import Raicon from 'raicon';

class EventFeedbacksController {
  constructor() {
    this.otherSubjectCheckboxSelector = '#other-subject-checkbox';
    this.otherSubjectInputSelector = '#other-subject-input';
  }

  new() {
    this.handleAccessibleOtherSubjectInput(this.otherSubjectCheckboxSelector);
    $(this.otherSubjectCheckboxSelector).on('change', (e) => {
      this.handleAccessibleOtherSubjectInput(e.target);
    })
  }

  thankYou(){}

  handleAccessibleOtherSubjectInput(checkbox) {
    if($(checkbox).is(':checked')) {
      $(this.otherSubjectInputSelector).removeAttr('disabled')
    } else {
      $(this.otherSubjectInputSelector).attr('disabled','disabled');
    }
  }
}
Raicon.register('eventFeedbacks', EventFeedbacksController);
