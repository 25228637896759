import Raicon from 'raicon';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import { getAge } from '@/layouts/application/utils/calculateAgeByInput';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';
import handleLockForm  from '../shared/handleLockForm';

class ClientsExpensesController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  new() {
    handleMoneyField();
    this.handleLogicShowAge();
  }

  edit() {
    handleMoneyField();
    this.handleLogicShowAge();
    handleLockForm(['#edit_expense']);
  }

  create() {}

  newExpensesSelection() {}

  handleLogicShowAge() {
    let initialValue = getAge($('#date-of-birth-data').data('dob-data'), new Date($('#expense_end_date').val()));

    $('#age-value').html(initialValue);

    $('#expense_end_date').on('change', () => {
      let ageValue = getAge($('#date-of-birth-data').data('dob-data'), new Date($('#expense_end_date').val()));
      $('#age-value').html(ageValue);
    });
  }
}

Raicon.register('clients/expenses', ClientsExpensesController);
