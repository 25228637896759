export function changePrWhenNationalityIsSingapore() {
  toggleCorrespondencePr('.client_singapore_pr_since', '.client_malaysia_pr_since');
  toggleCorrespondencePrBaseOnNationality();
  togglePrFieldsBasedOnResidency();
}

const toggleCorrespondencePr = (singaporePrSince, malaysiaPrSince) => {
  const $checkboxSingaporePr = $(':radio[name="client[singapore_pr]"]');
  const $checkboxMalaysiaPr = $(':radio[name="client[malaysia_pr]"]');

  if ($checkboxSingaporePr.filter(':checked').val() == 'true') {
    $(`${singaporePrSince}`).removeClass('d-none');
  }

  if ($checkboxMalaysiaPr.filter(':checked').val() == 'true') {
    $(`${malaysiaPrSince}`).removeClass('d-none');
  }

  $checkboxSingaporePr.on('change', function () {
    if ($(this).filter(':checked').val() == 'true') {
      $(`${singaporePrSince}`).removeClass('d-none');
    } else {
      $(`${singaporePrSince}`).addClass('d-none');
    }
  });

  $checkboxMalaysiaPr.on('change', function () {
    if ($(this).filter(':checked').val() == 'true') {
      $(`${malaysiaPrSince}`).removeClass('d-none');
    } else {
      $(`${malaysiaPrSince}`).addClass('d-none');
    }
  });
};

const toggleCorrespondencePrBaseOnNationality = () => {
  $('#client_nationality').on('change', () => {
    const value = $('#client_nationality :selected').val();
    let options;

    switch (value) {
      case 'Singapore Citizen':
        options = [{ value: 'Malaysia PR', text: 'Malaysia PR' }];
        break;
      case 'Malaysian':
        options = [{ value: 'Singapore PR', text: 'Singapore PR' }];
        break;
      default:
        options = [
          { value: 'Singapore PR', text: 'Singapore PR' },
          { value: 'Malaysia PR', text: 'Malaysia PR' },
        ];
        break;
    }

    updatePermanentResidentOptions(options);
  });
};

const updatePermanentResidentOptions = (options) => {
  const select = $('#permanent-resident');
  select.empty();
  options.forEach((option) => {
    select.append(new Option(option.text, option.value));
  });

  $('#permanent-resident').trigger('change');
};

const togglePrFieldsBasedOnResidency = () => {
  const toggleFields = () => {
    const value = $('#permanent-resident :selected').val();

    switch (value) {
      case 'Singapore PR':
        $('#singapore-pr').show();
        $('#singapore-pr-since').show();
        $('#malaysia-pr').hide();
        $('#malaysia-pr-since').hide();
        break;
      case 'Malaysia PR':
        $('#malaysia-pr').show();
        $('#malaysia-pr-since').show();
        $('#singapore-pr').hide();
        $('#singapore-pr-since').hide();
        break;
      default:
        $('#singapore-pr').hide();
        $('#singapore-pr-since').hide();
        $('#malaysia-pr').hide();
        $('#malaysia-pr-since').hide();
        break;
    }
  };

  // Run the function when the form is first initialized
  toggleFields();

  // Run the function when the value of #permanent-resident changes
  $('#permanent-resident').on('change', toggleFields);
};
