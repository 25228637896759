import Raicon from 'raicon';
import { setupEditorFor } from '@/layouts/application/utils/CkeditorUtil.js'

class EventEmailTemplatesController {
  constructor() {
    this.richTextSelector = '.ckeditor';
  }

  index() {}

  new() {
    this.setupCkeditor();
  }

  create() {
    this.setupCkeditor();
  }

  edit() {
    this.setupCkeditor();
  }

  update() {
    this.setupCkeditor();
  }

  setupCkeditor() {
    $(this.richTextSelector).each((_index, richTextElement) => {
      setupEditorFor(richTextElement).then((result) => (this.editor = result));
    });
  }
}

Raicon.register('admin/eventEmailTemplates', EventEmailTemplatesController);
