import * as FilePond from 'filepond';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

FilePond.registerPlugin(
  FilePondPluginFilePoster,
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
);

const baseOptions = {
  storeAsFile: true,
  imagePreviewHeight: 200,
  imageCropAspectRatio: '1:1',
  imageResizeTargetWidth: 200,
  imageResizeTargetHeight: 200,
  filePosterHeight: 200,
  itemInsertLocation: 'after',
};

const generateDestroyedAttachmentArrayInputName = (name, isAllowMultiple) => {
  const baseName = isAllowMultiple ? name.slice(0, name.length - 2) : name.slice();
  const insertPosition = baseName.lastIndexOf('[');
  return `${baseName.slice(0, insertPosition + 1)}destroyed_${baseName.slice(insertPosition + 1)}[]`;
}

export default class FilepondInput {
  constructor(inputId) {
    this.inputTarget = document.getElementById(inputId);
  }

  setup() {
    let options = { ...baseOptions };
    const { dataset: { files } } = this.inputTarget;
    const inputTarget = this.inputTarget;
  
    if (files) {
      options = { ...options, files: JSON.parse(files) };
    }

    const removeInputFromExistedFiles = (rootInput, instance) => {
      const inputSelector = `.filepond--data input[name='${rootInput.getAttribute('name')}'][type='hidden']`;
      const inputElements = instance.element.querySelectorAll(inputSelector);
      inputElements.forEach((inputElement) => {
        inputElement.remove();
      });
    }

    const instance = FilePond.create(inputTarget, {
      ...options,
      oninit: () => {
        removeInputFromExistedFiles(inputTarget, instance);
        if (instance.getFile()?.getMetadata('attachment_id') === null) instance.removeFiles();
      },
      onaddfile: () => {
        removeInputFromExistedFiles(inputTarget, instance);
      },
      onremovefile: (error, file) => {
        if (error) {
          console.error(`[filepond] ${error}`);
          return;
        }

        const { attachment_id: attachmentId } = file.getMetadata();
        const filepondDataElement = instance.element.querySelector('.filepond--data');

        if (attachmentId) {
          const inputName = inputTarget.getAttribute('name');
          const pureInputName = generateDestroyedAttachmentArrayInputName(inputName, instance.allowMultiple);

          const inputElement = document.createElement('input');
          inputElement.setAttribute('type', 'hidden');
          inputElement.setAttribute('name', pureInputName);
          inputElement.setAttribute('value', attachmentId);

          filepondDataElement.appendChild(inputElement);
        }
      }
    });
  }
}
