const showAttentionNeededForEvents = () => {
  const $progressWrapper = $('.event-progress-wrapper');
  for(let i = 0; i < $progressWrapper.length; i++) {
    let progressWrapper = $progressWrapper[i];
    if (progressWrapper.getAttribute('data-progress-status') == 'attention-needed') {
      let eventId = progressWrapper.getAttribute('data-event-id');
      let eventSumAssured = progressWrapper.getAttribute('data-sum-assured');
      let eventTarget = progressWrapper.getAttribute('data-target');

      showEventProgressByPercentage(eventId, eventSumAssured, eventTarget);
    }
  }
}

const showEventProgressByPercentage = (id, sumAssured, target) => {
  const progressBarId = `#event-progress-bar-${id}`;
  const $eventBeforeProgress = $(`${progressBarId} .event-before`);

  const eventProgressBarContainers = document.querySelectorAll(".event-progress-bar-container");
  eventProgressBarContainers.forEach((container) => {
      const eventBeforeDiv = container.querySelector(".event-before");
      const eventAfterDiv = container.querySelector(".event-after");

      if (eventBeforeDiv.classList.contains("well-done") && eventAfterDiv.classList.contains("extra")) {
          eventBeforeDiv.classList.add("extra");
      }
  });

  setTimeout(() => {
    let progressBarWidth = $(`${progressBarId} .event-progress-bar`).width();
    if(progressBarWidth === 0) {
      const childPadding = 30;
      progressBarWidth = $('.card-suggestion').width() - childPadding;
    }
    const percentage = sumAssured / target;

    if (percentage <= 0.01) {
      $eventBeforeProgress.css({'height': '5px', 'margin-top': '5px', 'width': '1px'});
    }

    if (percentage > 0.01 && percentage <= 0.06) {
      $eventBeforeProgress.css({'border-radius': '50%', 'height': '11px', 'margin-top': '2px'});
    }

    if (percentage > 0.01) {
      // event before width is only 80% of progressBarWidth
      $eventBeforeProgress.width(progressBarWidth * 0.8 * percentage)
    }
  }, 250);
}

export default showAttentionNeededForEvents;
