import Raicon from 'raicon';
import Chart from 'chart.js/auto';
import merlinWithOtter from '@/images/icons/quiz/merlin_with_otter.png';
import {
  emailFormatValidator,
  presentValidator,
  setupValidatorBaseOnCountryCode,
} from '@/layouts/application/utils/validators';
import { copyExecutedButton } from '@/layouts/application/utils/copyExecuted';
import ConsultantActionFriendshipCompatibilityForm from './consultantActionFriendshipCompatibilityForm';
import { getUrlParamValueByName } from '../../../helpers';

class QuizController {
  constructor() {
    this.formSelector = '#quiz-information-form';
    this.currentStep = 0;
    this.getStarted = '#get-started-button';
    this.stepQueues = [];
    this.stepQuiz = [
      '#welcome-step',
      '#information-step-1',
      '#information-step-2',
      '#information-step-3',
      '#information-step-4',
      '#information-step-5',
      '#information-step-6',
      '#information-step-7',
      '#information-step-8',
      '#information-step-9',
      '#information-step-10',
      '#information-step-11',
      '#information-step-12',
      '#information-step-13',
      '#information-step-14',
      '#information-step-15',
      '#information-step-16',
      '#information-step-17',
      '#information-step-18',
    ];
    this.stepQuizCareer = [
      '#welcome-step',
      '#information-step-1',
      '#information-step-2',
      '#information-step-3',
      '#information-step-4',
      '#information-step-5',
      '#information-step-6',
      '#information-step-7',
      '#information-step-8',
      '#information-step-9',
      '#information-step-10',
      '#information-step-11',
      '#information-step-12',
      '#information-step-13',
      '#information-step-14',
      '#information-step-15',
      '#information-step-16',
      '#information-step-17',
      '#information-step-18',
      '#information-step-19',
      '#information-step-20',
    ];

    this.stepQuizFriendship = [
      '#welcome-step',
      '#information-step-1',
      '#information-step-2',
      '#information-step-3',
      '#information-step-4',
      '#information-step-5',
      '#information-step-6',
      '#information-step-7',
    ];

    this.navigationSection = '#navigation-section';
    this.nextButton = '#next-button';
    this.backButton = '#back-button';
    this.submitButton = '#submit-step';
    this.submitFormButton = '#submit-button';
    this.formInformationStepsSelector = '#form-information-steps';
    this.requiredFields = ['#quiz_name', '#quiz_email', '#quiz_phone_number', '#quiz_dream_with_friend'];
  }

  lastPageRequiredFields = [
    {
      selector: '#quiz_name',
      validators: [presentValidator],
    },
    {
      selector: '#quiz_email',
      validators: [presentValidator, emailFormatValidator],
    },
    {
      selector: '#quiz_phone_number',
      validators: [presentValidator],
    },
  ];

  new() {
    this.stepQueues = this.stepQuiz;
    this.handleNextButton('create');
    this.setup();
    this.handlePrivacyCheckbox();
  }

  newQuizCareer() {
    this.stepQueues = this.stepQuizCareer;
    this.handleNextButton('create');
    this.setup();
    this.handlePrivacyCheckbox();
  }

  newQuizFriendship() {
    this.lastPageRequiredFields.push({
      selector: '#quiz_dream_with_friend',
      validators: [presentValidator],
    });
    this.stepQueues = this.stepQuizFriendship;
    this.handleNextButton('create');
    this.setup();
    this.handlePrivacyCheckbox();
  }

  handlePrivacyCheckbox() {
    $('.check-privacy-box').on('click', () => {
      if ($('.check-privacy-box').is(':checked')) {
        $('.submit-quiz-button').prop('disabled', false);
      } else {
        $('.submit-quiz-button').prop('disabled', true);
      }
    });
  }

  newConsultantAnswer() {
    new ConsultantActionFriendshipCompatibilityForm().setup();
  }

  editConsultantAnswer() {
    new ConsultantActionFriendshipCompatibilityForm().setup();
  }

  create() {
    this.handleNextButton('create');
    this.setup();
  }

  show() {
    this.handleNextButton('show');
    this.setup();
  }

  resultPageChangeFriendCompatibilityAnswer() {
    copyExecutedButton('.btn-copy-excuted');
  }

  setup() {
    this.displayLastPageError();
    this.handleGetStarted();
    this.handleChoseAnswerToNext();
    this.hanldeBackButton();
    this.handleSubmited();
  }

  displayLastPageError() {
    const $formInformationSteps = $(this.formInformationStepsSelector);

    if ($formInformationSteps.length > 0) {
      const lastPageError = parseInt($formInformationSteps.data('first-page-error'));
      this.setStep(lastPageError || 0);
    }
  }

  resultPage() {
    $('#quiz_refree_fc_phone_number, #quiz_refree_fc_name').on('keyup', function (e) {
      let fc_name = $('#quiz_refree_fc_name').val();
      let fc_phone_number = $('#quiz_refree_fc_phone_number').val();
      if (fc_name != '' && fc_phone_number != '') {
        $('#send_mail_result_page').removeClass('d-none');
      } else {
        $('#send_mail_result_page').addClass('d-none');
      }
    });
    this.submitResultForm();

    if ($('#friendship-compatibility-chart').length == 0) return;

    let friendshipCompatibility = $('.main-content').data('friend-compatibility');
    new Chart('friendship-compatibility-chart', {
      type: 'doughnut',
      plugins: [
        {
          afterDraw: (chart) => {
            let ctx = chart.ctx;
            ctx.save();
            let image = new Image();
            image.src = merlinWithOtter;
            let imageSize = 400;
            ctx.drawImage(
              image,
              chart.width / 2 - imageSize / 2,
              chart.height / 2 - imageSize / 2,
              imageSize,
              imageSize,
            );
            ctx.restore();
          },
        },
      ],
      data: {
        datasets: [
          {
            data: [friendshipCompatibility, 100 - friendshipCompatibility],
            backgroundColor: ['#0032a0', '#e6d39f'],
          },
        ],
      },
      options: {
        responsive: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        events: [],
        cutout: 170,
      },
    });

    copyExecutedButton('.btn-copy-excuted');
  }

  submitResultForm() {
    $('#send_mail_result_page').on('click', function (e) {
      let fc_name = $('#quiz_refree_fc_name').val();
      let fc_phone_number = $('#quiz_refree_fc_phone_number').val();
      $('#send_mail_result_page :button').attr('disabled', true);
      let referee_fc = {
        quiz: {
          referee_fc_attributes: {
            name: fc_name,
            phone_number: fc_phone_number,
          },
        },
      };
      let url = window.location.href;
      const quizID = url.substring(url.lastIndexOf('/') - 2).split('/')[0];
      const fcId = url.substring(url.lastIndexOf('/') - 2).split('fc=')[1];
      $.ajax({
        type: 'PATCH',
        url: url.replace('/result_page', ''),
        data: referee_fc,
        success: (data) => {},
      });
    });
  }

  setStep(nextStep) {
    this.currentStep = nextStep;
    this.navigateToNextStep();
    this.updateNavigationSectionDisplay();
  }

  updateNavigationSectionDisplay() {
    if (this.currentStep == 0 || this.currentStep > this.stepQueues.length - 1) {
      $(this.navigationSection).addClass('d-none');
    } else {
      $(this.navigationSection).removeClass('d-none');
      if (this.currentStep == this.stepQueues.length) {
        $(this.submitButton).removeClass('d-none');
      } else {
        $(this.submitButton).addClass('d-none');
      }
    }
  }

  navigateToNextStep() {
    this.stepQueues.forEach((step, index) => {
      const $indexStepElement = $(step);
      if (index == this.currentStep) {
        $indexStepElement.removeClass('d-none');
      } else {
        $indexStepElement.addClass('d-none');
      }
    });
  }

  handleChoseAnswerToNext() {
    this.stepQueues.forEach((step, index) => {
      $(step)
        .find('.collection_radio_buttons')
        .on('click', () => {
          setTimeout(() => {
            $(this.nextButton).trigger('click');
          }, 50);
        });
    });
  }

  handleGetStarted() {
    $(this.getStarted).on('click', () => {
      $('.disclaimer').addClass('d-none');
      this.nextStep();
    });
  }

  handleSubmited() {
    $(this.submitFormButton).on('click', (e) => {
      e.preventDefault();
      const currentStepValidationPassed = this.validateCurrentStep();
      const id = getUrlParamValueByName('fc');
      const username = location.pathname.split('/').at(-1);
      const fc = id || username;
      const email = $('#quiz_email');
      const emailVerificationField = {
        element: email,
        invalidSelector: '.email-invalid-feedback',
      };

      if (!currentStepValidationPassed) {
        const { element, invalidSelector } = emailVerificationField
        element.parent().parent().find(invalidSelector).hide();
        return;
      }

      $.get(
        Routes.verify_username_and_email_validations_path({
          fc,
          email: email.val()
        }),
      ).done((result) => {
        if (result.is_admin_fc_email) {
          const isInvalid = result.is_admin_fc_email;
          const { element, invalidSelector } = emailVerificationField
            if (isInvalid) {
              element.addClass('is-invalid');
              element.removeClass('is-valid');
              element.parent().parent().find(invalidSelector).show();
            } else {
              element.addClass('is-valid');
              element.removeClass('is-invalid');
              element.parent().parent().find(invalidSelector).hide();
            }
        } else {
          $(this.formSelector).submit();
        }
      });
    });
  }

  handleNextButton(type) {
    $(this.nextButton).on('click', () => {
      if (type == 'create') {
        this.nextStep();
      } else {
        this.nextStepForShow();
      }
    });
  }

  hanldeBackButton() {
    $(this.backButton).on('click', () => {
      if (this.currentStep === 1) {
        $('.disclaimer').removeClass('d-none');
      } else {
        $('.disclaimer').addClass('d-none');
      }
      this.backStep();
    });
  }

  nextStepForShow() {
    const MAX_STEP = this.stepQueues.length;

    $(this.stepQueues[this.currentStep]).addClass('d-none');
    this.currentStep += 1;
    $(this.navigationSection).removeClass('d-none');
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
    if (this.currentStep === MAX_STEP) {
      $(this.submitButton).removeClass('d-none');
    }
  }

  nextStep() {
    const MAX_STEP = this.stepQueues.length;
    let validCheckedInput = $(this.stepQueues[this.currentStep]).find('input:radio, input:checkbox').is(':checked');
    if (this.currentStep == 0) {
      validCheckedInput = true;
    }

    if (validCheckedInput) {
      $(this.stepQueues[this.currentStep]).addClass('d-none');
      this.setStep(this.currentStep + 1);
      $(this.navigationSection).removeClass('d-none');
      $(this.stepQueues[this.currentStep]).removeClass('d-none');
      if (this.currentStep === MAX_STEP) {
        $(this.submitButton).removeClass('d-none');
      }
    } else {
      alert('Please choose an answer!');
    }
  }

  validateCurrentStep() {
    let currentStepValidationPassed = true;
    let phoneNumberValidate = this.lastPageRequiredFields.find(
      (requiredField) => requiredField.selector === '#quiz_phone_number',
    );
    setupValidatorBaseOnCountryCode(phoneNumberValidate, '#quiz_country_code');

    this.lastPageRequiredFields.forEach((fieldInfor) => {
      const fieldInput = $(fieldInfor.selector);
      let isValidField = true;

      fieldInfor.validators.forEach((validatorMethod) => {
        isValidField = validatorMethod.bind(this)(fieldInput.val());
        if (!isValidField) {
          currentStepValidationPassed = false;
          fieldInput.addClass('is-invalid');
          fieldInput.removeClass('is-valid');
          fieldInput.parent().parent().find('.invalid-feedback').show();
        } else {
          fieldInput.addClass('is-valid');
          fieldInput.removeClass('is-invalid');
          fieldInput.parent().parent().find('.invalid-feedback').hide();
        }
      });
    });

    return currentStepValidationPassed;
  }

  backStep() {
    const MIN_STEP = 0;

    $(this.stepQueues[this.currentStep]).addClass('d-none');
    this.currentStep -= 1;
    if (this.currentStep === MIN_STEP) {
      $(this.navigationSection).addClass('d-none');
    }
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
    $(this.submitButton).addClass('d-none');
  }
}

Raicon.register('quizzes', QuizController);
