import 'select2';
import 'select2/dist/css/select2.css';

export const initSelect2 = (selector = '.select2', withTagsSelector = '.select2-with-tags') => {

  const select2Added = document.querySelectorAll(`${selector} + .select2`);
  
  if(select2Added.length) {
    for(let e = 0; e < select2Added.length; e++) {
      select2Added[e].remove();
    }
  }

  $.each($(selector), (index, elm) => {
    $(elm).select2({
      placeholder: 'Select a value',
      minimumResultsForSearch: 10,
      width: '100%',
      allowClear: $(elm).hasClass('clearable-select'),
      dropdownParent: $(elm).parent(),
    })
  });

  $(selector).on('select2:open', (e) => {
    const evt = "scroll.select2";
    $(e.target).parents().off(evt);
    $(window).off(evt);
    $('.select2-search--dropdown .select2-search__field').attr(
      'placeholder',
      'Search...',
    );
  });

  $(selector).on('select2:close', () => {
    $('.select2-search--dropdown .select2-search__field').attr(
      'placeholder',
      null,
    );
  });

  $(withTagsSelector).select2({
    tags: true,
    placeholder: 'Select a value',
    minimumResultsForSearch: 10,
    width: '100%',
    dropdownParent: $(withTagsSelector).parent(),
  });
};

document.addEventListener('turbolinks:load', () => {
  initSelect2();
});
