import { formatCurrencyToNumber, formatCurrency } from '@/layouts/application/utils/formats';
import { DEFAULT_CURRENCY } from '@/constants';

const calculateTimeDifference = (startDate, endDate) => {
  const months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
  const quarters = Math.ceil(months / 3);
  const halfYears = Math.ceil(months / 6);
  const years = Math.ceil(months / 12);
  return { months, quarters, halfYears, years };
};

const calculateTotal = (intervalType, amount, inceptionDate, paymentEndDate) => {
  let totalPaidAmount = 0;
  const now = new Date();
  const startDate = new Date(inceptionDate);
  const endDate = now > new Date(paymentEndDate) ? new Date(paymentEndDate) : now;

  if (startDate >= endDate) {
    return 0;
  }

  const { months, quarters, halfYears, years } = calculateTimeDifference(startDate, endDate);
  switch (intervalType) {
    case 'monthly':
      totalPaidAmount = months * amount;
      break;
    case 'yearly':
      totalPaidAmount = years * amount;
      break;
    case 'half_yearly':
      totalPaidAmount = halfYears * amount;
      break;
    case 'quarterly':
      totalPaidAmount = quarters * amount;
      break;
    case 'single':
      totalPaidAmount = amount;
      break;
  }
  return totalPaidAmount;
};

const calculateTotalDeposit = () => {
  let depositAmount = formatCurrencyToNumber($('#recurring-deposit-input').val());
  let inceptionDate = $($('.policy-inception-date')[2]).val();
  let intervalType = $('#recurring-interval-type').val();
  let paymentEndDate = $('.payment-end-date#payment-end-year').val() ||  $('#payment-end-year').val();

  if (intervalType == '' || inceptionDate == '' || depositAmount == '' || paymentEndDate == '') {
    return;
  }
  let totalDepositAmount = calculateTotal(intervalType, depositAmount, inceptionDate, paymentEndDate);
  let currency = $('#currency-select').val() || DEFAULT_CURRENCY;
  $('#total-deposit').text(formatCurrency(totalDepositAmount, currency));
};

const calculateTotalPaid = () => {
  let premiumAmount = formatCurrencyToNumber($('#premium-amount-input').val());
  let inceptionDate = $($('.policy-inception-date')[0]).val();
  let intervalType = $('#premium-interval-type').val();
  let paymentEndDate = $('.payment-end-date#payment-end-year').val() ||  $('#payment-end-year').val();

  if (intervalType == '' || inceptionDate == '' || premiumAmount == '' || paymentEndDate == '') {
    return;
  }
  let totalDepositAmount = calculateTotal(intervalType, premiumAmount, inceptionDate, paymentEndDate);

  $('#total-premium-paid').text(formatCurrency(totalDepositAmount).replace('$', ''));
};

const handleTotalDepositOnChange = () => {
  $('#recurring-deposit-input').on('input', () => {
    calculateTotalDeposit();
  });

  $($('.policy-inception-date')[2]).on('change', () => {
    calculateTotalDeposit();
  });

  $($('.payment-end-date')).on('change', () => {
    calculateTotalDeposit();
  });

  $('#recurring-interval-type').on('change', () => {
    calculateTotalDeposit();
  });

  calculateTotalDeposit();
};

const handleTotalPaidOnChange = () => {
  $('#premium-amount-input').on('input', () => {
    calculateTotalPaid();
  });

  $($('.policy-inception-date')[0]).on('change', () => {
    calculateTotalPaid();
  });

  $('#premium-interval-type, #payment-end-year').on('change', () => {
    calculateTotalPaid();
  });

  calculateTotalPaid();
};

export { handleTotalDepositOnChange, handleTotalPaidOnChange };
