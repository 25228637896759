import Raicon from 'raicon';
import OttermiseChart from './ottermiseChart';
import clearInputNumberOnFocus from '@/layouts/application/utils/clearInputNumber';

class ConsultantOttermiseCalculatorsController {
  constructor(){
    this.ottermiseChartContainer = '#ottermise-chart-container'
  }
  
  index(){
    clearInputNumberOnFocus();
    this.presentDataToChart();
    this.validateInterestRateInput();
  }

  presentDataToChart(){
    new OttermiseChart(this.ottermiseChartContainer).setup();
  }

  validateInterestRateInput(){
    $('.right-content').on('change', '.annual-rate', (e) => {
      const fieldInput = $(e.target)
      if(+fieldInput.val() < -99) {
        fieldInput.addClass('is-invalid');
        fieldInput.removeClass('is-valid');
        fieldInput.parent().parent().find('.invalid-feedback').show();
      } else {
        fieldInput.addClass('is-valid');
        fieldInput.removeClass('is-invalid');
        fieldInput.parent().parent().find('.invalid-feedback').hide();
      }
    })
  }
}

Raicon.register('consultant/ottermiseCalculators', ConsultantOttermiseCalculatorsController);
