import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

class AdminSprintGoalsController {

  index() {
    this.initDropZone();
  }

  initDropZone() {
    new DropZoneForm({
      formSelector: '#import-sprint-goals-form',
      submitBtnSelector: '#submit-import-sprint-goals',
      fileParamName: 'sprint_goal[import_sprint_goal_file]',
      importResultFormSelector: '#import-sprint-goals-result-form',
      importBtnSelector: [
        '#import-sprint-goals-btn',
        '#import-sprint-goals-form',
        '#import-sprint-goals-result-form'
      ]
    }).setup();
  }
}

Raicon.register('admin/sprintGoals', AdminSprintGoalsController);
