import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

class AdminIpreciateSubscribersController {
  index() {
    this.initDropZone();
  }

  initDropZone() {
    new DropZoneForm({
      isFailedRecordsLink: true,
      formSelector: '#import-blacklist-form',
      submitBtnSelector: '#submit-import-blacklist',
      fileParamName: 'ipreciate_subscriber[import_blacklist_file]',
      importBtnSelector: [
        '#import-blaclist-button',
        '#import-blaclist-form',
      ]
    }).setup();
  }
}
Raicon.register('admin/ipreciateSubscribers', AdminIpreciateSubscribersController);
