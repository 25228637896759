import Raicon from 'raicon';

class PublicDownloadsController {
  constructor() {
    this.downloadFhrButton = '#download-fhr-report-button';
    this.privacyCheckbox = '.check-privacy-box';
  }

  fhrReportWithPublicLink() {
    $('#main-modal').modal('show');
    this.handleToggleDownloadButton();
     $(this.privacyCheckbox).on('change', () => {
       this.handleToggleDownloadButton();
     })

    $(this.downloadFhrButton).on('click', () => {
      if($(this.downloadFhrButton).hasClass('disabled')) {
        return;
      }

      const inputedPassword = $('#download-fhr-password-input').val();

      $.ajax({
        url: window.location.href,
        type: 'POST',
        data: { password: inputedPassword },
        success: (data) => {
          if (data.success) {
            const a = document.createElement('a');
            a.href = data.path;
            a.download = "Financial Health Report.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)

            $('#main-modal').modal('hide');
          } else {
            $('#error-message').show();
          }
        }
      });
    })
  }
  handleToggleDownloadButton() {
    if($(this.privacyCheckbox).is(':checked')) {
      $(this.downloadFhrButton).removeClass('disabled');
      $(this.downloadFhrButton).prop('disabled', false);
    } else {
      $(this.downloadFhrButton).addClass('disabled');
      $(this.downloadFhrButton).prop('disabled', true);
    }
  }
}

Raicon.register('publicDownloads', PublicDownloadsController);
