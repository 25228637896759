import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

export default class ImportContactForm extends DropZoneForm {
  constructor() {
    super({
      isLoading: true,
      isFailedRecordsLink: true,
      overrideInput: '.override-duplication',
      formSelector: '#import-contacts-form',
      submitBtnSelector: '#submit-import-contacts',
      fileParamName: 'client[import_contact_file]',
      importResultFormSelector: '#import-contacts-result-form',
      gotItBtnSelector: '#got-it-button-import-people',
    })
  }

  setup = () => {
    $('body').on('modal:afterSetContent', '#import-contacts-from, #import-contacts-button', () => {
      this.setupDropZone();
      this.setupEventDropZone();
      this.redirectTo(this.gotItBtnSelector, Routes.consultant_clients_path());
    });
  }
}
