import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';
import {
  presentValidator
} from '@/layouts/application/utils/validators';
import Raicon from 'raicon';

class ConsultantTestimonialController {
  constructor() {
    this.showAmendButton = '#show-amend-section';
    this.hideAmendButton = '#hide-amend-section';
    this.submitAmendButton = '#submit-amend-btn';
    this.imageSelector = '.image-file-input';
    this.imagePreviewSelector = '.preview-input-file';
  }

  requiredFields = [
    {
      selector: '#testimonial_feedback',
      validators: [presentValidator]
    }
  ];

  setup() {
    this.setOpenPolicyModalEvents();
  }

  handleAmendTestimonial() {
    this.handleTermAndConditionsCheckbox();
    this.handleAmendSection();
    this.handleTermAndConditionsCheckbox();
    this.initDropzone();
  }

  handleAmendSection() {
    $('#show-amend-section').on('click', () => {
      $('.testimonial-field').removeClass('hidden');
      $('.amend-testimonial-modal-footer').removeClass('hidden');
      
      $('.testimonial-value').addClass('hidden');
      $('.show-testimonial-modal-footer').addClass('hidden');

      $('.testimonial-upload-image').removeClass('d-none');
    })

    $('#hide-amend-section').on('click', () => {
      $('.testimonial-field').addClass('hidden');
      $('.amend-testimonial-modal-footer').addClass('hidden');

      $('.testimonial-value').removeClass('hidden');
      $('.show-testimonial-modal-footer').removeClass('hidden');

      $('.testimonial-upload-image').addClass('d-none');
    })
  }

  setOpenPolicyModalEvents() {
    $('body').on(
      'modal:afterSetContent',
      '#review-testimonial-button',
      () => {
        this.handleAmendTestimonial();
        this.eventForUploadImage();
        this.handleImageChange();
      },
    );
  }

  handleTermAndConditionsCheckbox() {
    this.activeByCheckBox('#submit-amend-btn', '#checkbox-term');
  }

  handleImageChange() {
    $(this.imageSelector).on('change', ({ target }) => {
      const file = target.files[0];
      if (!file) return;

      $('#submit-testimonial-profile-image').trigger("click");
    });
  }

  eventForUploadImage() {
    $('.upload-file-btn').on('click', ({ target }) => {
      $(target).closest('.testimonial-upload-image').find('input[type="file"]').trigger('click');
    });
  }

  activeByCheckBox(btn, checkbox = '.check-privacy-box') {
    $(checkbox).on('click', () => {
      if ($(checkbox).is(':checked')) {
        if(this.validate()) {
          $(btn).prop('disabled', false);
        }
      } else {
        $(btn).prop('disabled', true);
      }
    })
  }

  index() {
    this.setup();
  }

  validate() {
    let currentStepValidationPassed = true;

    this.requiredFields.forEach((fieldInfor) => {
      const fieldInput = $(fieldInfor.selector);
      let isValidField = true;

      fieldInfor.validators.forEach((validatorMethod) => {
        isValidField = validatorMethod.bind(this)(fieldInput.val());

        if (!isValidField) {
          currentStepValidationPassed = false;
          fieldInput.addClass('is-invalid');
          fieldInput.removeClass('is-valid');
          fieldInput.parent().parent().find('.invalid-feedback').show();
        } else {
          fieldInput.addClass('is-valid');
          fieldInput.removeClass('is-invalid');
          fieldInput.parent().parent().find('.invalid-feedback').hide();
        }
      });
    });

    return currentStepValidationPassed;
  }

  initDropzone() {
    const dropZone = new DropZoneForm({
      uploadMultiple: false,
      isValidateUpload: false,
      formSelector: '#testimonial-form',
      submitBtnSelector: this.submitButton,
      fileParamName: 'testimonial[profile_image]',
      acceptedFiles: ['image/png', '.jpg', '.jpeg'],
      autoDiscover: false,
    });

    dropZone.setupDropZone();
    dropZone.setupEventDropZone();
  }
}

Raicon.register('consultant/testimonials', ConsultantTestimonialController);
