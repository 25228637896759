import Raicon from 'raicon';
import { togglePassword } from '../../clients/shared/togglePassword';
class SessionsController {
  constructor() {
    this.email = '#user_email';
    this.password = '#user_password';
  }

  new() {
    togglePassword();
    this.hideFooterOnClientPortal();
  }

  create() {
    this.hideFooterOnClientPortal();
  }

  hideFooterOnClientPortal() {
    if (navigator.userAgent.indexOf('gonative') > -1) {
      this.hideFooterEvent(this.email);
      this.hideFooterEvent(this.password);
    }
  }

  hideFooterEvent(element) {
    $(element).on('focus', () => {
      $('.footer').hide();
    });

    $(element).on('blur', () => {
      $('.footer').show();
    });
  }
}
Raicon.register('users/sessions', SessionsController);
