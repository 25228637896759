import { formatCurrency, formatMoneyToNumber } from '@/layouts/application/utils/formats';
import { autoFormatMoneyFields } from '@/layouts/application/utils/handleMoneyField';

$(document).on('turbolinks:load', function () {
  $('#gifting-calculator-modal').on('shown.bs.modal', () => {
    defaultGiftingValue();
    autoFormatMoneyField();
    $('#gifting_calculator_sidebar_placeholder_value, #gifting_calculator_sidebar_percent').on('input', () => {
      updateGiftingValueBaseOnInput(
        formatMoneyToNumber($('#gifting_calculator_sidebar_placeholder_value').val()),
        $('#gifting_calculator_sidebar_gifting_interval').val(),
        $('#gifting_calculator_sidebar_percent').val(),
      );
    });
    $('#gifting_calculator_sidebar_gifting_interval').on('change', () => {
      updateGiftingValueBaseOnInput(
        formatMoneyToNumber($('#gifting_calculator_sidebar_placeholder_value').val()),
        $('#gifting_calculator_sidebar_gifting_interval').val(),
        $('#gifting_calculator_sidebar_percent').val(),
      );
    });

    $('#gifting-dream-button, #gifting-expense-button').on('click', () => {
      $('#gifting-calculator-modal').modal('hide');
    });
  });
});

const defaultGiftingValue = () => {
  $('#gifting-value-side-bar').text(formatCurrency('0'));
};

const autoFormatMoneyField = () => {
  $('#gifting_calculator_sidebar_placeholder_value').on('input', (e) => {
    autoFormatMoneyFields(e.target);
  });
};

const updateGiftingValueBaseOnInput = (value, interval, percent) => {
  $.post(
    Routes.calculate_consultant_gifting_calculators_path({
      value: value,
      interval: interval,
      percent: percent,
    }),
  ).done((data) => {
    $('#gifting-value-side-bar').text(formatCurrency(data['data']));
  });
};
