export default class ImageCropper {
  constructor(imgInputSelector, imgPreviewInForm, signedIdField) {
    this.cropperModalSelector = '#image-cropper-modal';
    this.imgInputSelector = imgInputSelector;
    this.cropperCanvasSelector = '#cropper-canvas';
    this.openCropperSelector = '#open-cropper-modal';
    this.cropBtnSelector = '#cropBtnSelector';
    this.restoreBtnSelector = '#restoreBtnSelector';
    this.applyCropBtnSelector = '#submit-crop';
    this.imgPreviewSelector = '#image-preview';

    this.imgPreviewInFormSelector = imgPreviewInForm;
    this.signedIdFieldSelector = signedIdField;
  }

  setup() {
    const canvas  = $(this.cropperCanvasSelector);
    const context = canvas.get(0).getContext("2d");
  
    $(this.imgInputSelector).on( 'change', (e) => {
      $(this.openCropperSelector).trigger('click');
      const files = e.target.files;
  
      if (files && files[0]) {
          if ( files[0].type.match(/^image\//) ) {
            const reader = new FileReader();
            reader.onload = (evt) => {
              const img = new Image();
              img.onload = () => {
                context.canvas.height = img.height;
                context.canvas.width  = img.width;
                context.drawImage(img, 0, 0);
                let cropper = new Cropper($(this.cropperCanvasSelector)[0], {
                  aspectRatio: 1,
                  viewMode: 1,
                  minContainerHeight: 500,
                  minContainerWidth: 500
                });
                $(this.cropBtnSelector).on('click', () => {
                    const croppedImageDataURL = cropper.crop().getCroppedCanvas().toDataURL("image/png");
                    $(this.imgPreviewSelector).empty();
                    $(this.imgPreviewSelector).append( $('<img>').attr('src', croppedImageDataURL) );
                  });
                $(this.restoreBtnSelector).on('click',() => {
                  cropper.reset();
                  $(this.imgPreviewSelector).empty();
                });
  
                $(this.applyCropBtnSelector).on('click', () => {
                  const croppedImageDataURL = cropper.crop().getCroppedCanvas().toDataURL("image/png");
                  $.ajax({
                    url: Routes.create_from_base64_upload_files_path({}),
                    type: 'POST',
                    data: { data: croppedImageDataURL },
                  success: (response) => {
                    this.setCropperResultFileToForm(croppedImageDataURL, response.signed_id);
                    this.resetCropperObject(cropper);
                  }})
                })

                $(this.cropperModalSelector).on('hide.bs.modal', () => {
                  this.resetCropperObject(cropper);
                });
  
              }
              img.src = evt.target.result;
            };
            reader.readAsDataURL(files[0]);
          }
          else {
            alert("Invalid file type! Please select an image file.");
          }
      }
      else {
        alert('No file(s) selected.');
      }
    });
  }

  resetCropperObject(cropper) {
    $(this.cropBtnSelector).off('click');
    $(this.restoreBtnSelector).off('click');
    $(this.applyCropBtnSelector).off('click');
    $(this.cropperModalSelector).off('hide.bs.modal');
    $(this.imgPreviewSelector).empty();
    cropper.reset();
    cropper.destroy();
  }

  setCropperResultFileToForm(base64Img, signedId) {
    $('#profile-pic-preview').attr('src', base64Img).removeClass('d-none');
    $('#profile-pic-signed-blob').val(signedId);
  }
}  