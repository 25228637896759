import Raicon from 'raicon';
import { copyExecuted } from '@/layouts/application/utils/copyExecuted';
import { fillDataForAddContact } from '../shared/fillDataForAddContact';
import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
class ConsultantIpreciatesController {
  index() {
    copyExecuted('#copy-pre-find-fact-link', '#pre-fact-find-link');
    copyExecuted('#copy-post-fna-link', '#post-fna-link');
    copyExecuted('#copy-post-quiz-link', '#post-quiz-link');
    copyExecuted('#copy-post-quiz-career-link', '#post-quiz-career-link');
    copyExecuted('#copy-post-quiz-friendship-compatibility-link', '#post-quiz-friendship-compatibility-link');
    copyExecuted('#copy-pay-forward-link', '#pay-forward-link');
    copyExecuted('#copy-ipreciate-link', '#ipreciate-link');
    copyExecuted('#copy-finding-merlin-link', '#finding-merlin-link');
    copyExecuted('#copy-client-link', '#client-portal-link');
    fillDataForAddContact();
    this.autoCalculateAgeByInput();
  }

  autoCalculateAgeByInput() {
    $('body').on('modal:afterSetContent', '#new-ipreciate-to-contact-button', () => {
      calculateAgeByInput('#dob', '.input-age');
    });
  }
}
Raicon.register('consultant/ipreciates', ConsultantIpreciatesController);
