import Raicon from 'raicon';
import FamilyMemberForm from './familyMemberForm';
import { add10Referees } from '../add10Referees';
import GiftingCalculatorClientForm from '../clients/giftingCalculatorForm';
import { bulkDeleteItem } from '../shared/bulkDeleteItem';
import { changePrWhenNationalityIsSingapore } from '../shared/handleNationalitySelectChange';
import OverlayCanvas from '../../shared/overlayCanvas';

class ConsultantFamilyController {
  index() {
    this.bioSetup();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    this.setupGiftingCalculatorForm();
    this.setupOverlayCanvas();
    bulkDeleteItem('#bulk-delete-family-member', '.family-member-checkbox');

    $('body').on('modal:afterSetContent', '#family-details-form', () => {
      this.toggleCorrespondencePrBaseOnNationality();
    })

    this.toggleCorrespondencePrBaseOnNationality();
    $(document).on('change', '#family_member_nationality', () => {
      this.toggleCorrespondencePrBaseOnNationality();
    });
    $('body').on('click', '#add-new-contact', () => {
      changePrWhenNationalityIsSingapore();
    });
  }

  setupGiftingCalculatorForm() {
    new GiftingCalculatorClientForm().setup();
  }

  setupOverlayCanvas() {
    new OverlayCanvas().setup();
  }

  bioSetup() {
    new FamilyMemberForm().setup();
  }

  toggleCorrespondencePrBaseOnNationality() {
    const value = $('#family_member_nationality :selected').val();

    if (value == 'Singapore Citizen') {
      $('.family_member_singapore_pr').addClass('d-none');
    } else {
      $('.family_member_singapore_pr').removeClass('d-none');
    }
  }
}

Raicon.register('consultant/family', ConsultantFamilyController);
