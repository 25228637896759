import Raicon from 'raicon';

const images = require.context('@/images', true);
const imagePath = (name) => images(name, true);

class AdminTestimonialsController {
  constructor() {
    this.imageSelector = '.image-file-input';
    this.reAssignConsultantSelector = '.reassign-consultant-input';
    this.imagePreviewSelector = '.preview-input-file';
    this.saveButtonSelector = '#testimonial-save-btn';
    this.removeAttachmentSelector = '.remove-attachment';
    this.defaultImagePath = imagePath('./default-testimonial-profile.png');
  }

  show() {
    this.eventForUploadFileTestimonial();
    this.handleImageChange();
    this.handleRemoveAttachment();
    this.toggleSaveButton();
    this.toggleRemoveButton();
    this.handleReAssignConsultantChange();
  }

  eventForUploadFileTestimonial() {
    $('.upload-file-btn').on('click', ({ target }) => {
      $(target).closest('.testimonial-upload-image').find('input[type="file"]').trigger('click');
    });
  }

  handleImageChange() {
    $(this.imageSelector).on('change', ({ target }) => {
      const file = target.files[0];
      if (!file) return;

      $(target).closest('.file-input-wrapper').find(this.imagePreviewSelector).removeClass('d-none');
      $(target).closest('.file-input-wrapper').find(this.imagePreviewSelector).attr('src', URL.createObjectURL(file));
      this.toggleSaveButton();
      this.toggleRemoveButton(true);
    });
  }

  handleReAssignConsultantChange() {
    $(this.reAssignConsultantSelector).on('change', ({ target }) => {
      $(this.saveButtonSelector).removeClass('d-none');
    });
  }

  handleRemoveAttachment() {
    $(this.removeAttachmentSelector).on('click', ({ target }) => {
      const targetSelector = $(target);

      if (confirm("Are you sure delete?")) {
        if (typeof targetSelector.data('attachment-id') == 'undefined') {
          const fileInputWrapper = targetSelector.closest('.file-input-wrapper');
          fileInputWrapper.find(this.imagePreviewSelector).attr('src', this.defaultImagePath);
          fileInputWrapper.find(this.imageSelector).val('');
          this.toggleSaveButton();
          this.toggleRemoveButton(false);
          return;
        }

        const blobId = targetSelector.data('attachment-id');
        $.ajax({
          url: Routes.destroy_signed_admin_upload_file_path(blobId),
          type: 'DELETE',
          dataType: 'json',
          success: function () {
            window.location.reload();
          },
        });
      }
    });
  }

  toggleSaveButton() {
    const hasFile = $(this.imageSelector).val() !== '';
    if (hasFile) {
      $(this.saveButtonSelector).removeClass('d-none');
    } else {
      $(this.saveButtonSelector).addClass('d-none');
    }
  }

  toggleRemoveButton(show) {
    if (show === undefined) {
      const currentImageSrc = $(this.imagePreviewSelector).attr('src');
      show = currentImageSrc !== this.defaultImagePath;
    }
    if (show) {
      $(this.removeAttachmentSelector).removeClass('d-none');
    } else {
      $(this.removeAttachmentSelector).addClass('d-none');
    }
  }
}

Raicon.register('admin/testimonials', AdminTestimonialsController);
