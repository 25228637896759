export default class ShareButton {
	constructor() {
		this.shareBtn = '.btn-share-now'
	}

	setup() {
		this.triggerShareToOther()
	}

	triggerShareToOther() {
		if (navigator.userAgent.indexOf('gonative') > -1) {
			$(this.shareBtn).on('click', () => {
				const clientCode = $(this.shareBtn).data('client-code');
				const clientName = $(this.shareBtn).data('client-name');
				this.gonativeShareToApp({
					url: `https://staging.iammerlin.co/code/${clientCode}`,
					text: `${clientName} has enjoyed using Merlin and would like for you to join! Simply click on the link below to find out more!`,
				});
			});
		}
	}

	gonativeShareToApp(data) {
		gonative.share.sharePage(data);
	}
}
