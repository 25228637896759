import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
import { changePrWhenNationalityIsSingapore } from '../shared/handleNationalitySelectChange';

export default class FamilyMemberForm {
  constructor() {
    this.openEditMemberSelector = '#open-update-member';
    this.openNewMemberSelector = '#open-new-member';
    this.updateMemberFormSelector = '#family-details-form';
    this.newMemberFormSelector = '#new-mem-form';
    this.dobInputSelector = '#dob';
    this.ageShowSelector = '.input-age';
    this.familyMemberTypeSelector = '#family-member-type';
    this.standaloneInfoSelector = '.standalone';
    this.existingInfoSelector = '.linked';
    this.newContactFamilyMemberSelector = '#add-new-contact';
    this.newContactFormSelector = '#new-contact-form';
    this.toggleEmergencyNetworkSelector = '#toggle-emergency-network';
    this.emergencyNetwork = '#emergency-network';
  }

  setup() {
    this.addEditButtonTriggerOpenModalEvents();
    this.handleOnFamilyMember();
    this.handleSubmitForm();
  }

  handleSubmitForm() {
    $('body').on('modal:afterSetContent', `${this.openNewMemberSelector}, ${this.newMemberFormSelector}`, () => {
      let backUrl = $('.reinit-container a.selection-item:first-child').attr("href");
      $('input[type="submit"]').attr('disabled', true);

      $('#new-mem-form').on('click', () => {
        $('input[type="submit"]').removeAttr('disabled');
      });

      $('#new-mem-form').on('submit', (event) => {
        setTimeout(() => {
          let errors = false;
          $('#new-mem-form').find('.invalid-feedback').each(function () {
            if ($(this).val().length < 1) {
              $(this).addClass('error');
              errors = true;
            }
          });

          if ($('.reinit-container a.selection-item:first-child').length < 1) { // lenght < 1 ==> without element back-link. So, how to add it as href here?
            $('.family-member-modal .reinit-container').prepend(`<a data-type="html" data-modal="true" class="selection-item" id="back-link" data-remote="true" href="${backUrl}"><div class="icon icon-sm mt-2 ml-2"><img alt="btn-icon" src="/packs/media/images/icons/arrow-left-f6a4eced0286db203356921385d11c72.svg"></div></a>`)
          }

          if (errors == false && (backUrl.includes('insurance_policies') || backUrl.includes('assets') || backUrl.includes('emergency_networks/new') || backUrl.includes('dreams'))) {
            $('.reinit-container a.selection-item:first-child')[0].click();
          }
        }, 1000);
      });
    });
  }

  addEditButtonTriggerOpenModalEvents() {
    $('body').on('modal:afterSetContent', `${this.openEditMemberSelector}, ${this.updateMemberFormSelector}`, () => {
      calculateAgeByInput(this.dobInputSelector, this.ageShowSelector);
    })
    $('body').on('modal:afterSetContent', `${this.openNewMemberSelector}, ${this.newMemberFormSelector}`, () => {
      calculateAgeByInput(this.dobInputSelector, this.ageShowSelector);
      this.toggleInputForm();
      this.toggleCorrespondencePrBaseOnNationality();
      $('#family_member_nationality').on('change', () => {
        this.toggleCorrespondencePrBaseOnNationality();
      });
    })
  }

  toggleInputForm() {
    this.checkToggle();

    $(this.familyMemberTypeSelector).on('change', () => {
      this.checkToggle();
    })
  }

  checkToggle() {
    if($(this.familyMemberTypeSelector).is(':checked')) {
      $(this.standaloneInfoSelector).hide();
      $(this.existingInfoSelector).show();
    }
    else {
      $(this.standaloneInfoSelector).show();
      $(this.existingInfoSelector).hide();
    }
  }

  toggleEmergencyNetwork() {
    if($(this.toggleEmergencyNetworkSelector).is(':checked')) {
      $(this.emergencyNetwork).show();
    }
    else {
      $(this.emergencyNetwork).hide();
    }
    $(this.toggleEmergencyNetworkSelector).on('change', () =>{
      if($(this.toggleEmergencyNetworkSelector).is(':checked')) {
        $(this.emergencyNetwork).show();
      }
      else {
        $(this.emergencyNetwork).hide();
      }
    });
  }

  handleOnFamilyMember() {
    $('body').on('modal:afterSetContent', `${this.newContactFamilyMemberSelector}, ${this.newContactFormSelector}`, () => {
      calculateAgeByInput(this.dobInputSelector, this.ageShowSelector);
      changePrWhenNationalityIsSingapore();
    })

    $('body').on('modal:afterSetContent', `${this.openEditMemberSelector}, ${this.updateMemberFormSelector}, ${this.newMemberFormSelector}, ${this.openNewMemberSelector}`, () => {
      this.toggleEmergencyNetwork();
    });
  }

  toggleCorrespondencePrBaseOnNationality() {
    const value = $('#family_member_nationality :selected').val();

    if (value == 'Singapore Citizen') {
      $('.family_member_singapore_pr').addClass('d-none');
    } else {
      $('.family_member_singapore_pr').removeClass('d-none');
    }
  }
}
