import TaskForm from '@/controllers/consultant/tasks/formTask';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';
import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';
import { copyExecuted } from '@/layouts/application/utils/copyExecuted';
import { checkboxSelected, toggleTdTable } from '@/layouts/application/utils/multipleSelectTable';
import { securityText } from '@/layouts/application/utils/securityText';
import Raicon from 'raicon';
import ProductForm from '../cases/productForm';
import { handleOverlayGuides } from '../overlayGuides';
import AppointmentForm from '../planners/appointmentForm';
import { fillDataForDataContactWithSecurity } from '../shared/fillDataForAddContact';
import { handleTrackClickBanner } from '../shared/handleTrackClickBanner';
import { changePrWhenNationalityIsSingapore } from '../shared/handleNationalitySelectChange';

class ConsultantRefereesController {
  index() {
    checkboxSelected();
    toggleTdTable();

    this.initSecurity();
    this.handleTaskForm();
    this.handleAppointmentForm();
    fillDataForDataContactWithSecurity();
    this.autoCalculateAgeByInput();
    this.allowEmptySelect2();
    this.initDropzone();
    this.eventForToggleSecurityTable();
    handleOverlayGuides();
    this.setupFilterAction();
    copyExecuted('#copy-pre-find-fact-link', '#pre-fact-find-link');
    copyExecuted('#copy-post-fna-link', '#post-fna-link');
    copyExecuted('#copy-post-quiz-link', '#post-quiz-link');
    copyExecuted('#copy-post-quiz-career-link', '#post-quiz-career-link');
    copyExecuted('#copy-post-quiz-friendship-compatibility-link', '#post-quiz-friendship-compatibility-link');
    copyExecuted('#copy-pay-forward-link', '#pay-forward-link');
    copyExecuted('#copy-ipreciate-link', '#ipreciate-link');
    copyExecuted('#copy-finding-merlin-link', '#finding-merlin-link');
    copyExecuted('#copy-client-link', '#client-portal-link');
    changeParamenterByTab('people', 'people_tab', true);
    this.triggerOpenModalEvents();
    handleTrackClickBanner();
  }

  initSecurity() {
    if ($('.referee-index-page').data('is-security') === 'true' || $('.referee-index-page').data('is-security')) {
      securityText('.country-code');
      securityText('.email-field', 'email');
      securityText('.mobile-field');
    }
  }

  eventForToggleSecurityTable() {
    $(document).on('click', '#hide-security-table-button, #show-security-table-button', function() {
      const securityButton = $(this);
      securityButton.prop('disabled', true);

      $.post(Routes.toggle_security_table_consultant_clients_path({ type: 'leads' }), function() {
        window.location.reload();
      }).fail(function() {
        securityButton.prop('disabled', false);
      });
    });
  }

  triggerOpenModalEvents() {
    $('body').on('click', '#bulk-update-lead', () => {
      this.addIdsToAddLabelsAndUpdateContentType();
    });
  }

  handleTaskForm() {
    $('body').on(
      'modal:afterSetContent',
      '#open-new-tasks, #new_task, #open-edit-task, #edit-task-form, #save-task, #how-is-your-call-form',
      () => {
        new TaskForm().setup();

        $('#open-new-case').on('click', () => {
          $('#what-next-field').on('cocoon:after-insert', () => {
            new ProductForm();
          });
        });

        if ($('.case-fields').length > 0) {
          new ProductForm();
        }
      },
    );
  }

  handleAppointmentForm() {
    $('body').on('modal:afterSetContent', '#new-appointment-button, #appointment-form', () => {
      new AppointmentForm().setup();
    });
  }

  autoCalculateAgeByInput() {
    $('body').on('modal:afterSetContent', '#new-referee-to-contact-button, #new-contact-form, #open-new-referee, #edit-referee-form', () => {
      calculateAgeByInput('#dob', '.input-age');
      changePrWhenNationalityIsSingapore();
    });
  }

  allowEmptySelect2() {
    $('body').on('modal:afterSetContent', '#open-new-referee, #edit-referee-form, #referee-form', () => {
      $('#referrer-selector').select2({
        allowClear: true,
        placeholder: 'Choose a referrer',
        dropdownParent: $('#referrer-selector').parent(),
      });
    });
  }

  initDropzone = () => {
    new DropZoneForm({
      isFailedRecordsLink: true,
      formSelector: '#import-referees-form',
      submitBtnSelector: '#submit-import-referee',
      fileParamName: 'referee[import_referees_file]',
      importResultFormSelector: '#import-referees-result-form',
      importBtnSelector: [
        '#import-referees-button',
        '#import-referees-form'
      ]
    }).setup();
  }

  setupFilterAction() {
    $('body').on('change', '#favorable-recommendations-filter', ({ target }) => {
      const filterValue = target.value;
      Turbolinks.visit(Routes.consultant_referees_path({ filter: filterValue }));
    });
  }

  addIdsToAddLabelsAndUpdateContentType() {
    const selectBox = $('.select-box:checked');
    const labelInputIds = $('#bulk-update-lead-client-ids');
    let ids = [];
    selectBox.each(function () {
      const value = $(this).data('value');
      ids.push(value);
    });

    labelInputIds.val(ids);
  }
}

Raicon.register('consultant/referees', ConsultantRefereesController);
