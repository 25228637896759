// ======= Show errors if  password is not correct
// ======= open PDF new window
import { initFlatPickr } from '@/layouts/application/packages/flatpickr';

$(document).on('turbolinks:load', function () {
  setupFinancialReport();
})

const setupFinancialReport = () => {
  $('body').on('modal:afterSetContent', '#open-list-report, #open-new-report', () => {
    openNewWindowReportDownload();
    addSortedTableToViewFile();
  });

  $('body').on('click', '.copy-public-link-icon', ({ target }) => {
    const $tempInput = $("<textarea>");
    $("body").append($tempInput);
    $tempInput.val($(target).siblings('input.public-link-input').val()).trigger('select');
    document.execCommand("copy");
    $tempInput.remove();
    alert('Copied to clipboard!');
  })

  $('body').on('modal:afterSetContent', '#open-new-report, #create-financial-report', () => {
    openNewWindowReportNew();
  });
}

const openNewWindowReportDownload = () => {
  $("body").on('ajax:complete', '#new_financial_report', (event) => {
    const [xhr, status] = event.detail;
    if (status === "OK") {
      window.open(JSON.parse(xhr.response).url)
    } else {
      $("#errorsReport").text(JSON.parse(xhr.response).message)
    }
  })
}

const openNewWindowReportNew = () => {
  createReportRequestHandler();
  addEventForToggleCheckboxes();
}

const addSortedTableToViewFile = () => {
  const inputSortedTable = $('#sorted-table-input');
  let sortedTable = {}
  let search = '_table';
  Object.keys(localStorage)
                      .filter((key) => key.endsWith(search))
                      .map((key) => {
                        return sortedTable[key] = localStorage.getItem(key);
                      });

  inputSortedTable.val(JSON.stringify(sortedTable))
}

const createReportRequestHandler = () => {
  $("body").on('ajax:complete', '#create-financial-report', (event) => {
    const [xhr] = event.detail;
    if (xhr.status != 200) {
      $('#modal-custom-financial-report').html(xhr.responseText);
      initFlatPickr('.datepicker');
      addEventForToggleCheckboxes();
    }
  });
}

const addEventForToggleCheckboxes = () => {
  const appointmentTypeCheckbox = '#appointment-type-checkbox';
  const appointmentTypeInputSelector = '#appointment-type-input';
  const focusTypeCheckboxSelector = '#focus-type-checkbox';
  const focusTypeInputSelector = '#areas-focus-input';
  const recommendationTypeCheckboxSelector = '#recommendation-type-checkbox';
  const recommendationTypeInputSelector = '#recommendation-input';
  const passwordTypeCheckboxSelector = '#password-type-checkbox';
  const passwordTypeInputSelector = '#password-input';

  handleToggleEvent(appointmentTypeCheckbox, appointmentTypeInputSelector);
  handleToggleEvent(focusTypeCheckboxSelector, focusTypeInputSelector);
  handleToggleEvent(recommendationTypeCheckboxSelector, recommendationTypeInputSelector);
  handleToggleEvent(passwordTypeCheckboxSelector, passwordTypeInputSelector);
}

const handleToggleEvent = (checkbox, inputSection) => {
  const $checkbox = $(checkbox);
  const $inputSection = $(inputSection);

  if ($checkbox.is(':checked')) {
    $inputSection.show();
  } else {
    $inputSection.hide();
  }

  $checkbox.on('change', ({ target }) => {
    if (target.checked) {
      $inputSection.show();
    } else {
      $inputSection.hide();
    }
  })
}
