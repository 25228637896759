import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

class AdminProductionTrendsController {
  index() {
    this.initDropZone();
  }

  initDropZone() {
    new DropZoneForm({
      formSelector: '#import-production-trend-form',
      submitBtnSelector: '#submit-import-production-trend',
      fileParamName: 'production_trend[import_file]',
      importBtnSelector: [
        '#import-production-trend-button',
        '#update-production-trend-button'
      ],
    }).setup();
  }
}

Raicon.register('admin/productionTrends', AdminProductionTrendsController);
