import initMultipleLinesChart from '@/layouts/application/utils/charts/initMultipleLinesChart';
import DreamChart from '../dreams/dreamCharts/dreamChart';
import Chart from 'chart.js/auto';
import { formatCurrency } from '@/layouts/application/utils/formats';
import externalTooltipHandler from '@/layouts/application/utils/charts/modifyTooltipNetwealthChart';
import { convertYearByAge } from '@/layouts/application/utils/calculateAgeByInput';
import Tooltip from '@/layouts/application/utils/charts/tooltipPositionCustom';
import { DEFAULT_CURRENCY } from '@/constants';

import { SHARED_DREAM_AMOUNT_SAVED_LINE, INCOME } from '@/layouts/application/utils/charts/chartColors';

export default class NetwealthChart extends DreamChart {
  constructor(...args) {
    super(...args);
    this.rangeBackYearSelector = '.netwealth-year-range .back-year-icon';
    this.rangeForwardYearSelector = '.netwealth-year-range .forward-year-icon';
    this.rangeStartYearSelector = '.netwealth-year-range .range-start-year';
    this.rangeEndYearSelector = '.netwealth-year-range .range-end-year';
    this.clientBaseCurrency = $('body').data('client-base-currency') || DEFAULT_CURRENCY;
  }

  chart = undefined;
  isLifeTimeView = false;

  setup() {
    this.buildXAxisData();
    this.setupChartRangeYear();
    this.setOnChangeViewType();
    this.setupChartValue();
  }

  setupChartValue() {
    const chartInitialData = this.chartElementContainer.data('chart-data');
    this.chartRetirementYear = this.chartElementContainer.data('label');

    this.liabilities = chartInitialData.liabilities;

    this.buildXAxisData();
    this.presentDataToChart([]);
  }

  presentDataToChart() {
    this.showLoadingDreamChart();
    const data = this.buildDataForChartRequest();
    $.post(
      Routes.present_data_chart_consultant_client_netwealths_path({ client_id: this.clientId }),
      { data: data },
      (data) => {
        this.chartLinesData = data.chart_data;
        this.dataForLabels = data.chart_data.labels;
        this.drawCashflowChart();
      },
    );
  }

  calculateRangeAge() {
    let rangeAge = 10;

    if (this.isLifeTimeView) {
      const clientBirthdayYear = this.clientDobYear();
      rangeAge = Number(clientBirthdayYear) + this.chartRetirementYear - Number(this.currentYear) + 1;
    }

    return rangeAge;
  }

  drawCashflowChart() {
    const datasets = this.buildChartDatasets();

    const options = {
      scales: {
        y: {
          grid: {
            color: function (context) {
              if (context.tick.value == 0) {
                return '#000000';
              } else {
                return Chart.defaults.borderColor;
              }
            },
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          borderRadius: 25,
          labels: {
            generateLabels: (chart) => {
              const items = Chart.defaults.plugins.legend.labels.generateLabels(chart);

              for (const item of items) {
                if (item.text == 'Net Worth') {
                  item.text = '';
                  continue;
                }
                let color;

                if (item.text == 'Total Liabilities') {
                  color = '#ffd966';
                } else if (item.text == 'Total Assets') {
                  color = '#1156cc';
                }

                item.fillStyle = color;
                item.lineWidth = 0;
                item.borderRadius = 5;
              }

              return items;
            },
          },
        },
        tooltip: {
          mode: 'index',
          position: 'tooltipPositionCustom',
          backgroundColor: '#EEEEEE',
          padding: 15,
          titleColor: '#303030',
          titleFont: { weight: 'bold', size: 14 },
          bodyColor: '#303030',
          cornerRadius: 10,
          usePointStyle: true,
          enabled: false,
          external: externalTooltipHandler,
          callbacks: {
            title: (context) => {
              if (this.isLifeTimeView) {
                const currentYear = this.currentRangeStartYear;
                const year = currentYear + context[0].dataIndex;
                const age = context[0].label;
                return `${year} (Age: ${age})`;
              } else {
                let currentYear =
                  parseInt(convertYearByAge(this.clientDob, false)) + parseInt($('span.range-start-year').text());
                if (parseInt(convertYearByAge(this.clientDob, false)) + this.clientAge != new Date().getFullYear()) {
                  currentYear =
                    parseInt(convertYearByAge(this.clientDob, false)) + 1 + parseInt($('span.range-start-year').text());
                }
                const year = currentYear + context[0].dataIndex;
                const age = context[0].label;
                return `${year} (Age: ${age})`;
              }
            },
            label: (tooltipItems) => {
              const label = tooltipItems.dataset.label;
              return ` ${label}: ${formatCurrency(tooltipItems.raw, this.clientBaseCurrency)}`;
            },
            labelPointStyle: function (context) {
              return {
                pointStyle: 'circle',
                borderWidth: 10,
              };
            },
            labelColor: function (context) {
              const color = context.dataset.pointBackgroundColor;

              return {
                borderColor: color,
                backgroundColor: color,
                borderWidth: 10,
                borderDash: [2, 2],
                borderRadius: 2,
              };
            },
          },
        },
      },
    };
    const plugins = [
      {
        afterUpdate: () => this.hideLoadingDreamChart(),
      },
    ];
    if (this.chart) {
      this.chart.data.datasets = datasets;
      this.chart.data.labels = this.dataForLabels;

      this.chart.update();
    } else {
      this.chart = initMultipleLinesChart(this.chartElementContainer, datasets, this.dataForLabels, options, plugins);
    }
  }

  buildChartDatasets() {
    let datasets = [];
    let { liabilities: liabilities, netwealths: netwealths } = this.chartLinesData;
    const pointStyle = this.isLifeTimeView ? null : this.pointImageSource();

    if (netwealths.length == 0) {
      netwealths = new Array(liabilities.length).fill(0);
    }

    datasets.push({
      label: 'Total Liabilities',
      data: liabilities.map((value) => parseFloat(value)),
      pointStyle: pointStyle,
      borderColor: SHARED_DREAM_AMOUNT_SAVED_LINE,
      backgroundColor: SHARED_DREAM_AMOUNT_SAVED_LINE,
      tension: 0.1,
      fill: false,
      pointBackgroundColor: '#E8B012',
      pointBorderColor: '#E8B012',
      pointBorderWidth: 4,
      pointRadius: 2,
      cubicInterpolationMode: 'monotone',
      responsive: true,
    });

    datasets.push({
      label: 'Total Assets',
      data: netwealths.map((value) => parseFloat(value)),
      pointStyle: pointStyle,
      borderColor: INCOME,
      backgroundColor: SHARED_DREAM_AMOUNT_SAVED_LINE,
      tension: 0.1,
      fill: false,
      pointBackgroundColor: '#E8B012',
      pointBorderColor: '#E8B012',
      pointBorderWidth: 4,
      pointRadius: 2,
      cubicInterpolationMode: 'monotone',
      responsive: true,
    });

    datasets.push({
      label: 'Net Worth',
      data: netwealths.map((value, index) => parseFloat(value - liabilities[index])),
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    });

    return datasets;
  }

  buildDataForChartRequest() {
    return {
      range_start_age: this.currentRangeStartAge,
      is_lifetime_view: this.isLifeTimeView,
    };
  }
}
