import Raicon from 'raicon';
import { togglePassword } from '../../clients/shared/togglePassword';
import validatePasswordCharacter from '../../consultant/shared/validatePasswordCharacter';

class RegistrationsController {
  setup(){
    togglePassword();
    validatePasswordCharacter();
  }

  new(){
    this.setup();
  }

  create(){
    this.setup();
  }
}

Raicon.register('users/registrations', RegistrationsController);
