import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';

class CashflowsPasswordsController {
  index() {
    handleSideMenu();
    handleUploadAvatar();
  }

  loanRepaymentExpenses() {
    handleSideMenu();
    handleUploadAvatar();
  }

  savingInvestmentsExpenses() {
    handleSideMenu();
    handleUploadAvatar();
  }

  totalInsuranceExpenses() {
    handleSideMenu();
    handleUploadAvatar();
  }
}

Raicon.register('clients/cashflows', CashflowsPasswordsController);
