import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

export default class ImportFCBrandForm extends DropZoneForm {
  constructor() {
    super({
      isLoading: true,
      isFailedRecordsLink: true,
      overrideInput: '.override-duplication',
      formSelector: '#import-brands-form',
      submitBtnSelector: '#submit-import-brands',
      fileParamName: 'fc_brand[fc_brands_file]',
      importResultFormSelector: '#import-brands-result-form'
    })
  }

  setup = () => {
    $('body').on('modal:afterSetContent', '#import-brands-form, #import-brands-button', () => {
      this.setupDropZone();
      this.setupEventDropZone();
    });
  }
}
