import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar  from '../shared/handleUploadAvatar';

class NetwealthsPasswordsController {
  index() {
    handleSideMenu();
    handleUploadAvatar();
  }
}

Raicon.register('clients/netwealths', NetwealthsPasswordsController);
