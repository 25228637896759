import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

export default class ImportPeopleForm extends DropZoneForm {
  constructor() {
    super({
      isLoading: true,
      isFailedRecordsLink: true,
      overrideInput: '.override-duplication',
      formSelector: '#import-people-form',
      submitBtnSelector: '#submit-import-people',
      fileParamName: 'client[import_people_file]',
      importResultFormSelector: '#import-people-result-form',
      gotItBtnSelector: '#got-it-button-import-people',
    })
  }

  setup = () => {
    $('body').on('modal:afterSetContent', '#import-people-from, #import-people-button', () => {
      this.setupDropZone();
      this.setupEventDropZone();
      this.redirectTo(this.gotItBtnSelector, Routes.consultant_all_people_path());
    });
  }
}
