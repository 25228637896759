import Chart from 'chart.js/auto';
import { formatMoneyToNumber, formatCurrencyWholeNumber } from '@/layouts/application/utils/formats';
import { autoFormatMoneyFields } from '@/layouts/application/utils/handleMoneyField';
import Tooltip from '@/layouts/application/utils/charts/tooltipPositionCustom';
import calculate from '../../../layouts/application/utils/publicInvestmentIllustrator';

export default class InvestmentIllustratorPublicChart {
  constructor($chartElementContainer) {
    this.chartElementContainer = $chartElementContainer;
    this.ageFrom = '#public_investment_illustrator_age_from';
    this.ageTo = '#public_investment_illustrator_age_to';
    this.rateValue = '#public_investment_illustrator_rate_value';
    this.amountAnnualy = '#public_investment_illustrator_amount_annualy';
    this.retireYear = '#public_investment_illustrator_retire_year';
    this.retireAge = '#public_investment_illustrator_investment_retire_age';
    this.retireAmount = '#public_investment_illustrator_investment_retire_amount';
    this.data_chart = {};
    this.investmentInfomationForm = {};
  }

  setup() {
    this.handleAddNewLumpSumTopup();
    this.handleAddNewLumpSumWithdrawal();
    this.triggerRemoveLumpSum();
    this.showBreakdownTable();
    this.triggerUpdateDataCalculate();
    this.triggerHideLumpSum();
    this.handleSpanToInput();
    this.updateChartWhenBlurInputAge();

    setTimeout(() => {
      this.updateChartData();
    }, 2000);
  }

  getInvestmentIllustratorObjectForm() {
    let startAge = $(this.ageFrom).text() || $(this.ageFrom).val();
    let endAge = $(this.ageTo).text() || $(this.ageTo).val();
    let rate = $(this.rateValue).text() || $(this.rateValue).val();
    let amountAnnually = $(this.amountAnnualy).text() || $(this.amountAnnualy).val();
    let yearsToInvest = $(this.retireYear).text() || $(this.retireYear).val();
    let retireAge = $(this.retireAge).text() || $(this.retireAge).val();
    let retirementAmount = $(this.retireAmount).text() || $(this.retireAmount).val();

    this.investmentInfomationForm = {
      'startAge': startAge,
      'endAge': endAge,
      'rate': rate,
      'amountAnnually': amountAnnually,
      'yearsToInvest': yearsToInvest,
      'retireAge': retireAge,
      'retirementAmount': retirementAmount
    };

    this.investmentInfomationForm['topUp'] = [];
    this.investmentInfomationForm['withdrawal'] = [];

    if ($('.lumpsum-topup').length > 0) {
      for (let i = 1; i <= $('.lumpsum-topup').length; i++) {
        let inputAge = $(`#topup_age_value_${i}`).closest(".range-slider").prevAll(".rangeValues:first");
        let inputAmount = $(`#topup_amount_${i}`).closest(".range-slider").prevAll(".rangeValues:first");
        let age = Number(inputAge.text() || inputAge.val());
        let amount = Number(inputAmount.text() || inputAmount.val());
        let newLumSumObject = {};
        newLumSumObject[age] = amount;
        this.investmentInfomationForm['topUp'].push(newLumSumObject);
      }
    } else {
      this.investmentInfomationForm['topUp'].push({'0': '0'});
    }

    if ($('.lumpsum-withdrawal').length > 0) {
      for (let i = 1; i <= $('.lumpsum-withdrawal').length; i++) {
        let inputAge = $(`#withdrawal_age_value_${i}`).closest(".range-slider").prevAll(".rangeValues:first");
        let inputAmount = $(`#withdrawal_amount_${i}`).closest(".range-slider").prevAll(".rangeValues:first");
        let age = Number(inputAge.text() || inputAge.val());
        let amount = Number(inputAmount.text() || inputAmount.val());
        let newWithdralObject = {};
        newWithdralObject[age] = amount;
        this.investmentInfomationForm['withdrawal'].push(newWithdralObject);
      }
    } else {
      this.investmentInfomationForm['withdrawal'].push({'0': '0'});
    }

    this.investmentInfomationForm['topUp'] = this.investmentInfomationForm['topUp'].filter(Boolean)
    this.investmentInfomationForm['withdrawal'] = this.investmentInfomationForm['withdrawal'].filter(Boolean)
  }

  handleAddNewLumpSumTopup() {
    $('.add-new-lumpsum-topup').on('click', () => {
      let items_count = $('.lumpsum-topup').length;
      let new_input = `
      <div class="lumpsum-topup" id="lumpsum_topup_${items_count + 1}">
        <div class="text-left mb-2">
          <h2 class="font-size-16-text">Lump Sum Top Up ${items_count + 1}</h2>
          <span class="age title-lumsum-text-age" id="title-lumsum-topup-age-${items_count + 1}"></span>
          <span class="amount title-lumsum-text-amount" id="title-lumsum-topup-amount-${items_count + 1}"></span>
        </div>
        <div class="form-group input-with-extra-info string optional mb-3 age-wrapper">
          <label class="string optional p-0 mb-3 font-size-14-text" for="topup_age_value_${items_count + 1}">At the age of &nbsp;</label>
          <span class="rangeValues age age-wrapper-${items_count + 1} ageArr">0</span>
          <section class="range-slider">
            <input class="input-slide" max="100" min="0" step="1" type="range" value="0" id="topup_age_value_${items_count + 1}"/>
          </section>
        </div>
        <div class="form-group string optional amount-wrapper input-with-extra-info mt-3">
          <label class="string optional p-0 mb-3 font-size-14-text" for="topup_amount_${items_count + 1}">with an amount</label>
          <span class="rangeValues-item">&nbsp;$</span>
          <span class="rangeValues amount-wrapper-${items_count + 1} amountLumSumArr">0</span>
          <section class="range-slider">
            <input class="input-slide" max="1000000" min="0" step="100" type="range" value="0" id="topup_amount_${items_count + 1}"/>
          </section>
        </div>
        <button class="btn btn-sm btn-danger float-right remove-lumpsum-topup-button" data-form-id="lumpsum_topup_${items_count + 1}">Remove</button>
        <hr class="hr-lumpsum-topup">
      </div>`;

      $('#lumpsum-topup-content').append(new_input);

      let arr = document.getElementsByClassName('lumpsum-topup');

      if ($('.lumpsum-topup').length > 1) {
        arr.forEach((topup, index) => {
          $(topup).addClass('hide-content-lumsum');
          let ageText = $(`#lumpsum_topup_${items_count} .age-wrapper`).find('span').text();
          let amountText = $(`#lumpsum_topup_${items_count} .amount-wrapper`).find('span').text();

          $(`#title-lumsum-topup-age-${items_count}`).text('Age ' + (ageText === '' ? '0' : ageText));
          $(`#title-lumsum-topup-amount-${items_count}`).text((amountText === '' ? '0' : amountText));
        });
        $(`#lumpsum_topup_${$('.lumpsum-topup').length}`).removeClass('hide-content-lumsum');
      }

      $(".range-slider").each(function() {
        let sliders = $(this).find("input[type='range']");
        sliders.on("input", () => {
          let parent = $(this).closest(".range-slider");
          let slides = parent.find("input");
          let slide1 = parseFloat(slides.eq(0).val());
          let slide2 = parseFloat(slides.eq(1).val());

          if (slide2) {
            if (slide1 > slide2) {
              $('#public_investment_illustrator_age_from').text(slide2)
              $('#public_investment_illustrator_age_to').text(slide1)
            } else {
              $('#public_investment_illustrator_age_from').text(slide1)
              $('#public_investment_illustrator_age_to').text(slide2)
            }
          } else {
            let displayElement = parent.prevAll('.rangeValues:first');
            displayElement.html(slide1);
          }
        });
      });
    });
  }

  handleAddNewLumpSumWithdrawal() {
    $('.add-new-lumpsum-withdrawal').on('click', () => {
      let items_count = $('.lumpsum-withdrawal').length;
      let new_input = `
        <div class="lumpsum-withdrawal" id="lumpsum_withdrawal_${items_count + 1}">
          <div class="text-left mb-2">
            <h2 class="font-size-16-text">Lump Sum Withdrawals ${items_count + 1}</h2>
            <span class="age title-lumsum-text-age" id="title-lumsum-withdrawal-age-${items_count + 1}"></span>
          <span class="amount title-lumsum-text-amount" id="title-lumsum-withdrawal-amount-${items_count + 1}"></span>
          </div>
          <div class="form-group input-with-extra-info string optional mb-3 age-wrapper">
            <label class="string optional p-0 mb-3 font-size-14-text" for="withdrawal_age_value_${items_count + 1}">At the age of &nbsp;</label>
            <span class="rangeValues age-wrapper-${items_count + 1} ageArr">0</span>
            <section class="range-slider">
              <input class="input-slide" max="100" min="0" step="1" type="range" value="0" id="withdrawal_age_value_${items_count + 1}"/>
            </section>
          </div>
          <div class="form-group input-with-extra-info string optional amount-wrapper mt-3">
            <label class="string optional p-0 mb-3 font-size-14-text" for="withdrawal_amount_${items_count + 1}">with an amount</label>
            <span class="rangeValues-item">&nbsp;$</span>
            <span class="rangeValues amount-wrapper-${items_count + 1} amountLumSumArr">0</span>
            <section class="range-slider">
              <input class="input-slide" max="1000000" min="0" step="100" type="range" value="0" id="withdrawal_amount_${items_count + 1}"/>
            </section>
          </div>
          <button class="btn btn-sm btn-danger float-right remove-lumpsum-withdrawal-button" data-form-id="lumpsum_withdrawal_${items_count + 1}">Remove</button>
          <hr class="hr-lumpsum-withdrawal">
        </div>
      `;

      $('#lumpsum-withdrawal-content').append(new_input);
      let arr = document.getElementsByClassName('lumpsum-withdrawal');

      if ($('.lumpsum-withdrawal').length > 1) {
        arr.forEach((withdrawal, index) => {
          $(withdrawal).addClass('hide-content-lumsum');
          let ageText = $(`#lumpsum_withdrawal_${items_count} .age-wrapper`).find('span').text();
          let amountText = $(`#lumpsum_withdrawal_${items_count} .amount-wrapper`).find('span').text();

          $(`#title-lumsum-withdrawal-age-${items_count}`).text('Age ' + (ageText === '' ? '0' : ageText));
          $(`#title-lumsum-withdrawal-amount-${items_count}`).text((amountText === '' ? '0' : amountText));
        });
        $(`#lumpsum_withdrawal_${$('.lumpsum-withdrawal').length}`).removeClass('hide-content-lumsum');
      }

      $(".range-slider").each(function() {
        let sliders = $(this).find("input[type='range']");
        sliders.on("input", () => {
          let parent = $(this).closest(".range-slider");
          let slides = parent.find("input");
          let slide1 = parseFloat(slides.eq(0).val());
          let slide2 = parseFloat(slides.eq(1).val());

          if (slide2) {
            if (slide1 > slide2) {
              $('#public_investment_illustrator_age_from').text(slide2)
              $('#public_investment_illustrator_age_to').text(slide1)
            } else {
              $('#public_investment_illustrator_age_from').text(slide1)
              $('#public_investment_illustrator_age_to').text(slide2)
            }
          } else {
            let displayElement = parent.prevAll('.rangeValues:first');
            displayElement.html(slide1);
          }
        });
      });
    });
  }

  triggerHideLumpSum() {
    $(document).ready(() => {
      $('#lumpsum-topup-content').on('click', 'div.lumpsum-topup', function() {
        let idLumpSum = $(this).attr('id');
        let $lumpsumTopupDivs = $('.lumpsum-topup');

        $lumpsumTopupDivs.each(function(index, topup) {
          if (!$(topup).hasClass('hide-content-lumsum')) {
            $(topup).addClass('hide-content-lumsum');
            let ageText = $(`#lumpsum_topup_${index + 1} .age-wrapper`).find('span').text();
            let amountText = $(`#lumpsum_topup_${index + 1} .amount-wrapper`).find('span').text();

            $(`#title-lumsum-topup-age-${index + 1}`).text('Age ' + (ageText === '' ? '0' : ageText));
            $(`#title-lumsum-topup-amount-${index + 1}`).text((amountText === '' ? '0' : amountText));
          }
        });

        $(`#${idLumpSum}`).removeClass('hide-content-lumsum');
      });

      $('#lumpsum-withdrawal-content').on('click', 'div.lumpsum-withdrawal', function() {
        let idLumpSum = $(this).attr('id');
        let $lumpsumWithdrawalDivs = $('.lumpsum-withdrawal');

        $lumpsumWithdrawalDivs.each(function(index, withdrawal) {
          if (!$(withdrawal).hasClass('hide-content-lumsum')) {
            $(withdrawal).addClass('hide-content-lumsum');
            let ageText = $(`#lumpsum_withdrawal_${index + 1} .age-wrapper`).find('span').text();
            let amountText = $(`#lumpsum_withdrawal_${index + 1} .amount-wrapper`).find('span').text();

            $(`#title-lumsum-withdrawal-age-${index + 1}`).text('Age ' + (ageText === '' ? '0' : ageText));
            $(`#title-lumsum-withdrawal-amount-${index + 1}`).text((amountText === '' ? '0' : amountText));
          }
        });

        $(`#${idLumpSum}`).removeClass('hide-content-lumsum');
      });
    });
  }

  triggerRemoveLumpSum() {
    $('#additional-information').on('click', '.remove-lumpsum-topup-button, .remove-lumpsum-withdrawal-button', (e) => {
      const $this = $(e.target);
      $(`#${$this.data('form-id')}`).remove();

      if ($this.hasClass('remove-lumpsum-topup-button')) {
        let arr = document.getElementsByClassName('lumpsum-topup');
        if ($('.lumpsum-topup').length == 1) {
          $('.lumpsum-topup').attr('id', 'lumpsum_topup_1');
          $(`#lumpsum_topup_1 h2`).html('Lump Sum Top-up 1');
          $(`#lumpsum_topup_1 button`).attr('data-form-id', 'lumpsum_topup_1');
          $('#lumpsum_topup_1 .age-wrapper label').attr('for', 'topup_age_value_1');
          $('#lumpsum_topup_1 .age-wrapper input').attr('id', 'topup_age_value_1');
          $('#lumpsum_topup_1 .amount-wrapper label').attr('for', 'topup_amount_1');
          $('#lumpsum_topup_1 .amount-wrapper input').attr('id', 'topup_amount_1');
        } else if ($('.lumpsum-topup').length > 0 && $('.lumpsum-topup').length != 1) {
          arr.forEach((topup, index) => {
            $(topup).attr('id', `lumpsum_topup_${index + 1}`);
            $(`#lumpsum_topup_${index + 1} h2`).html(`Lump Sum Top-up ${index + 1}`);
            $(`#lumpsum_topup_${index + 1} button`).attr('data-form-id', `lumpsum_topup_${index + 1}`);
            $(`#lumpsum_topup_${index + 1} .age-wrapper label`).attr('for', `topup_age_value_${index + 1}`);
            $(`#lumpsum_topup_${index + 1} .age-wrapper input`).attr('id', `topup_age_value_${index + 1}`);
            $(`#lumpsum_topup_${index + 1} .amount-wrapper label`).attr('for', `topup_amount_${index + 1}`);
            $(`#lumpsum_topup_${index + 1} .amount-wrapper input`).attr('id', `topup_amount_${index + 1}`);
          });
        }
      } else {
        let arr = document.getElementsByClassName('lumpsum-withdrawal');
        if ($('.lumpsum-withdrawal').length == 1) {
          $('.lumpsum-withdrawal').attr('id', 'lumpsum_withdrawal_1');
          $(`#lumpsum_withdrawal_1 h2`).html('Lump Sum Withdrawal 1');
          $(`#lumpsum_withdrawal_1 button`).attr('data-form-id', 'lumpsum_withdrawal_1');
          $('#lumpsum_withdrawal_1 .age-wrapper label').attr('for', 'withdrawal_age_value_1');
          $('#lumpsum_withdrawal_1 .age-wrapper input').attr('id', 'withdrawal_age_value_1');
          $('#lumpsum_withdrawal_1 .amount-wrapper label').attr('for', 'withdrawal_amount_1');
          $('#lumpsum_withdrawal_1 .amount-wrapper input').attr('id', 'withdrawal_amount_1');
        } else if ($('.lumpsum-withdrawal').length > 0 && $('.lumpsum-withdrawal').length != 1) {
          arr.forEach((withdrawal, index) => {
            $(withdrawal).attr('id', `lumpsum_withdrawal_${index + 1}`);
            $(`#lumpsum_withdrawal_${index + 1} h2`).html(`Lump Sum withdrawal ${index + 1}`);
            $(`#lumpsum_withdrawal_${index + 1} button`).attr('data-form-id', `lumpsum_withdrawal_${index + 1}`);
            $(`#lumpsum_withdrawal_${index + 1} .age-wrapper label`).attr('for', `withdrawal_age_value_${index + 1}`);
            $(`#lumpsum_withdrawal_${index + 1} .age-wrapper input`).attr('id', `withdrawal_age_value_${index + 1}`);
            $(`#lumpsum_withdrawal_${index + 1} .amount-wrapper label`).attr('for', `withdrawal_amount_${index + 1}`);
            $(`#lumpsum_withdrawal_${index + 1} .amount-wrapper input`).attr('id', `withdrawal_amount_${index + 1}`);
          });
        }
      }

      this.updateChartData()
    });
  }

  handleSpanToInput() {
    $(document).ready(function() {
      $('#public-investment-illustrator-form').on('click', '.rangeValues', function() {
        let $span = $(this);
        let id = this.id;
        let originalValue = $span.text() || $span.val();
        let activeElement = document.activeElement;
        if (activeElement.id == id && activeElement.tagName === 'INPUT') {
          return
        }
        let $input = $(`<input class="input-with-span rangeValues" id="${id}" value="${$span.text()}" type="text">`).val(originalValue);
        if (this.classList.contains('ageArr')) {
          $input = $(`<input class="input-with-span rangeValues ageArr" id="${id}" value="${$span.text()}" type="text" min="0" max="100">`).val(originalValue);
        }

        if (this.classList.contains('amountArr')) {
          $input = $(`<input class="input-with-span rangeValues amountArr" id="${id}" value="${$span.text()}" type="text" min="0" max="500000">`).val(originalValue);
        }

        if (this.classList.contains('amountLumSumArr')) {
          $input = $(`<input class="input-with-span rangeValues amountLumSumArr" id="${id}" value="${$span.text()}" type="text" min="0" max="1000000">`).val(originalValue);
        }

        if (this.classList.contains('rateArr')) {
          $input = $(`<input class="input-with-span rangeValues rateArr" id="${id}" value="${$span.text()}" type="text" min="0" max="20">`).val(originalValue);
        }

        $span.replaceWith($input);
        let textLength = $input.val().length;
        $input.val('');
        $input.focus();
        $input.css('width', textLength * 14 + 10 + 'px');
        $input.val(originalValue);

        $input.on('input', function(e) {
          let newValue = $(this).val().replace(/\b0+(\d+\.\d*|\d+)/g, "$1");
          if (!this.classList.contains('rateArr')) {
            newValue = newValue.replace(/[^0-9]/g, '');
          }

          let max = parseInt($(this).attr('max'));
          let min = parseInt($(this).attr('min'));
          let textLength = $input.val().length;

          if (newValue > max) {
            newValue = max
          } else if (newValue < min) {
            newValue = min;
          }

          if (newValue === '') {
            newValue = 0
          }

          $input.css('width', textLength * 14 + 15 + 'px');
          $input.val(newValue);
          $input.parent().find('.range-slider input').val(newValue)
        });

        $input.on('blur', function() {
          let max = parseInt($(this).attr('max'));
          let min = parseInt($(this).attr('min'));
          let newValue = $(this).val();
          let textLength = $input.val().length;

          if (id == 'public_investment_illustrator_age_from') {
            let calcLeftPosition = value => (100 / (100 - 1)) * (value - 1);
            let lineLeft = calcLeftPosition(newValue);
            let lineRight = parseInt($('#public_investment_illustrator_age_to').text());

            if (newValue > lineRight) {
              newValue = lineRight - 1;
              lineLeft = calcLeftPosition(newValue);
            }

            $('#line').css({'left': lineLeft + '%', 'right': (100 - lineRight) + '%'});
            $('#thumbMin').css({'left': lineLeft + '%', 'right': 'unset'});
            $('#thumbMax').css({'left': 'unset', 'right': 100 - lineRight + '%'});

            $('#public_investment_illustrator_age_from').html(newValue);
            $('#public_investment_illustrator_age_to').html(lineRight);
            $('#rangeMin').val(newValue);
            $('#rangeMax').val(lineRight);
          }

          if (id === 'public_investment_illustrator_age_to') {
            let calcLeftPosition = value => (100 / (100 - 1)) * (value - 1);
            let lineLeft = parseInt($('#public_investment_illustrator_age_from').text());
            let lineRight = calcLeftPosition(newValue);

            if (newValue < lineLeft) {
              newValue = lineLeft + 1;
              lineRight = calcLeftPosition(newValue);
            }

            $('#line').css({'left': lineLeft + '%', 'right': (100 - lineRight) + '%'});
            $('#thumbMin').css({'left': lineLeft + '%', 'right': 'unset'});
            $('#thumbMax').css({'left': 'unset', 'right': 100 - lineRight + '%'});

            $('#public_investment_illustrator_age_from').html(lineLeft);
            $('#public_investment_illustrator_age_to').html(newValue);
            $('#rangeMin').val(lineLeft);
            $('#rangeMax').val(newValue);
          }

          if (newValue === '') {
            newValue = 0;
            $(this).val(newValue);
          }

          let $newSpan = $(`<span class="rangeValues" id="${id}">`).text(newValue);

          if (this.classList.contains('ageArr')) {
            $newSpan = $(`<span class="rangeValues ageArr" id="${id}">`).text(newValue);
          }

          if (this.classList.contains('amountArr')) {
            $newSpan = $(`<span class="rangeValues amountArr" id="${id}">`).text(newValue);
          }

          if (this.classList.contains('amountLumSumArr')) {
            $newSpan = $(`<span class="rangeValues amountLumSumArr" id="${id}">`).text(newValue);
          }

          if (this.classList.contains('rateArr')) {
            $newSpan = $(`<span class="rangeValues rateArr" id="${id}">`).text(newValue);
          }

          $input.replaceWith($newSpan);
        });
      });
    });
  }

  updateChartWhenBlurInputAge() {
    $('body').on('change', '#public-investment-illustrator-form input', (e) => {
      let id = $(e.target).attr('id');
      if (id == 'public_investment_illustrator_age_from' || id == 'public_investment_illustrator_age_to') {
        setTimeout(() => {
          this.updateChartData();
        }, 200);
      }
    });
  }

  triggerUpdateDataCalculate() {
    $(document).ready(() => {
      $('#public-investment-illustrator-form').on('input', () => {
        this.updateChartData();
      });
    });
  }

  updateChartData() {
    this.getInvestmentIllustratorObjectForm();
    this.data_chart = calculate(this.investmentInfomationForm);
    this.updateChart(this.data_chart);
    this.updateCalculatorInfoResult();
  }

  filterData(data, agePoints) {
    let filteredData = [];
    for (let age in data) {
      if (agePoints.includes(Number(age))) {
        filteredData.push(data[age]);
      }
    }
    return filteredData;
  }

  updateChart(data_chart) {
    let agePoints = [];
    let agePointData = [];
    let numPoints = 11;
    let minValue = parseInt(this.investmentInfomationForm.startAge, 10);
    let maxValue = parseInt(this.investmentInfomationForm.endAge, 10);
    let step = parseInt((maxValue - minValue) / numPoints, 10);

    if (minValue == 0) {
      minValue = 1
    }

    for (let i = minValue; i <= maxValue; i++) {
      agePointData.push(i);
    }

    if ((maxValue - minValue) <= 12) {
      for (let i = 1; i <= maxValue; i++) {
        let agePoint = minValue + i * step;

        if (step <= 1) {
          agePoint = minValue + i;
        }

        if (agePoint >= maxValue) {
          break;
        }

        agePoints.push(agePoint);
      }
    } else {
      for (let i = 1; i < numPoints; i++) {
        let agePoint = minValue + i * step;
        if (agePoint >= maxValue) {
          break;
        }
        agePoints.push(agePoint);
      }
    }

    let lastAgeArray = agePoints[agePoints.length - 1]
    if (((maxValue - lastAgeArray) / step) >= 2) {
      for (let i = 1; i < parseInt((maxValue - lastAgeArray)/step); i++) {
        agePoints.push(agePoints[agePoints.length - 1] + step)
      }
    }

    agePoints.unshift(minValue);
    agePoints.push(maxValue);

    if ((maxValue - minValue) == 0) {
      agePoints = []
      agePoints[0] = minValue
    }

    if (agePointData[agePointData.length] == 100 && agePointData[0] == 0 && maxValue == 100) {
      agePointData.shift(0);
      agePointData.push(maxValue);
    }

    let filteredDataMoneyInvested = this.filterData(data_chart.moneyInvested, agePointData);
    let filteredDataTotalMoneyInvested = this.filterData(data_chart.totalMoneyInvested, agePointData);
    let filteredDataNetAccountValue = this.filterData(data_chart.netAccountValue, agePointData);
    let filteredDataIncomeDrawdown = this.filterData(data_chart.incomeDrawdown, agePointData);

    let data = {
      labels: agePointData,
      datasets: [
        {
          type: 'bar',
          label: 'Money Invested',
          data: filteredDataMoneyInvested,
          borderWidth: 0,
          barPercentage: -1.0,
          categoryPercentage: -2.1,
          backgroundColor: 'rgba(228, 139, 40)',
          hoverBackgroundColor: 'rgba(228, 139, 40)',
        },
        {
          type: 'line',
          label: 'Total Money Invested',
          data: filteredDataTotalMoneyInvested,
          borderWidth: 0,
          lineTension: 0.2,
          pointRadius: 0,
          fill: true,
          backgroundColor: 'rgba(255, 240, 112, 0.7)',
          hoverBackgroundColor: 'rgba(255, 240, 112, 0.7)',
        },
        {
          type: 'line',
          label: 'Net Account Value',
          data: filteredDataNetAccountValue,
          borderWidth: 0,
          lineTension: 0.2,
          pointRadius: 0,
          fill: true,
          backgroundColor: 'rgba(130, 151, 203, 0.9)',
          hoverBackgroundColor: 'rgba(130, 151, 203, 0.9)',
        },
        {
          type: 'bar',
          label: 'Income Draw Down',
          data: filteredDataIncomeDrawdown,
          borderWidth: 0,
          borderColor: 'rgba(139, 191, 159)',
          barPercentage: -1.0,
          categoryPercentage: -2.1,
          backgroundColor: 'rgba(139, 191, 159)',
          hoverBackgroundColor: 'rgba(139, 191, 159)',
        },
      ],
      backgroundColor: '#F00',
    };

    let options = {
      scales: {
        x: {
          display: true,
          stacked: false,
          offset: false,
          grid: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            autoSkip: false,
            callback: function(val, index) {
              return agePoints.includes(agePointData[val]) ? agePointData[val] : '';
            },
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: function(value, index, ticks) {
                return '$' + value / 1000;
            }
          },
          title: {
            display: true,
            text: 'THOUSANDS',
            font: {
              weight: '500'
            },
          },
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem) {
              let datasetLabel = tooltipItem.dataset.label || '';
              let value = tooltipItem.formattedValue;
              if (typeof value === 'string') {
                value = parseFloat(value.replace(/,/g, ''));
              }

              let roundedNumber = Math.round(value);
              let formattedNumber = roundedNumber.toLocaleString();

              return datasetLabel + ': $' + formattedNumber;
            }
          }
        },
      },
      interaction: {
        mode: 'index',
        axis: 'x',
        intersect: false
      }
    };

    let chart = Chart.getChart(this.chartElementContainer);

    if (chart) {
      chart.destroy();
    }

    let ctx = this.chartElementContainer;
    let myChart = new Chart(ctx, { data: data, options: options });

    this.generateLegend(myChart);
  }

  generateLegend(myChart) {
    let arrayOfSpans = [
      '<span class="total-contribution" data-placement="top" data-toggle="tooltip" data-trigger="hover" data-html="true" title="" data-original-title="The amount of money you invest at that particular year."><img alt="btn-icon" class="help-icon" src="/packs/media/images/icons/help-icon-d96447f0a5e8af810f2d95ffc86d6254.png"></span>',
      '<span class="total-payout" data-placement="top" data-toggle="tooltip" data-trigger="hover" data-html="true" title="" data-original-title="The total amount of money invested"><img alt="btn-icon" class="help-icon" src="/packs/media/images/icons/help-icon-d96447f0a5e8af810f2d95ffc86d6254.png"></span>',
      '<span class="remaining-amount" data-placement="top" data-toggle="tooltip" data-trigger="hover" data-html="true" title="" data-original-title="The total dollar value of your investment. This number is <br/> calculated after taking into account the value of the total <br/> investments made minus withdrawals."><img alt="btn-icon" class="help-icon" src="/packs/media/images/icons/help-icon-d96447f0a5e8af810f2d95ffc86d6254.png"></span>',
      '<span class="project-worth" data-placement="top" data-toggle="tooltip" data-trigger="hover" data-html="true" title="" data-original-title="The amount of money drawn out from the </br> investment at that particular year."><img alt="btn-icon" class="help-icon" src="/packs/media/images/icons/help-icon-d96447f0a5e8af810f2d95ffc86d6254.png"></span>'
    ];

    let chartBox = document.querySelector('.canvas-container')
    let div = document.querySelector('#customLegend');
    div.innerHTML = '';
    let ul = document.createElement('ul');
    myChart.legend.legendItems.forEach((dataset, index) => {
      let text = dataset.text;
      let datasetIndex = dataset.datasetIndex;
      let bgColor = dataset.fillStyle;
      let bColor = dataset.strokeStyle;
      let fontColor = dataset.fontColor;

      let li = document.createElement('li');

      let spanBox = document.createElement('span');
      spanBox.style.backgroundColor = bgColor;

      let p = document.createElement('p');
      let textNode = document.createTextNode(text);
      let tooltip = $(arrayOfSpans[index])[0]

      li.onclick = (click) => {
        let isHidden = !myChart.isDatasetVisible(datasetIndex);
        myChart.setDatasetVisibility(datasetIndex, isHidden);
        this.updateLenged(click, myChart);
      }

      ul.appendChild(li);
      li.appendChild(spanBox);
      li.appendChild(p);
      li.appendChild(tooltip);
      p.appendChild(textNode);
    });

    chartBox.prepend(div);
    div.appendChild(ul);
  }

  updateLenged(click, myChart) {
    let element = click.target.parentNode;
    element.classList.toggle('fade');
    myChart.update();
  }

  updateCalculatorInfoResult() {
    $('.end-age-info').text(this.investmentInfomationForm['endAge']);
    $('.retire-age-info').text(this.investmentInfomationForm['retireAge']);
    $('.retirement-amount-info').text(formatCurrencyWholeNumber(this.investmentInfomationForm['retirementAmount']));

    //take note age
    let netAccountValueBecomeZero = 0;
    for (let key in this.data_chart.incomeDrawdown) {
      if (this.data_chart.incomeDrawdown[key] !== 0) {
        netAccountValueBecomeZero = key;
      }
    }

    $('.retire-age-take-note-info').text(netAccountValueBecomeZero);
    $('.retire-age-take-note-info-2').text(this.investmentInfomationForm['endAge'] - netAccountValueBecomeZero);
    $('.retirement-amount-info').text(formatCurrencyWholeNumber(this.investmentInfomationForm['retirementAmount']));

    //first note
    let firstNoteResultInfo = this.data_chart.totalMoneyInvested[`${this.investmentInfomationForm['retireAge'] - 1}`]
    let firstTotalAmountInfo = this.data_chart.netAccountValue[`${this.investmentInfomationForm['retireAge'] - 1}`]

    firstNoteResultInfo = (typeof firstNoteResultInfo === 'undefined' || isNaN(firstNoteResultInfo)) ? 0 : firstNoteResultInfo
    firstTotalAmountInfo = (typeof firstTotalAmountInfo === 'undefined' || isNaN(firstTotalAmountInfo)) ? 0 : firstTotalAmountInfo
    $('.first-note-result-info').text(formatCurrencyWholeNumber(firstNoteResultInfo));
    $('.total-contribution-info').text(formatCurrencyWholeNumber(firstTotalAmountInfo));

    //second-note
    let totalContributionInfo = this.data_chart.totalMoneyInvested[`${this.investmentInfomationForm['endAge']}`]
    let payOutValuePublic = this.data_chart.totalWithdrawal[`${this.investmentInfomationForm['endAge']}`]
    let remainingValuePublic = this.data_chart.netAccountValue[`${this.investmentInfomationForm['endAge']}`]
    let projectWorthValue = -1 * payOutValuePublic + remainingValuePublic

    $('.contribution-value-public-invesment-illustrator').text(formatCurrencyWholeNumber(totalContributionInfo));
    $('.payout-value-public-invesment-illustrator').text(formatCurrencyWholeNumber(payOutValuePublic * -1));
    $('.remaining-value-public-invesment-illustrator').text(formatCurrencyWholeNumber(remainingValuePublic));
    $('.project-worth-value-public-invesment-illustrator').text(formatCurrencyWholeNumber(projectWorthValue));

    if (totalContributionInfo > 999999 || payOutValuePublic > 999999 || remainingValuePublic > 999999 || projectWorthValue > 999999) {
      $('.contribution-value-public-invesment-illustrator').parent().addClass('col-xl-6');
      $('.payout-value-public-invesment-illustrator').parent().addClass('col-xl-6');
      $('.remaining-value-public-invesment-illustrator').parent().addClass('col-xl-6');
      $('.project-worth-value-public-invesment-illustrator').parent().addClass('col-xl-6');
    } else {
      $('.contribution-value-public-invesment-illustrator').parent().removeClass('col-xl-6');
      $('.payout-value-public-invesment-illustrator').parent().removeClass('col-xl-6');
      $('.remaining-value-public-invesment-illustrator').parent().removeClass('col-xl-6');
      $('.project-worth-value-public-invesment-illustrator').parent().removeClass('col-xl-6');
    }

    //growth of
    let firstGrowthOf = parseFloat(((firstTotalAmountInfo / firstNoteResultInfo) - 1) * 100)
    let secondGrowthOf = parseFloat((projectWorthValue - totalContributionInfo) / totalContributionInfo * 100) + 1


    $('.growth-of-1').html(`${isNaN(firstGrowthOf) ? '0' : Math.floor(firstGrowthOf)}%<span data-placement="top" data-toggle="tooltip" data-trigger="hover" data-html="true" title="" data-original-title="With a rate of return of ${this.investmentInfomationForm['rate']}%, your money will double every ${Math.floor(72 / this.investmentInfomationForm['rate'])} years. <br/><br/> Growth of ${isNaN(firstGrowthOf) ? '0' : Math.floor(firstGrowthOf)}% is based on returns gained divided by total invested amount which is ([Net Account Value – Total Money Invested] divided by Total Money Invested)"><img alt="btn-icon" class="help-icon" src="/packs/media/images/icons/help-icon-d96447f0a5e8af810f2d95ffc86d6254.png"></span>`);

    $('.growth-of-2').text(isNaN(secondGrowthOf) ? '0' : Math.floor(secondGrowthOf));

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    if (this.investmentInfomationForm['startAge'] == 0) {
      $('.retirement-message-1').addClass('d-none');
      $('.retirement-message-2').addClass('d-none');
    } else if (parseInt(netAccountValueBecomeZero) < parseInt(this.investmentInfomationForm['endAge'])) {
      $('.retirement-message-1').addClass('d-none');
      $('.retirement-message-2').removeClass('d-none');
    } else {
      $('.retirement-message-1').removeClass('d-none');
      $('.retirement-message-2').addClass('d-none');
    }
  }

  showBreakdownTable() {
    $('.view-breakdown-link').on('click', () => {
      this.getInvestmentIllustratorObjectForm();
      this.data_chart = calculate(this.investmentInfomationForm);

      $('#public-investment-illustrator-breakdown-data').html('');
      $('#breakdown-public-investment-illustrator-table-header').html('');
      $('#breakdown-public-investment-illustrator-table-header').append(`
        <tr>
          <th scope="col" class="primary-sticky-header">Age</th>
          <th scope="col" class="primary-sticky-header pr-4">Retirement Income</th>
          <th scope="col" class="primary-sticky-header">End Year Balance</th>
        </tr>
      `);

      if (this.investmentInfomationForm['retireAge'] - 1 > this.investmentInfomationForm['endAge']) {
        $('#public-investment-illustrator-breakdown-data').append(`
          <tr>
            <td colspan="3">Current Age must be more than retirement age!</td>
          </tr>
        `);
      } else {
        for (let i = this.investmentInfomationForm['retireAge'] - 1; i <= this.investmentInfomationForm['endAge']; i++) {
          const age = i;
          const retirementIncome = this.data_chart.retirementDrawBreakDown[i][0];
          const endYearBalance = this.data_chart.retirementDrawBreakDown[i][1];

          $('#public-investment-illustrator-breakdown-data').append(`
            <tr>
              <td>${age}</td>
              <td style="color: red;">${formatCurrencyWholeNumber(retirementIncome)}</td>
              <td>${formatCurrencyWholeNumber(endYearBalance)}</td>
            </tr>
          `);
        }
      }

      $('#public-investment-illustrator-modal').modal({
        backdrop: 'static',
        keyboard: false,
      });
    });
  }
}
