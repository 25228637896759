import Raicon from 'raicon';
import ImportFCBrandForm from '@/layouts/application/utils/dropzone/importFCBrandForm';
class AdminFcBrandsController {
  constructor() {
    this.submitButton = '#submit-import-fc-brand';
  }

  bulkEdit() {
    this.initDropZone();
  }

  initDropZone() {
    new ImportFCBrandForm().setup();
  }

}

Raicon.register('admin/fcBrands', AdminFcBrandsController);
