import { getAge } from '@/layouts/application/utils/calculateAgeByInput';

export default class AssetEvents {
  insurancePersonDob = undefined;

  policyInceptionDate = undefined;
  policyInceptionAge = undefined;
  policyEndDate = undefined;
  policyEndDateCoverage = undefined;

  policyPaymentStartYear = undefined;
  policyPaymentEndYear = undefined;
  policyPaymentCoverage = undefined;
  policyPaymentStartAge = undefined;
  policyPaymentEndAge = undefined;

  constructor() {
    this.insurancePersonFieldSetSelector = '.insurance-person-input';
    this.insurancePersonListSelector = '#insurance-person-list';
    this.policyInceptionDateSelector = '.policy-inception-date';
    this.policyInceptionAgeSelector = '.policy-inception-age';
    this.policyEndDateSelector = '.policy-end-date';
    this.policyEndAgeSelector = '.policy-end-age';
    this.policyEndCoverageSelector = '.policy-end-coverage';
    this.paymentStartYearSelector = '#payment-start-year';
    this.paymentStartYearAgeSelector = '#payment-start-year-age';
    this.paymentEndYearSelector = '#payment-end-year';
    this.paymentEndYearAgeSelector = '#payment-end-year-age';
    this.paymentEndYearCoverageSelector = '#payment-end-year-coverage';
    this.tillAgeSelector = '.till-age-input';
    this.sumAssuredSelector = '.sum-assured-input';
  }

  setInsurancePersonDob(dob) {
    this.insurancePersonDob = dob;
  }

  setAssetEvents() {
    this.setExistedValue();
    this.setOnchangeEventForInsurancePerson();
    this.setOnchangeEventForPolicyInceptionDate();
    this.setOnchangeEventForPolicyEndDate();
    this.setOnChangeEventForPaymentStartYear();
    this.setOnChangeEventForPaymentEndYear();
    this.calculationForPaymentDateCoverage();
    this.calculationForPaymentStartYearAge();
    this.calculationForPaymentEndYearAge();
    this.calculationForpolicyInceptionAge();
    this.calculationForPolicyEndDateCoverageAndAge();
  }

  setExistedValue() {
    const $personSelected = $(this.insurancePersonListSelector).find('input:checked');
    const $policyInceptionDate = $(this.policyInceptionDateSelector);
    const $policyEndDate = $(this.policyEndDateSelector);
    const $paymentStartYear = $(this.paymentStartYearSelector);
    const $paymentEndYear = $(this.paymentEndYearSelector);

    if ($personSelected.length > 0) {
      this.setInsurancePersonDob(new Date(JSON.parse($personSelected.val()).dob));
    }

    this.policyInceptionDate = new Date($policyInceptionDate.val());
    this.policyEndDate = new Date($policyEndDate.val());
    this.policyPaymentStartYear = $paymentStartYear.val();
    this.policyPaymentEndYear = $paymentEndYear.val();
  }

  setOnchangeEventForInsurancePerson() {
    $(this.insurancePersonFieldSetSelector).on('click', ({ target }) => {
      this.setInsurancePersonDob(new Date(JSON.parse(target.value).dob));
      this.calculationForPolicyEndDateDefaultValue();
      this.calculationForpolicyInceptionAge();
      this.calculationForPolicyEndDateCoverageAndAge();
      this.calculationForPaymentStartYearAge();
      this.calculationForpolicyInceptionAge();
      this.calculationForPaymentEndYearAge();
    });
  }

  setOnchangeEventForPolicyInceptionDate() {
    $(this.policyInceptionDateSelector).on('change', ({ target }) => {
      this.policyInceptionDate = new Date(target.value);
      this.calculationForpolicyInceptionAge();
      this.calculationForPolicyEndDateCoverageAndAge();
      this.calculationForPolicyEndDateDefaultValue();
    });
  }

  calculationForpolicyInceptionAge() {
    if (!this.insurancePersonDob || !this.policyInceptionDate) {
      return false;
    }

    this.policyInceptionAge = getAge(this.insurancePersonDob, this.policyInceptionDate);

    $(this.policyInceptionAgeSelector).html(this.policyInceptionAge);
  }

  setOnchangeEventForPolicyEndDate() {
    $(this.policyEndDateSelector).on('change', ({ target }) => {
      this.policyEndDate = new Date(target.value);
      this.calculationForPolicyEndDateCoverageAndAge();
    });
  }

  calculationForPolicyEndDateDefaultValue(inceptionDatePolicy = this.insurancePersonDob) {
    const policyEndDateDatePicker = $(this.policyEndDateSelector);
    const policyEndDateTextInput = $('.datepicker[placeholder="Policy End Date"][type="text"]');
    const inceptionDate = new Date(inceptionDatePolicy);
    inceptionDate.setDate(inceptionDate.getDate() - 1); // Set a date is 1 day before age 99

    const date = ('0' + inceptionDate.getDate()).slice(-2); // Get day with 2 digits
    const month = ('0' + (inceptionDate.getMonth() + 1)).slice(-2); // Get month with 2 digits

    policyEndDateDatePicker.val(`${this.calculationInsurancePersonDob()}-${month}-${date}`);
    policyEndDateTextInput.val(`${date}/${month}/${this.calculationInsurancePersonDob()}`);
    this.policyEndDate = new Date(policyEndDateDatePicker.val());
  }

  calculationForPolicyEndDateCoverageAndAge() {
    if (this.policyEndDate && this.policyInceptionDate) {
      this.policyEndDateCoverage = this.policyEndDate.getFullYear() - this.policyInceptionDate.getFullYear();

      if (Number.isInteger(this.policyEndDateCoverage) && this.policyEndDateCoverage > 0) {
        $(this.policyEndCoverageSelector).html(this.policyEndDateCoverage + ' Years');
      } else if (this.policyEndDateCoverage < 0) {
        $(this.policyEndCoverageSelector).html('Completed');
      }
    }

    if (this.policyEndDate && this.insurancePersonDob) {
      this.policyEndDateAge = getAge(this.insurancePersonDob, this.policyEndDate);

      $(this.policyEndAgeSelector).html(this.policyEndDateAge);
      this.setTillAgeOnPaymentEndDateChange();
    }
  }

  setOnChangeEventForPaymentStartYear() {
    $(this.paymentStartYearSelector).on('change', ({ target }) => {
      this.policyPaymentStartYear = $(target).val();
      this.calculationForPaymentDateCoverage();
      this.calculationForPaymentStartYearAge();
    });
  }

  setOnChangeEventForPaymentEndYear() {
    $(this.paymentEndYearSelector).on('change', ({ target }) => {
      this.policyPaymentEndYear = $(target).val();
      this.calculationForPaymentDateCoverage();
      this.calculationForPaymentEndYearAge();
    });
  }

  calculationForPaymentDateCoverage() {
    if (this.policyPaymentEndYear) {
      const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
      const policyPaymentEndYear = new Date(this.policyPaymentEndYear);
      this.policyPaymentCoverage = policyPaymentEndYear.getFullYear() - currentDate.getFullYear();
      if (currentDate > policyPaymentEndYear) {
        $(this.paymentEndYearCoverageSelector).html('Completed');
      } else {
        $(this.paymentEndYearCoverageSelector).html(this.policyPaymentCoverage + ' Years');
      }
    }
  }

  calculationForPaymentStartYearAge() {
    if (this.insurancePersonDob && this.policyPaymentStartYear) {
      this.policyPaymentStartAge = this.policyPaymentStartYear - this.insurancePersonDob.getFullYear();

      $(this.paymentStartYearAgeSelector).html(this.policyPaymentStartAge);
    }
  }

  calculationForPaymentEndYearAge() {
    if (this.insurancePersonDob && this.policyPaymentEndYear) {
      const policyPaymentEndYear = new Date(this.policyPaymentEndYear);
      this.policyPaymentEndAge = policyPaymentEndYear.getFullYear() - this.insurancePersonDob.getFullYear();

      $(this.paymentEndYearAgeSelector).html(this.policyPaymentEndAge);
    }
  }

  calculationInsurancePersonDob() {
    return this.insurancePersonDob.getFullYear() + 100;
  }

  setTillAgeOnPaymentEndDateChange() {
    const benefitLength = $(this.tillAgeSelector).length;
    for (let i = 0; i < benefitLength; i++) {
      let tillAgeValue = $($(this.tillAgeSelector)[i]).val();
      let sumAssuredValue = $($(this.sumAssuredSelector)[i]).val();
      let invalidFeedback = $($(this.tillAgeSelector)[i]).parent().find('div[class="invalid-feedback"]');
      if ((tillAgeValue == '' || sumAssuredValue == '0') && invalidFeedback.length == 0) {
        $($(this.tillAgeSelector)[i]).val(this.policyEndDateAge);
      }
    }
  }
}
