import { formatCurrencyToNumber, formatCurrency } from '@/layouts/application/utils/formats';

export default class AssetEpfForm {
  constructor() {
    this.formSelector = '#asset-epf-account-form';
    this.account1InputSelector = '#epf-account-1-input';
    this.account2InputSelector = '#epf-account-2-input';
    this.account3InputSelector = '#epf-account-3-input';
    this.account55InputSelector = '#epf-account-55-input';
    this.accountEmasInputSelector = '#epf-account-emas-input';
    this.totalEpfSelector = '#total-epf-value';
  }

  setup() {
    this.addOnchangeForInputs();
  }

  addOnchangeForInputs() {
    $(this.formSelector).on(
      'input',
      `${this.account1InputSelector}, ${this.account2InputSelector}, ${this.account3InputSelector}, ${this.account55InputSelector}, ${this.accountEmasInputSelector}`,
      () => {
        const account1Value = formatCurrencyToNumber($(this.account1InputSelector).val());
        const account2Value = formatCurrencyToNumber($(this.account2InputSelector).val());
        const account3Value = formatCurrencyToNumber($(this.account3InputSelector).val());
        const account55Value = formatCurrencyToNumber($(this.account55InputSelector).val());
        const emasValue = formatCurrencyToNumber($(this.accountEmasInputSelector).val());
        const totalEpfValue = formatCurrency(account1Value + account2Value + account3Value + account55Value + emasValue);

        $(this.totalEpfSelector).html(totalEpfValue);
      },
    );
  }
}
