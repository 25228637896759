import Raicon from 'raicon';
import InsurancePolicyForm from '../../consultant/insurances/insurancePolicyForm';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import handleLockForm from '../shared/handleLockForm';
import dynamicIncludedMonth from '../../consultant/dynamicIncludedMonth';

class InsurancePolicesController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  new() {
    this.setup();
  }

  edit() {
    this.setup();
    handleLockForm(['#insurance-policy-form']);
  }

  create() {
    this.setup();
  }

  update() {
    this.setup();
  }

  show() {}

  newInsurancePolicySelection() {}

  newInsurancePolicySelectionOther() {}

  showPolicyOther() {}

  showDetailPolicyOther() {}

  setup() {
    new InsurancePolicyForm().setupForClientPortal();
    handleMoneyField();
    this.toggleDreamPlanningEvent();
    new dynamicIncludedMonth().setup('#insurance_policy_premium_interval_type');
  }

  toggleDreamPlanningEvent() {
    const $checkbox = $('#dream-planing-checkbox');
    const $recurringForm = $('.payout-option-form');

    this.toggleElementByCheckboxEvent($checkbox, $recurringForm);
  }

  toggleElementByCheckboxEvent($checkbox, $form) {
    if ($checkbox.is(':checked')) {
      $form.show();
    } else {
      $form.hide();
    }

    $checkbox.on('change', function () {
      if (this.checked) {
        $form.show();
      } else {
        $form.hide();
      }
    });
  }
}

Raicon.register('clients/insurancePolicies', InsurancePolicesController);
