import Raicon from 'raicon';
import validatePasswordCharacter from '../../consultant/shared/validatePasswordCharacter'
class UsersPasswordsController {
  constructor(){
    this.termCheckbox = '#checkbox-term';
    this.email = '#user_email';
  }

  new(){
    this.hideFooterOnClientPortal()
  }

  hideFooterOnClientPortal() {
    if (navigator.userAgent.indexOf('gonative') > -1) {
      this.hideFooterEvent(this.email);
    }
  }

  hideFooterEvent(element) {
    $(element).on('focus', () => {
      $('.footer').hide();
    });

    $(element).on('blur', () => {
      $('.footer').show();
    });
  }

  editPassword(){
    this.toggleTerm();
    this.togglePasswordAction();
    validatePasswordCharacter();
  }

  edit(){
    validatePasswordCharacter();
  }

  update(){
    validatePasswordCharacter();
  }

  updatePassword(){
    this.toggleTerm();
    this.togglePasswordAction();
    validatePasswordCharacter();
  }

  setPassword(){
    this.toggleTerm();
    this.togglePasswordAction();
    validatePasswordCharacter();
  }

  appSetPassword(){
    this.toggleTerm();
    this.togglePasswordAction();
    validatePasswordCharacter();
  }

  toggleTerm(){
    const btnContinue = '#btn-continue';
    $(this.termCheckbox).change(function(){
      if($(this).is(':checked')){
	$(btnContinue).prop('disabled', false);
      } else {
	$(btnContinue).prop('disabled', true);
      }
    })
  }

  togglePasswordAction() {
    $(".toggle-password").on('click', function () {
      const $passwordInput = $(this).parent().find(':input');
      if ($passwordInput.attr('type') === 'password') {
        $passwordInput.attr('type', 'text');
      } else {
        $passwordInput.attr('type', 'password');
      }
    });
  }
}

Raicon.register('users/passwords', UsersPasswordsController);
