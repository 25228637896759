import TaskForm from '@/controllers/consultant/tasks/formTask';
import initDoughnutChart from '@/layouts/application/utils/charts/initDoughnutChart';
import Raicon from 'raicon';

import { toggleFormCocoonCollapse, triggerCallbackCocoonLog } from '@/layouts/application/packages/cocoon';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';
import { copyExecuted } from '@/layouts/application/utils/copyExecuted';
import { add10Referees } from '../add10Referees';
import ProductForm from '../cases/productForm';
import InsurancePolicyChart from '../insurances/insurancePolicyChart';
import AppointmentForm from '../planners/appointmentForm';
import showAttentionNeededForEvents from '../shared/showEventProgressByPercentage';
import { changeStatusTask } from '../tasks/triggerChangeStatus';
import IpreciateMailingForm from './ipreciateMailingForm';
import OverviewRefereeList from './overviewRefereeList';
import GiftingCalculatorClientForm from '../clients/giftingCalculatorForm';
import OverviewUploadDocumentForm from '@/layouts/application/utils/dropzone/overviewUploadDocumentForm';
import OverlayCanvas from '../../shared/overlayCanvas';

class ConsultantOverviewsController {
  index() {
    this.refereeSetup();
    this.drawCashflowCharts();
    this.drawNetworthCharts();
    showAttentionNeededForEvents();
    this.drawPortfolioSummaryChart();
    changeStatusTask(
      '.task-complete, .task-reverse',
      '#todo-container',
      '#completed-todo-tasks',
      '#open-task-todo-overdue',
      '#open-task-todo-today',
      'task-complete',
      'task-reverse',
    );
    changeStatusTask(
      '.task-tocall-complete, .task-tocall-reverse',
      '#tocall-container',
      '#completed-tocall-tasks',
      '#open-task-tocall-overdue',
      '#open-task-tocall-today',
      'task-tocall-complete',
      'task-tocall-reverse',
    );
    this.addTriggerOpenModalEvents();
    this.setupAppointmentForm();
    this.addTriggerAddProductForLogAppointmentForm();
    this.triggerClickNextWeekTask();
    changeParamenterByTab('overview', 'active_tab');
    this.setupProductForm();
    this.getCurrentTotalLiabilities();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    this.setupAutomationSettings();
    this.setupIpreciateMailingForm();
    copyExecuted('#copy-post-fna-link', '#post-fna-link');
    copyExecuted('#copy-post-quiz-link', '#post-quiz-link');
    copyExecuted('#copy-post-quiz-career-link', '#post-quiz-career-link');
    copyExecuted('#copy-post-quiz-friendship-compatibility-link', '#post-quiz-friendship-compatibility-link');
    copyExecuted('#copy-pay-forward-link', '#pay-forward-link');
    copyExecuted('#copy-ipreciate-link', '#ipreciate-link');
    copyExecuted('#copy-finding-merlin-link', '#finding-merlin-link');
    this.setupGiftingCalculatorForm();
    this.initDropZone();
    this.setupOverlayCanvas();
  }

  setupOverlayCanvas() {
    new OverlayCanvas().setup();
  }

  initDropZone() {
    new OverviewUploadDocumentForm().setup();
  }

  setupIpreciateMailingForm() {
    new IpreciateMailingForm().setup();
  }

  setupGiftingCalculatorForm() {
    new GiftingCalculatorClientForm().setup();
  }

  triggerClickNextWeekTask() {
    $('body').on('click', '.btn-next-week', (e) => {
      const $this = $(e.target);
      $this.hide();
      $this.parent().find('.spinner-border').show();
    });
  }

  setupAutomationSettings() {
    $('body').on('modal:afterSetContent', '#open-automation-setting', () => {
      $('.disabled').attr('disabled', 'disabled');
      $('.disabled').prop('checked', true);
      $('.checked').prop('checked', true);
    });
  }

  setupProductForm() {
    $('body').on(
      'modal:afterSetContent',
      '#edit-case-overview, #edit-case-form, #open-log-appointment, #log-appointment-modal, #open-new-case, #how-is-your-call-form, #new-case-form',
      () => {
        new ProductForm();

        $('body').on('cocoon:after-insert', () => {
          new ProductForm();
        });
      },
    );
  }

  setupAppointmentForm() {
    $('body').on(
      'modal:afterSetContent',
      '#new-appointment-button, #appointment-form, #send-confirmation-email-form',
      () => {
        new AppointmentForm().setup();
      },
    );
  }

  addTriggerOpenModalEvents() {
    $('body').on('modal:afterSetContent', '#open-new-tasks, #new_task, #open-edit-task, #edit-task-form', () => {
      new TaskForm().setup();
    });

    $('body').on('modal:afterSetContent', '#log-call, #edit-log-call, #new-log-call', () => {
      triggerCallbackCocoonLog();
      toggleFormCocoonCollapse();
    });
  }

  refereeSetup() {
    new OverviewRefereeList().setup();
  }

  fcActivitySetup() {
    new OverviewFcActivities().setup();
  }

  drawCashflowCharts() {
    setTimeout(() => {
      const $cashflowInflowChart = $('#cashflow-inflow-chart');
      const $cashflowOutflowChart = $('#cashflow-outflow-chart');

      if ($cashflowInflowChart.length > 0) {
        const inflowChartData = $cashflowInflowChart.data('chart-data');
        const inflowChartColor = $cashflowInflowChart.data('chart-color');
        initDoughnutChart(
          $cashflowInflowChart,
          inflowChartData,
          inflowChartColor.slice(0, inflowChartData.length),
          '70%',
        );
      }

      if ($cashflowOutflowChart.length > 0) {
        const outflowChartData = $cashflowOutflowChart.data('chart-data');
        const outflowChartColor = $cashflowOutflowChart.data('chart-color');

        initDoughnutChart(
          $cashflowOutflowChart,
          outflowChartData,
          outflowChartColor.slice(0, outflowChartData.length),
          '70%',
        );
      }
    }, 500);
  }

  drawNetworthCharts() {
    setTimeout(() => {
      const $networthAssetChart = $('#networth-asset-chart');

      if ($networthAssetChart.length > 0) {
        const assetData = $networthAssetChart.data('chart-data');
        const assetColor = $networthAssetChart.data('chart-color');
        initDoughnutChart($networthAssetChart, assetData, assetColor.slice(0, assetData.length), '70%');
      }

      const $networthLiabilityChart = $('#networth-liability-chart');

      if ($networthLiabilityChart.length > 0) {
        const liabilityChartData = $networthLiabilityChart.data('chart-data');
        const liabilityChartColor = $networthLiabilityChart.data('chart-color');

        initDoughnutChart(
          $networthLiabilityChart,
          liabilityChartData,
          liabilityChartColor.slice(0, liabilityChartData.length),
          '70%',
        );
      }
    }, 500);
  }

  drawPortfolioSummaryChart() {
    const is_show = $('#financial-policy-sumary-show').hasClass('show');

    if (!is_show) {
      $('a[href="#financial-policy-sumary-show"]').on('shown.bs.tab', function () {
        new InsurancePolicyChart().setup();
      });
    } else {
      new InsurancePolicyChart().setup();
    }
  }

  caseActivitiesSetup() {
    new CaseActivities().setup();
  }

  addTriggerAddProductForLogAppointmentForm() {
    $('body').on('modal:afterSetContent', '#open-log-appointment, #log-appointment-modal', () => {
      triggerCallbackCocoonLog();
      toggleFormCocoonCollapse();
    });
  }

  getCurrentTotalLiabilities() {
    const getCurrent = '#get-current-liabilities';
    const totalLiabilities = '#total-liabilities';

    $('body').on('modal:afterSetContent', '#death-event, #edit-event-event_death-form', () => {
      $(getCurrent).on('click', () => {
        const liabilities = $(getCurrent).attr('data-current-liabilities');
        $(totalLiabilities).val(liabilities);
      });
    });
  }
}

Raicon.register('consultant/overviews', ConsultantOverviewsController);
