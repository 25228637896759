export default function calculate(object) {
  let startAge = Number(object['startAge']);
  let endAge = Number(object['endAge']);
  let rate = Number(object['rate']) / 100;
  let amountAnnually = Number(object['amountAnnually']);
  let yearsToInvest = Number(object['yearsToInvest']);
  let retireAge = Number(object['retireAge']);
  let retirementAmount = Number(object['retirementAmount']);
  let lumpsumTopup = object['topUp'];
  let lumpsumWithdrawal = object['withdrawal'];
  let moneyInvested = calculateMoneyInvested(startAge, amountAnnually, yearsToInvest, lumpsumTopup);
  let resultIncomeDrawdownAndNetAccountValue = calculateIncomeDrawdownAndNetAccountValue(
    retireAge,
    retirementAmount,
    lumpsumWithdrawal,
    moneyInvested,
    rate,
  );

  return {
    moneyInvested: moneyInvested,
    totalMoneyInvested: calculateTotalMoney(moneyInvested),
    incomeDrawdown: resultIncomeDrawdownAndNetAccountValue['incomeDrawdown'],
    totalWithdrawal: calculateTotalMoney(resultIncomeDrawdownAndNetAccountValue['incomeDrawdown']),
    netAccountValue: resultIncomeDrawdownAndNetAccountValue['netAccountValue'],
    retirementDrawBreakDown: calculateRetirementDrawBreakDown(
      retireAge,
      endAge,
      rate,
      resultIncomeDrawdownAndNetAccountValue['netAccountValue'][retireAge - 1],
    ),
  };
}

const calculateMoneyInvested = (startAge, amountAnnually, yearsToInvest, lumpsumTopup) => {
  let result = defaultAgeRange();

  Object.keys(result).forEach((age) => {
    if (isInRange(age, startAge, yearsToInvest)) {
      result[age] += amountAnnually;
    }
    lumpsumTopup.forEach((e) => {
      if (e.hasOwnProperty(age)) {
        result[age] += Number(e[age]);
      }
    });
  });

  return result;
};

const calculateTotalMoney = (money) => {
  let result = {};
  let sum = 0;

  for (let key = 1; key <= 100; key++) {
    sum += money[key];
    result[key] = sum;
  }

  return result;
};

const calculateRetirementDrawBreakDown = (retireAge, endAge, rate, money) => {
  let result = {};
  let retirementIncome = 0;
  let sumEndYearBalance = money;
  let firstAgeRetirementDrawBreakDown = retireAge - 1;

  for (let key = firstAgeRetirementDrawBreakDown; key <= endAge; key++) {
    if (key == firstAgeRetirementDrawBreakDown) {
      result[key] = [retirementIncome, sumEndYearBalance];
    } else {
      retirementIncome = 0.05 * sumEndYearBalance;
      sumEndYearBalance = (sumEndYearBalance - retirementIncome) * (1 + rate);

      result[key] = [retirementIncome, sumEndYearBalance];
    }
  }

  return result;
};

const calculateIncomeDrawdownAndNetAccountValue = (
  retireAge,
  retirementAmount,
  lumpsumWithdrawal,
  moneyInvested,
  rate,
) => {
  let resultIncomeDrawdown = defaultAgeRange();
  let resultNetAccountValue = defaultAgeRange();
  let result = defaultAgeRange();
  let sumValueWithdrawalWithSameKey = {};

  lumpsumWithdrawal.forEach((currentValue) => {
    let key = Object.keys(currentValue)[0];
    let value = Object.values(currentValue)[0];
    sumValueWithdrawalWithSameKey[key] = (sumValueWithdrawalWithSameKey[key] || 0) + Number(value);
  });

  Object.keys(result).forEach((age) => {
    if (age == 1) {
      if (age >= retireAge) {
        resultIncomeDrawdown[age] = retirementAmount * -1;
      }
      resultNetAccountValue[age] = Math.max(0, moneyInvested[age] * (1 + rate));
    } else {
      if (
        resultNetAccountValue[age - 1] <
        Math.abs(
          (age >= retireAge ? retirementAmount * -1 : 0) +
            (sumValueWithdrawalWithSameKey.hasOwnProperty(age) ? sumValueWithdrawalWithSameKey[age] * -1 : 0),
        )
      ) {
        resultIncomeDrawdown[age] = resultNetAccountValue[age - 1] * -1;
      } else {
        resultIncomeDrawdown[age] =
          (age >= retireAge ? retirementAmount * -1 : 0) +
          (sumValueWithdrawalWithSameKey.hasOwnProperty(age) ? sumValueWithdrawalWithSameKey[age] * -1 : 0);
      }

      resultNetAccountValue[age] = Math.max(
        0,
        (resultNetAccountValue[age - 1] + moneyInvested[age] - Math.abs(resultIncomeDrawdown[age])) * (1 + rate),
      );
    }
  });

  result = {
    incomeDrawdown: resultIncomeDrawdown,
    netAccountValue: resultNetAccountValue,
  };

  return result;
};

const isInRange = (age, startAge, yearsToInvest) => {
  return age >= startAge && age < startAge + yearsToInvest;
};

const defaultAgeRange = () => {
  const result = {};

  for (let i = 1; i <= 100; i++) {
    result[i] = 0;
  }

  return result;
};
