import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
import { changePrWhenNationalityIsSingapore } from '../shared/handleNationalitySelectChange';

export default class ContactForm {
  constructor() {}

  setup() {
    calculateAgeByInput('#dob', '.input-age');
    this.handleDisableCreateButton();
    this.updateEmailInvalidMessage();
  }

  handleDisableCreateButton() {
    $('.create-contact-btn').on('click', () => {
      $('#create-contact-btn').hide();
    })
  }

  updateEmailInvalidMessage() {
    const invalidSelector = $('div.email .invalid-feedback');
    if ($(invalidSelector).length > 0) {
      const message = $(invalidSelector).html();
      if (message.includes('is invalid')) {
        $(invalidSelector).html('Please enter your email address in format: yourname@example.com');
      } else {
        $(invalidSelector).html('Email has already been taken or User already exist and is tagged under someone else');
      }
    }
  }
}
