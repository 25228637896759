import Raicon from 'raicon';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';
import ServiceMatrix from './serviceMatrix';
import ServiceMatrixForm from './serviceMatrixForm';
import formAutomationContent from '@/controllers/admin/automations/formAutomationContent';

class ConsultantAutomationsController {
  index() {
    changeParamenterByTab('automations', 'active_tab');
    this.setupServiceMatrix();
    this.setupServiceMatrixForm();
  }

  new() {
    this.toggleStartProcess();
    this.toggleSelectTargetOptions();
  }

  edit() {
    this.toggleStartProcess();
    this.toggleSelectTargetOptions();
    new formAutomationContent().setup();
  }

  setupServiceMatrix() {
    new ServiceMatrix().setup();
  }

  setupServiceMatrixForm() {
    $('body').on('modal:afterSetContent', '#edit-service-matrix', () => {
      new ServiceMatrixForm().setup();
    });
  }

  toggleStartProcess() {
    const checkBox = $('#only-newly-contact');
    const form = $('#start-process');
    this.toggleElementByCheckboxEvent(checkBox, form);
  }

  toggleElementByCheckboxEvent($checkbox, $form) {
    if ($checkbox.is(':checked')) {
      $form.show();
    } else {
      $form.hide();
    }

    $checkbox.on('change', function () {
      if (this.checked) {
        $form.show();
      } else {
        $form.hide();
      }
    });
  }

  toggleSelectTargetOptions() {
    const selectedTargetSelector = '#select-target';
    const customOptionsSelector = '#custom-option-block';
    const categoryOptionsSelector = '#category-option-block';
    const clientSelect = '#automation_client_ids';
    const newlyCheckbox = '#newly-check-box';
    const categorySelect = '.category-select';


    if($('input[name="automation[target]"]:checked').val() == 'category') {
      $(customOptionsSelector).hide();
      $(categoryOptionsSelector).show();
      $(clientSelect).val([]);
      $(clientSelect).trigger("change");
      $(newlyCheckbox).prop('checked', false);
    }
    else {
      $(customOptionsSelector).show();
      $(categoryOptionsSelector).hide();
      $(categorySelect).prop('checked', false);
    }

    $(selectedTargetSelector).on('change', (e) => {
      if(e.target.value == 'category') {
        $(customOptionsSelector).hide();
        $(categoryOptionsSelector).show();
        $(clientSelect).val([]);
        $(clientSelect).trigger("change");
        $(newlyCheckbox).prop('checked', false);
      }
      else {
        $(customOptionsSelector).show();
        $(categoryOptionsSelector).hide();
        $(categorySelect).prop('checked', false);
      }
    })
  }
}

Raicon.register('consultant/automations', ConsultantAutomationsController);
