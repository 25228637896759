import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

class AdminGoalsController {
  constructor() {
    this.weeksDropdown = '#weeks-dropdown'
    this.dateFilter = '';
  }

  index() {
    this.initDropZone();

    $(this.weeksDropdown).on('change', ({ target }) => {
      this.dateFilter = target.value
    })

    $('#export-goals-button').on('click', () => {
      const exportGoalsButton = $('#export-goals-button');
      exportGoalsButton[0].href = `/admin/goals/export_goals?filter=${this.dateFilter}`
    })
  }

  initDropZone() {
    new DropZoneForm({
      isFailedRecordsLink: true,
      formSelector: '#import-goals-form',
      submitBtnSelector: '#submit-import-goals',
      fileParamName: 'goal[import_goal_file]',
      importResultFormSelector: '#import-goals-result-form',
      importBtnSelector: [
        '#import-goals-button',
        '#import-goals-form',
        '#import-goals-result-form'
      ]
    }).setup();
  }

}

Raicon.register('admin/goals', AdminGoalsController);
