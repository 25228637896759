import Raicon from 'raicon';
import { formatMoneyToNumber } from '@/layouts/application/utils/formats';

class FinancialCalculatorsController {
  constructor() {
    this.clearButton = '#financial-clear-button';
    this.calculateFinancial();
    this.clearFinancialInput();
    this.formatInputValueFinancial();
  }

  formatInputValueFinancial() {
    $(document).on('input', '#financial_calculator_pv_value, #financial_calculator_fv_value, #financial_calculator_pmt_value, #financial_calculator_rate_value, #financial_calculator_nper_value', function() {
      var inputValue = $(this).val();
      inputValue = inputValue.replace(/[^0-9\-,.\s]/g, '');
      if ((inputValue.match(/-/g) || []).length > 1) {
        inputValue = inputValue.replace(/-/g, '');
      }
      if ((inputValue.match(/,/g) || []).length > 2) {
        inputValue = inputValue.replace(/,/g, '');
      }
      if ((inputValue.match(/\./g) || []).length > 1) {
        inputValue = inputValue.slice(0, inputValue.lastIndexOf('.'));
      }
      $(this).val(inputValue);
    })

    $(document).on('change', '#financial_calculator_pv_value, #financial_calculator_fv_value, #financial_calculator_pmt_value', function() {
      var inputValue = $(this).val();

      if (inputValue == 'nan') {
        return;
      }

      var parts = inputValue.split('.');
      var inputChange = parts[0].replaceAll(',', '');
      var integerPart = inputChange.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      var formattedValue = integerPart + (parts[1] ? '.' + parts[1] : '');
      $(this).val(formattedValue);
    })
  }

  calculateFinancial() {
    $(document).on('click', '#financial-pv-button, #financial-fv-button, #financial-pmt-button, #financial-rate-button, #financial-nper-button', function(event) {
      var clickedId = this.id;
      var clickedType = $(`#${this.id}`).text();

      var pvValue = parseFloat(formatMoneyToNumber($('#financial_calculator_pv_value').val()));
      var fvValue = parseFloat(formatMoneyToNumber($('#financial_calculator_fv_value').val()));
      var pmtValue = parseFloat(formatMoneyToNumber($('#financial_calculator_pmt_value').val()));
      var rateValue = parseFloat(formatMoneyToNumber($('#financial_calculator_rate_value').val()));
      var nperValue = parseFloat(formatMoneyToNumber($('#financial_calculator_nper_value').val()));

      if ($(event.target).is('#financial-pv-button, #financial-fv-button, #financial-pmt-button, #financial-rate-button') && nperValue < 0) {
        alert('Please enter non-negative periods!');
        return;
      }

      var requestData = {
        pv_value: pvValue,
        fv_value: fvValue,
        pmt_value: pmtValue,
        rate_value: rateValue,
        nper_value: nperValue,
        type: clickedType
      };

      $.ajax({
        url: Routes.financial_calculator_financial_calculators_path(),
        type: 'GET',
        data: requestData,
        success: function(data) {
          var calResult = data.toFixed(2) || 0;
          if (clickedType.toLowerCase() == 'rate') {
            calResult = data.toFixed(2) + ' %' || 0
            if (data == '92233720368547760') {
              calResult = 'nan'
            }
          }

          if (calResult == '-0.00') {
            calResult = '0.00'
          }

          const outerDiv = $('#' + clickedId).closest('.row');
          outerDiv.find('input').val(calResult).change();
        },
        error: function(error) {
          console.log('Invalid input. Please enter valid numbers.')
        }
      });
    });
  }

  clearFinancialInput() {
    $(document).on('click', '#financial-clear-button, #close-calculate, .modal-close-btn', () => {
      $('#financial-calculator-form input.form-control').val('');
    });
  }
}

Raicon.register('financialCalculators', FinancialCalculatorsController);
