import { getAge, convertYearByAge } from '@/layouts/application/utils/calculateAgeByInput';
import { debounce } from '@/helpers/index';
export default class DreamChart {
  constructor($chartElementContainer) {
    this.chartElementContainer = $chartElementContainer;
    this.presentDreamCheckboxSelector = '.present-dream-checkbox';
    this.dreamToggleItem = '.dream--toggle__item';
    this.retirementMonthlySurplus = '.retirement-surplus-section'
    this.dreamCardWrapperSelector = '.dream-card-wrapper';
    this.simulatedInvestmentActivateCheckboxSelector = '.activate-si-checkbox';
    this.clientId = $('body').data('client-id');
    this.presentingDreamIds = [];
    this.clientDob = $chartElementContainer.data('chart-data').client_dob;

    this.viewTypeSelector = '#view-type-selection';
    this.viewTypeSelector2 = '#view-type-selection-2';
    this.yearRangeSelector = '.year-range-navigator';
  }

  currentYear = new Date().getFullYear();
  currentAge = undefined;
  clientAge = undefined;
  clientYear = undefined;
  currentRangeStartYear = undefined;
  currentRangeStartAge = undefined;
  xAxisData = [];
  validChart = true;

  setupChartRangeYear() {
    this.calculateClientAgeAndYear();
    this.setRangeAge(this.currentAge)
    this.addEventForRangeBackYear();
    this.addEventForRangeForwardYear();

    const rangeStartAge = Number.parseInt($(this.rangeStartYearSelector).html());
    this.toggleBackYearIcon(rangeStartAge);
  }

  calculateClientAgeAndYear() {
    this.clientAge = getAge(this.clientDob);
    this.clientYear = convertYearByAge(this.clientDob);
    this.currentAge = this.calculateCurentAgeByYear(this.currentYear);
  }

  addEventForPresentDreamCheckbox() {
    $(this.presentDreamCheckboxSelector).on('change', ({ target }) => {
      this.addCheckedClassToDreamCard(target);

      const dream_id = $(target).val();
      const is_show = $(target).is(':checked');
      const params = { client_id: this.clientId, dream_id: dream_id, is_show: is_show };
      $.post(Routes.update_is_show_consultant_client_dreams_path(params)).done(() => {
        this.presentDataToChart();

        $.get(Routes.retirement_monthly_surplus_consultant_client_dreams_path({client_id: this.clientId})).done(data => {
          $(this.retirementMonthlySurplus).html(data)
        });
      });
    });
  }

  addEvenForDreamToggleItem() {
    $(this.dreamToggleItem).on('click', ({ currentTarget }) => {
      const dreamToggleItem = $(currentTarget);
      const isShow = dreamToggleItem.hasClass('disabled');
      const DreamId = dreamToggleItem.data('dream-id');

      // Update checkbox status when click on dream toggle item
      const checkBoxDreamSelector = document.getElementById(`dream-card-${DreamId}`)
      checkBoxDreamSelector.checked = !checkBoxDreamSelector.checked;
      this.addCheckedClassToDreamCard(checkBoxDreamSelector);

      $.post(
        Routes.update_is_show_consultant_client_dreams_path({
          is_show: isShow,
          dream_id: DreamId,
          client_id: this.clientId
        }))
        .done(() => {
          this.presentDataToChart();

          $.get(Routes.retirement_monthly_surplus_consultant_client_dreams_path({client_id: this.clientId})).done(data => {
            $(this.retirementMonthlySurplus).html(data)
          });
        });
    })
  }

  getAllPresentingDreamIds() {
    const dreamShowCheckboxCollection = [...$(this.presentDreamCheckboxSelector)];
    const checkedDreamIds = [];

    dreamShowCheckboxCollection.forEach(element => {
      if (element.checked) {
        checkedDreamIds.push(element.value);
      }
    });

    return checkedDreamIds;
  }

  setRangeAge(rangeStartAge) {
    const startAge = Number.parseInt(rangeStartAge);

    if (startAge) {
      this.currentRangeStartYear = startAge + this.clientYear;
      if (this.currentRangeStartYear != new Date().getFullYear()) {
        this.currentRangeStartYear = new Date().getFullYear();
      }
      this.currentRangeStartAge = startAge;
      $(this.rangeStartYearSelector).html(startAge)
      $(this.rangeEndYearSelector).html(startAge + 9)
    }
  }

  setOnChangeViewType() {
    const $viewTypeSelection = $(this.viewTypeSelector);

    if ($viewTypeSelection.val() == '2') {
      this.isLifeTimeView = true;
      this.hideYearRange();
    } else {
      this.isLifeTimeView = false;
      this.showYearRange();
    }

    $viewTypeSelection.on('change', ({ target: { value: id } }) => {
      if (id == 2) {
        this.isLifeTimeView = true;
        this.hideYearRange();
        this.setRangeAge(this.currentAge)
      } else {
        this.isLifeTimeView = false;
        this.showYearRange();
        $(this.rangeBackYearSelector).hide();
      }

      this.refreshChartDataWhenChangingRangeYear();
    })
  }

  setOnChangeViewTypeTwo() {
    const $viewTypeSelection = $(this.viewTypeSelector2);

    if ($viewTypeSelection.val() == '2') {
      this.isLifeTimeView = true;
      this.hideYearRange();
    } else {
      this.isLifeTimeView = false;
      this.showYearRange();
    }

    $viewTypeSelection.on('change', ({ target: { value: id } }) => {
      if (id == 2) {
        this.isLifeTimeView = true;
        this.hideYearRange();
        this.setRangeAge(this.currentAge)
      } else {
        this.isLifeTimeView = false;
        this.showYearRange();
        $(this.rangeBackYearSelector).hide();
      }

      this.refreshChartDataWhenChangingRangeYear();
    })

  }

  hideYearRange() {
    $(this.yearRangeSelector).hide();
  }

  showYearRange() {
    $(this.yearRangeSelector).show();
  }

  addEventForRangeBackYear() {
    $(this.rangeBackYearSelector).on('click', debounce(() => {
      const rangeStartAge = Number.parseInt($(this.rangeStartYearSelector).html());

      if (rangeStartAge) {
        this.currentRangeStartAge = rangeStartAge;
        this.setRangeAge(rangeStartAge - this.rangeAgeConstant());
        this.refreshChartDataWhenChangingRangeYear();
        this.toggleBackYearIcon(rangeStartAge - 10);
      }
    }, 250))
  }

  addEventForRangeForwardYear() {
    $(this.rangeForwardYearSelector).on('click', debounce(() => {
      const rangeStartAge = Number.parseInt($(this.rangeStartYearSelector).html());

      if (rangeStartAge) {
        this.currentRangeStartAge = rangeStartAge;
        this.setRangeAge(rangeStartAge + this.rangeAgeConstant());
        this.refreshChartDataWhenChangingRangeYear();
        this.toggleBackYearIcon(rangeStartAge + 10);
      }
    }, 250))
  }

  refreshChartDataWhenChangingRangeYear() {
    this.updateXAxisData();
    this.presentDataToChart();
  }

  updateXAxisData() {
    this.buildXAxisData();
    this.chart.data.labels = this.xAxisData;
    this.chart.update();
  }

  addCheckedClassToDreamCard(checkbox) {
    const $presentCheckbox = $(checkbox);
    const checkboxId = $presentCheckbox.val();
    const dreamCardSelector = `#dream-card-${checkboxId}`;
    const $dreamCardWrapper = $(dreamCardSelector).closest(this.dreamCardWrapperSelector);
    const $dreamToggleSelector = $(this.dreamToggleItem).filter(`[data-dream-id="${checkboxId}"]`);

    if ($presentCheckbox.is(':checked')) {
      $dreamCardWrapper.addClass('checked');
      $dreamToggleSelector.removeClass('disabled');
      $dreamCardWrapper.find('.card-icon').removeClass('opacity-3');
    } else {
      $dreamCardWrapper.removeClass('checked');
      $dreamToggleSelector.addClass('disabled');
      $dreamCardWrapper.find('.card-icon').addClass('opacity-3');
    }
  };

  addEventForActivateSimulatedInvestmentCheckbox() {
    $(this.simulatedInvestmentActivateCheckboxSelector).on('change', ({ target }) => {
      this.presentDataToChart();
    })
  }

  getAllSimulatedInvestmentIds() {
    const simulatedInvestmentActivatedCheckboxCollection = [...$(this.simulatedInvestmentActivateCheckboxSelector)];
    const investmentIds = [];

    simulatedInvestmentActivatedCheckboxCollection.forEach(element => {
      if (element.checked) {
        investmentIds.push(element.value);
      }
    });

    return investmentIds;
  }

  calculateRangeAge() {
    let rangeAge = this.rangeAgeConstant();

    if (this.isLifeTimeView) {
      const clientBirthdayYear = this.clientDobYear();
      rangeAge = Number(clientBirthdayYear) + 99 - Number(this.currentYear);
    }

    return rangeAge
  }

  buildXAxisData() {
    this.xAxisData = [...Array(this.calculateRangeAge()).keys()].map(e => {
      return (e + this.currentRangeStartAge);
    });
  }

  pointImageSource() {
    const pointImage = new Image();
    pointImage.src = $('#chart-point-image').attr('src');

    return pointImage;
  }

  rangeAgeConstant() {
    return 10;
  }

  clientDobYear() {
    return new Date(this.clientDob).getFullYear();
  }

  toggleBackYearIcon(firstAge) {
    if (firstAge > this.currentAge) {
      $(this.rangeBackYearSelector).show();
    }
    else {
      $(this.rangeBackYearSelector).hide();
    }
  }

  calculateCurentAgeByYear(year) {
    return year - this.currentYear + this.clientAge;
  }

  showLoadingDreamChart() {
    const chartContainer = $(this.chartElementContainer).closest('.chart-container');
    chartContainer.addClass('chart-loading');
    chartContainer.find('.dream--toggle__list').addClass('d-none')
  }

  hideLoadingDreamChart() {
    const chartContainer = $(this.chartElementContainer).closest('.chart-container');
    chartContainer.removeClass('chart-loading');
    chartContainer.find('.dream--toggle__list').removeClass('d-none');
  }
}
