import Raicon from 'raicon';
import Chart from 'chart.js/auto';

import {
  WEEKLY_LOGIN_COLOR,
  WEEKLY_NEW_LEADS_COLOR,
  WEEKLY_NEW_CONTACTS_COLOR,
} from '@/layouts/application/utils/charts/chartColors';

class AdminDashboardController {
  index() {
    this.drawPieChartForLeads();
    this.drawChart('#weekly-login-chart', WEEKLY_LOGIN_COLOR);
    this.drawChart('#weekly-new-leads-chart', WEEKLY_NEW_LEADS_COLOR);
    this.drawChart('#weekly-new-contacts-chart', WEEKLY_NEW_CONTACTS_COLOR);
    this.drawLineChartForProductionTrend();
  }

  drawChart(element, chartColor) {
    const chartElement = $(element);
    const chartData = chartElement.data('chart-data');
    const labels = chartData.map((data) => data.date);

    const datasets = [
      {
        data: chartData.map((data) => data.total_number),
        fill: false,
        borderColor: chartColor,
        tension: 0.1,
      },
    ];

    return new Chart(chartElement, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          y: {
            grid: {
              color: function (context) {
                if (context.tick.value == 0) {
                  return '#000000';
                } else {
                  return Chart.defaults.borderColor;
                }
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: '#EEEEEE',
            displayColors: false,
            padding: 15,
            titleColor: '#303030',
            titleFont: { weight: 'bold', size: 14 },
            bodyColor: '#303030',
            callbacks: {
              label: function (tooltipItem) {
                return `Total number: ${tooltipItem.raw}`;
              },
            },
          },
        },
      },
    });
  }

  drawPieChartForLeads() {
    const chartData = $('#chart-data');
    const colors = chartData.data('chart-color');
    const legend = chartData.data('chart-legend');
    const canvasAllTime = $('#chart--info__allTime');
    const canvasLastWeek = $('#chart--info__lastWeek');

    this.initChartPie(canvasAllTime, 'All Time', colors, legend);
    this.initChartPie(canvasLastWeek, 'Last Week', colors, legend);
  }

  drawLineChartForProductionTrend() {
    const chartElement = $('#production-trend');
    const chartData = chartElement.data('chart-data');
    const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'MTD',
          data: chartData.mtd_values,
          borderColor: 'blue',
          backgroundColor: 'blue',
        },
        {
          label: 'YTD',
          data: chartData.ytd_values,
          borderColor: 'orange',
          backgroundColor: 'orange',
        },
      ],
    };

    return new Chart($('#production-trend'), {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            // display: false,
          },
        },
      },
    });
  }

  initChartPie(element, chartTitle, colors, legend) {
    const rawData = element.data('chart');
    const chartData = Object.values(rawData);

    return new Chart(element, {
      type: 'pie',
      data: {
        datasets: [
          {
            backgroundColor: colors,
            data: chartData,
          },
        ],
      },
      options: {
        layout: { padding: 20 },
        zoomOutPercentage: 30,
        responsive: false,
        plugins: {
          title: {
            display: true,
            text: chartTitle,
            fontSize: 15,
            fontColor: '#333',
            fontStyle: 'bold',
            position: 'bottom',
            textAlign: 'start',
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const label = legend[context.dataIndex];
                const data = context.dataset.data;
                const total = data.reduce((acc, value) => acc + value);
                const currentValue = data[context.dataIndex];
                const percentage = Math.round((currentValue / total) * 100);
                return `${label} : ${context.raw} (${percentage}%)`;
              },
            },
          },
        },
      },
    });
  }
}

Raicon.register('admin/dashboard', AdminDashboardController);
