import { getAge } from '@/layouts/application/utils/calculateAgeByInput';
import { autoFormatMoneyFields, removeCommasBeforeSubmitForm } from '@/layouts/application/utils/handleMoneyField';

export default class SimulatedInvestmentForm {
  constructor(dreamChartInstance) {
    this.simulatedInvestmentFormSelector = '.si-form';
    this.commonPremiumAmountSelector = '.si-premium-amount';
    this.commonPremiumAmountIntervalSelector = '.si-premium-amount-interval';
    this.commonLumpSumAmountSelector = '.si-lump-sum-amount';
    this.commonInterestRateSelector = '.si-interest-rate';
    this.commonAgeFromSelector = '.si-age-from';
    this.commonAgeToSelector = '.si-age-to';
    this.commonActivatedSelector = '.si-activated-input'
    this.mainDreamIndexContentSelector = '.main-content';
    this.ageFromYearSelector = '.age-from-year';
    this.ageToYearSelector = '.age-to-year';
    this.clientDob = new Date($('.main-content').data('client-dob'));
    this.retirementNoticeInforSectionSelector = '.retirement-notice-info-section';
    this.errorsAgeFromSelector = '.errors-age-from';
    this.dreamChartInstance = dreamChartInstance;
    this.currencyTriggerFields = '.currency-trigger-field';
  }

  setup() {
    this.setSaveTriggerForSimulatedForm();
    this.setAgeYearCalculateEvent();
    this.checkAgeFromError();
    this.handleFormatMoneyFields();
    this.changeTriggerForSafari();
  }

  setSaveTriggerForSimulatedForm() {
    $(this.simulatedInvestmentFormSelector).on('change',
      `${this.simulatedInvestmentFormSelector},
      ${this.commonPremiumAmountSelector},
      ${this.commonPremiumAmountIntervalSelector},
      ${this.commonInterestRateSelector},
      ${this.commonAgeFromSelector},
      ${this.commonAgeToSelector},
      ${this.commonActivatedSelector},
      ${this.commonLumpSumAmountSelector}
    `, (e) => {
      const disabledForm = $(e.target).closest('.disabled-si-form');
      if(disabledForm.length) {
        return;
      }

      const $form = $(e.target).parents('form');
      const siIndex = $form.data('si-index');
      const isChangeFromCheckBox = e.target.type == 'checkbox'
      removeCommasBeforeSubmitForm(e.target.closest('form.simple_form'));

      $.ajax({
        url: $form.attr('action') + `?si_index=${siIndex}`,
        type: 'PUT',
        data: $form.serialize(),
        success: (data) => {
          $form.html(data.simulated_investment_form);
          $(this.retirementNoticeInforSectionSelector).replaceWith(data.retirement_notice_info_form);
          this.handleRerenderChart($form, isChangeFromCheckBox);
          this.setAgeYearCalculateEvent();
          this.checkAgeFromError();
          this.changeTriggerForSafari();
        }
      });
    }
    )
  }

  checkAgeFromError() {
    const age = getAge(this.clientDob);
    $(this.simulatedInvestmentFormSelector).toArray().forEach(form => {
      const $form = $(form)
      const ageFrom = parseInt($form.find(this.commonAgeFromSelector).val());

      if (ageFrom < age) {
        $form.find(this.errorsAgeFromSelector).removeClass('d-none');
      } else {
        $form.find(this.errorsAgeFromSelector).addClass('d-none');
      }
    });
  }

  handleRerenderChart($form, isChangeFromCheckBox) {
    if (isChangeFromCheckBox) {
      this.dreamChartInstance.presentDataToChart();
      return true;
    }

    const $simulatedInvestmentCheckbox = $($form.find(this.commonActivatedSelector))[0];

    if ($simulatedInvestmentCheckbox.checked) {
      this.dreamChartInstance.presentDataToChart();
    }
  }

  setAgeYearCalculateEvent() {
    const currentYear = new Date().getFullYear();
    const clientAge = getAge(this.clientDob)

    const setYearForAgeTarget = (targetElementSelector, ageValue) => {
      const inputedAge = parseInt(ageValue);
      const yearResult = currentYear + (inputedAge - clientAge)
      $(targetElementSelector).html(yearResult)
    };

    $(this.commonAgeFromSelector).each((_index, ele) => {
      const $input = $(ele)
      const existingAgeFromValue = parseInt($input.val())
      const tartgetYearElement = $input.closest('form').find(this.ageFromYearSelector)
      setYearForAgeTarget(tartgetYearElement, existingAgeFromValue)
    })

    $(this.commonAgeToSelector).each((_index, ele) => {
      const $input = $(ele)
      const existingAgeToValue = parseInt($input.val())
      const tartgetYearElement = $input.closest('form').find(this.ageToYearSelector)
      setYearForAgeTarget(tartgetYearElement, existingAgeToValue)
    })

    $(this.commonAgeFromSelector).on('input', ({ target }) => {
      const tartgetYearElement = $(target).closest('form').find(this.ageFromYearSelector)
      setYearForAgeTarget(tartgetYearElement, target.value)
    })

    $(this.commonAgeToSelector).on('input', ({ target }) => {
      const tartgetYearElement = $(target).closest('form').find(this.ageToYearSelector)
      setYearForAgeTarget(tartgetYearElement, target.value)
    })
  }

  handleFormatMoneyFields() {
    $(this.simulatedInvestmentFormSelector).on('keyup',
      `${this.simulatedInvestmentFormSelector},
      ${this.commonPremiumAmountSelector},
      ${this.commonPremiumAmountIntervalSelector},
      ${this.commonAgeFromSelector},
      ${this.commonAgeToSelector},
      ${this.commonActivatedSelector},
      ${this.commonLumpSumAmountSelector}
    `, (e) => {
      autoFormatMoneyFields(e.target);
    });
  }

  changeTriggerForSafari() {
    $('.currency-trigger-field').on('focus focusout', (e) => {
      if(e.type == 'focus') {
        $(e.target).attr('old-value', $(e.target).val());
      } else {
        if($(e.target).val() != $(e.target).attr('old-value')) {
          $(e.target).attr('old-value', $(e.target).val());
          $(e.target).trigger('change');
        }
      }
    })
  }
}
