import Raicon from 'raicon';
import '@/libs/jquery.reflection';
import '@/libs/jquery.cloud9carousel';
import Instagram from './instagram';
import { activeByCheckBox } from '@/layouts/application/utils/activeButtonWithCondition'
class LandingController {
  constructor() {
    this.popupWrapperSelector = '.landing-popup-wrapper';
    this.quizPopupSelector = '.quiz-popup';
    this.closeQuizPopupButtonSelector = '#close-quiz-popup-btn';
    this.openGameCardsButtonSelector = '.get-game-cards';
    this.gameCardsPopupSelector = '.game-cards-popup';
    this.closeGameCardsPopupButtonSelector = '#close-game-cards-btn';
    this.gameCardsFormSelector = '.game-cards-form';
    this.submitGameCardsSelector = '#submit-game-card-form';
  }

  index() {
    this.toggleMobileMenu();
    this.validateConnectForm();
    this.set3dCarousel();
    // this.handleQuizPopup();
    activeByCheckBox('.submit-landing-page')
    this.handleGameCardsPopup();
    this.handleSubmitGameCardsForm();
    new Instagram().setup();
  }

  securityPage() {}

  set3dCarousel() {
    $(function () {
      const $carousel = $('#feature-highlights-carousel');

      $carousel.Cloud9Carousel({
        yPos: 42,
        yRadius: 48,
        mirrorOptions: {
          gap: 48,
          height: 0.2,
          opacity: 0.4,
        },
        buttonLeft: $('.nav > .left'),
        buttonRight: $('.nav > .right'),
        autoPlay: -1,
        bringToFront: true,
        frontItemClass: 'active',
        farScale: 0.3,
        onRendered: function (item) {
          // fix opacity for another items
          const maxLength = item.items.length,
            twoSlideActiveClass = 'two-sides-active',
            $cloud9Items = $carousel.find('.cloud9-item');

          $cloud9Items.removeClass(twoSlideActiveClass);
          for (let i = 0; i < maxLength; i++) {
            if ($cloud9Items.eq(i).hasClass('active')) {
              const prevIndex = i > 0 ? i - 1 : maxLength - 1;
              const nextIndex = i < maxLength - 1 ? i + 1 : 0;
              $cloud9Items.eq(prevIndex).addClass(twoSlideActiveClass);
              $cloud9Items.eq(nextIndex).addClass(twoSlideActiveClass);
            }
          }
        },
        onLoaded: function () {
          $carousel
            .css({
              visibility: 'visible',
              display: 'none',
            })
            .fadeIn(1500);
        },
      });
    });
  }

  toggleMobileMenu() {
    const $menuButton = $('#menu-button');
    const $menuOptions = $('#menu-options');

    $($menuButton).on('click', (e) => {
      setTimeout(() => {
        if ($menuOptions.is(':visible')) {
          $($menuButton).removeClass('show');
          $menuOptions.hide();
        } else {
          $($menuButton).addClass('show');
          $menuOptions.show();
        }
      }, 200);
    });
  }

  validateConnectForm() {
    const forms = document.getElementsByClassName('merlin-question-form');
    const validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener(
        'submit',
        function (event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          } else {
            $('.submit-success-message').removeClass('d-none');
            setTimeout(() => {
              $('.submit-success-message').addClass('d-none');
            }, 10000);
          }
          form.classList.add('was-validated');
        },
        false,
      );
    });
  }

  handleQuizPopup() {
    $(document).on('mouseup', (e) => {
      if (this.isAncestor(this.closeQuizPopupButtonSelector, e.target)) {
        $(this.popupWrapperSelector).hide();
      }
    });
  }

  handleGameCardsPopup() {
    $(this.openGameCardsButtonSelector).on('click', () => {
      $(this.popupWrapperSelector).removeClass('d-none');
      $(this.gameCardsPopupSelector).show();
    });

    $(document).on('mouseup', (e) => {
      if (this.isAncestor(this.closeGameCardsPopupButtonSelector, e.target)) {
        $(this.popupWrapperSelector).addClass('d-none');
      }
    });
  }

  handleSubmitGameCardsForm() {
    $(this.submitGameCardsSelector).on('click', () => {
      const data = $(this.gameCardsFormSelector).serialize();

      $.ajax({
        url: Routes.game_cards_path(),
        type: 'POST',
        dataType: 'json',
        data: data,
        success: (res) => {
          if (res['success']) {
            $('.thank-you-message').show();
          }
          $('#name-input').css('background-color', 'rgba(240, 248, 255, 0.75)');
          $('#mobile-input').css('background-color', 'rgba(240, 248, 255, 0.75)');
          $('#email-input').css('background-color', 'rgba(240, 248, 255, 0.75)');

          if (res['errors'].includes('name')) {
            $('#name-input').css('background-color', 'rgba(239, 186, 130, .75)');
          }
          if (res['errors'].includes('mobile_number')) {
            $('#mobile-input').css('background-color', 'rgba(239, 186, 130, .75)');
          }
          if (res['errors'].includes('email')) {
            $('#email-input').css('background-color', 'rgba(239, 186, 130, .75)');
          }
        },
      });
    });
  }

  isAncestor(elementSelector, elementTarget) {
    return $(elementSelector).is(elementTarget) || $(elementSelector).has(elementTarget).length > 0;
  }
}

Raicon.register('landing', LandingController);
