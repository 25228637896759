import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';
import ImageCropper from '../../shared/imageCropper'
import {
  emailFormatValidator,
  presentValidator,
  setupValidatorBaseOnCountryCode,
} from '@/layouts/application/utils/validators';
import Raicon from 'raicon';

class TestimonialController {
  constructor() {
    this.associate_name = '#testimonial-associate-name';
    this.submitButton = '#submit-button';
    this.imageSelector = '.image-file-input';
    this.imagePreviewSelector = '.preview-input-file';
    this.formSelector = '#testimonial-form';
    this.checkboxSelector = '#checkbox-term';
    this.testimonialSubmitButton = '#testimonial-submit-btn';
    this.firstSubmitAttempt = true;

    this.requiredFields = [
      {
        selector: '#testimonial_name',
        validators: [presentValidator],
      },
      {
        selector: '#testimonial_title',
        validators: [presentValidator],
      },
      {
        selector: '#testimonial_gender',
        validators: [presentValidator],
      },
      {
        selector: '#testimonial_feedback',
        validators: [presentValidator],
      },
      {
        selector: '#testimonial_associate_name',
        validators: [presentValidator],
      },
      {
        selector: '#testimonial_email',
        validators: [presentValidator, emailFormatValidator],
      },
      {
        selector: '#mobile-number-input',
        validators: [presentValidator],
      },
      {
        selector: '#testimonial_other_associate_name',
        validators: [presentValidator],
      },
    ];
  }

  setup() {
    new ImageCropper('#profile-image-input', '#profile-pic-preview', '#profile-pic-signed-blob').setup();
    this.handleAssociateName();
    this.initDropzone();
    this.handleImageChange();
    this.handleTermAndConditionsCheckbox();
    this.handleFormSubmission();
    this.addInputEventListeners();
  }

  handleImageChange() {
    $(document).on('change', this.imageSelector, (event) => this.handleImageProfile(event.target));
  }

  handleImageProfile(input) {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        $('.preview-input-file').attr('src', e.target.result).removeClass('d-none');
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  handleAssociateName() {
    const url = Routes.get_list_of_consultants_testimonials_path;

    $('.testimonial-associate-name').select2({
      ajax: {
        url,
        dataType: 'json',
        delay: 500,
        data: (params) => ({ search: { q: params.term } }),
        processResults: (data) => ({
          results: $.map(data, (item) => ({ id: item.id, text: item.full_name })),
        }),
        cache: true,
      },
      minimumInputLength: 1,
      maximumSelectionLength: 1,
    });

    $('.testimonial-associate-name').on('change.select2', function (e) {
      const selectedVal = $('.testimonial-associate-name').find(':selected').val();
      const adminOwnedFCids = $(e.currentTarget).data('admin-owned-fc-ids');

      if(adminOwnedFCids.includes(parseInt(selectedVal))) {
        $('.testimonial-other-associate-name').removeClass('d-none');
        $('#testimonial_other_associate_name').removeAttr('disabled');
      } else {
        $('.testimonial-other-associate-name').addClass('d-none');
        $('#testimonial_other_associate_name').attr('disabled', true);
      }
    });
  }

  handleTermAndConditionsCheckbox() {
    this.activeByCheckBox(this.testimonialSubmitButton, this.checkboxSelector);
    this.updateSubmitButtonState();
  }

  activeByCheckBox(btn, checkbox) {
    const updateButtonState = () => {
      const isChecked = $(checkbox).is(':checked');
      $(btn).prop('disabled', !isChecked);
    };

    $(checkbox).on('change', updateButtonState);
    updateButtonState();
  }

  handleFormSubmission() {
    $(this.formSelector).on('submit', (event) => {
      $(this.testimonialSubmitButton).prop('disabled', false);
      if (!this.validate()) {
        event.preventDefault();
        if (this.firstSubmitAttempt) {
          $(this.checkboxSelector).prop('checked', false);
          this.firstSubmitAttempt = false;
        }
      }
    });
  }

  create() {
    this.setup();
  }

  write() {
    this.setup();
  }

  validate() {
    this.hideEmailError();
    let currentStepValidationPassed = true;

    const phoneNumberValidate = this.requiredFields.find(
      (requiredField) => requiredField.selector === '#mobile-number-input'
    );
    setupValidatorBaseOnCountryCode(phoneNumberValidate, '#country-code-input');

    this.requiredFields.forEach((fieldInfo) => {
      if (!this.validateField(fieldInfo)) {
        currentStepValidationPassed = false;
      }
    });

    this.updateSubmitButtonState();
    return currentStepValidationPassed;
  }

  updateSubmitButtonState() {
    const isChecked = $(this.checkboxSelector).is(':checked');
    $(this.testimonialSubmitButton).prop('disabled', !isChecked);
  }

  initDropzone() {
    const dropZone = new DropZoneForm({
      uploadMultiple: false,
      isValidateUpload: false,
      formSelector: this.formSelector,
      submitBtnSelector: this.submitButton,
      fileParamName: 'testimonial[profile_image]',
      acceptedFiles: ['image/png', '.jpg', '.jpeg'],
      autoDiscover: false,
    });

    dropZone.setupDropZone();
    dropZone.setupEventDropZone();
  }

  hideEmailError() {
    $(this.emailExistedError).hide();
  }

  addInputEventListeners() {
    this.requiredFields.forEach((fieldInfo) => {
      const fieldInput = $(fieldInfo.selector);
      fieldInput.on('blur change', () => this.validateField(fieldInfo));
    });
  }

  validateField(fieldInfo) {
    const fieldInput = $(fieldInfo.selector);
    let isValidField = true;

    fieldInfo.validators.forEach((validatorMethod) => {
      if(!fieldInput.prop('disabled')) {
        isValidField = validatorMethod.bind(this)(fieldInput.val());
        if (isValidField) {
          fieldInput.addClass('is-valid').removeClass('is-invalid').parent().parent().find('.invalid-feedback').hide();
        } else {
          fieldInput.addClass('is-invalid').removeClass('is-valid').parent().parent().find('.invalid-feedback').show();
        }
      }
    });

    return isValidField;
  }
}

Raicon.register('testimonials', TestimonialController);
