import Raicon from 'raicon';
import InvestmentIllustratorPublicChart from './investmentIllustratorPublicChart';

class PublicInvestmentIllustratorsController {
  constructor() {
    this.investmentIllustratorChartContentSelector = '#public-investment-illustrator-chart-container';

    this.setup();

    this.handleSetupInput();
  }

  index() {
    new InvestmentIllustratorPublicChart($(this.investmentIllustratorChartContentSelector)).setup();
  }

  setup() {
    let min = 25;
    let max = 80;
    this.rangeChart();
    this.activeTabData();
    this.rangeSliderAge(min, max);

    setTimeout(() => {
      this.updateUI(min, max);
    }, 1500);
  }

  handleSetupInput() {
    $(document).ready(() => {
      function updateBackground(input) {
        let val = (input.val() - input.attr('min')) / (input.attr('max') - input.attr('min'));
        input.css('background-image', '-webkit-gradient(linear, left top, right top, ' + 'color-stop(' + val + ', #ABE2FB), ' + 'color-stop(' + val + ', #EFEFEF)' + ')');
      }

      $('input.input-slide[type="range"]').each(function() {
        updateBackground($(this));
      });

      $('#public-investment-illustrator-form').on('input',function() {
        $('input.input-slide[type="range"]').each(function() {
          updateBackground($(this));
        });
      });

      $('#investment-illustrator-form').on('submit', function(e) {
        e.preventDefault();
      });
    });
  }

  updateUI(min, max) {
    let calcLeftPosition = value => 100 / (100 -1) * (value - 1);
    let lineLeft = calcLeftPosition(min);
    let lineRight = calcLeftPosition(max);

    if (lineLeft > lineRight) {
      $('#line').css({'left': lineRight + '%', 'right': (100 - lineLeft) + '%'});
      $('#thumbMin').css({'right': 99 - lineLeft + '%', 'left': 'unset'});
      $('#thumbMax').css({'left': lineRight + '%', 'right': 'unset'});

      $('#public_investment_illustrator_age_from').html(max);
      $('#public_investment_illustrator_age_to').html(min);
    } else {
      $('#line').css({'left': lineLeft + '%', 'right': (100 - lineRight) + '%'});
      $('#thumbMin').css({'left': lineLeft + '%', 'right': 'unset'});
      $('#thumbMax').css({'right': 99 - lineRight + '%', 'left': 'unset'});

      $('#public_investment_illustrator_age_from').html(min);
      $('#public_investment_illustrator_age_to').html(max);
    }
  }

  rangeSliderAge(min, max) {
    $(document).ready(() => {
      $('#rangeMin').on('input', (e) => {
        const newValue = parseInt(e.target.value);
        min = newValue;
        max = $('#rangeMax').val();
        this.updateUI(min, max);
      });

      $('#rangeMax').on('input', (e) => {
        const newValue = parseInt(e.target.value);
        min = $('#rangeMin').val();
        max = newValue;
        this.updateUI(min, max);
      });
    });
  }

  rangeChart() {
    $(document).ready(() => {
      function getVals() {
        let parent = $(this).closest('.range-slider');
        let slides = parent.find('input');
        let slide1 = parseFloat(slides.eq(0).val());
        let slide2 = parseFloat(slides.eq(1).val());

        if (slide2) {
          if (slide1 > slide2) {
            $('#public_investment_illustrator_age_from').text(slide2)
            $('#public_investment_illustrator_age_to').text(slide1)
          } else {
            $('#public_investment_illustrator_age_from').text(slide1)
            $('#public_investment_illustrator_age_to').text(slide2)
          }
        } else {
          let displayElement = parent.prevAll('.rangeValues:first');
          displayElement.html(slide1);
        }
      }

      $('.range-slider').each(function() {
        let sliders = $(this).find('input[type="range"]');
        sliders.on('input', getVals).trigger('input');
      });
    });
  }

  activeTabData() {
    $(document).ready(() => {
      $('.active-basic-info').on('click', () => {
        $('#additional-information-form').addClass('d-none');
        $('#investment-infomation-form').removeClass('d-none');
      });

      $('.active-additional-info').on('click', () => {
        $('#investment-infomation-form').addClass('d-none');
        $('#additional-information-form').removeClass('d-none');
      });
    });
  }
}
Raicon.register('publicInvestmentIllustrators', PublicInvestmentIllustratorsController);
