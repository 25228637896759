import Raicon from 'raicon';
import FilepondInput from '../../shared/filepondInput';
import { setupEditorFor } from '@/layouts/application/utils/CkeditorUtil.js'

class SiteContentsController {
  constructor() {
    this.richTextSelector = '.ckeditor';
    this.videoSelector = '.video-file-input';
    this.videoPreviewSelector = '.preview-input-file';
    this.removeAttachmentSelector = '.remove-attachment';
  }

  index() {}

  new() {
    this.setupCkeditor();
  }

  create() {
    this.setupCkeditor();
  }

  edit() {
    new FilepondInput('our-team-images-input').setup();
    this.setupCkeditor();
    this.eventForUploadFile();
    this.handleVideoChange();
    this.handleRemoveAttachement();
  }

  update() {
    this.setupCkeditor();
  }

  setupCkeditor() {
    $(this.richTextSelector).each((_index, richTextElement) => {
      setupEditorFor(richTextElement).then((result) => (this.editor = result));
    });
  }

  eventForUploadFile() {
    $('.upload-file-btn').on('click', ({ target }) => {
      $(target).closest('.mt-2').find('input[type="file"]').trigger('click');
    });
  }

  handleVideoChange() {
    $(this.videoSelector).on('change', ({ target }) => {
      const file = target.files[0];
      if (!file) return;

      const previewElement = $(target).closest('.file-input-wrapper').find(this.videoPreviewSelector);
      previewElement.removeClass('d-none');
      previewElement.attr('src', URL.createObjectURL(file));
      this.toggleSaveButton();
      this.toggleRemoveButton(true);
    });
  }

  handleRemoveAttachement() {
    $('.remove-attachment').on('click', ({ target }) => {
      console.log('remove attachment');
      const targetSelector = $(target);

      if (confirm("Are you sure delete Video?")) {
        if (typeof targetSelector.data('attachment-id') == 'undefined') {
          targetSelector.closest('.file-input-wrapper').find(this.videoPreviewSelector).addClass('d-none');
          targetSelector.closest('.file-input-wrapper').find(this.videoSelector).val('');
          return;
        }

        const blobId = targetSelector.data('attachment-id');
        $.ajax({
          url: Routes.destroy_signed_admin_upload_file_path(blobId),
          type: 'DELETE',
          dataType: 'json',
          success: function () {
            window.location.reload();
          },
        });
      }
    });
  }
}

Raicon.register('admin/siteContents', SiteContentsController);
