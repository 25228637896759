import Raicon from 'raicon';

class AdminWhatsAppsController {
  constructor() {
    this.selectConsultantIdsInput = '#select2-client-ids';
  }

  index() {}

  new() {
    this.onChangeSelectConsultantIds();
  }

  create() {
    this.onChangeSelectConsultantIds();
  }

  onChangeSelectConsultantIds() {
    $('input[name="whats_app[target]"]').on('change', ({ target }) => {
      let value = target.value;

      if (value === 'all_fc') {
        $(this.selectConsultantIdsInput).attr('disabled', 'disabled');
      } else {
        $(this.selectConsultantIdsInput).removeAttr('disabled');
      }
    });
  }
}

Raicon.register('admin/whatsApps', AdminWhatsAppsController);
