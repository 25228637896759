import { formatCurrency, formatMoneyToNumber } from '@/layouts/application/utils/formats';
import ToggleSharedDreamEvent from './shared/toggleSharedDreamEvent';
import updateAgeByInput from './shared/updateAgeByInput';
import checkValidDreamStartDate from './shared/checkInvalidStartDate';
import { DEFAULT_CURRENCY } from '@/constants';

export default class DreamEducationForm {
  constructor() {
    this.totalCostInputSelector = '.total-education-cost';
    this.cashPaymentByClientSelector = '.cash-payment-amount';
    this.purchaseStartDateSelector = '#date-expected-input';
    this.ageExpectedShowSelector = '#age-happen-value';
    this.clientBaseCurrency = $('body').data('client-base-currency') || DEFAULT_CURRENCY;
  }

  setup() {
    new ToggleSharedDreamEvent().setup();

    this.setOnchangeEventForTotalCost();

    updateAgeByInput(this.purchaseStartDateSelector, this.ageExpectedShowSelector);
    checkValidDreamStartDate(this.purchaseStartDateSelector);

    $(this.purchaseStartDateSelector).on('input', () => {
      checkValidDreamStartDate(this.purchaseStartDateSelector);
    });
  }

  setOnchangeEventForTotalCost() {
    $(this.totalCostInputSelector).on('input', ({ target }) => {
      const value = formatMoneyToNumber(target.value);

      $(this.cashPaymentByClientSelector).html(formatCurrency(value, this.clientBaseCurrency));
    });
  }
}
